import clone from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import {
  SOCKET_CONNECT_STARTING,
  SOCKET_CONNECT_SUCCESS,
  SOCKET_CONNECT_ERROR,
  SOCKET_DISCONNECTED,

  ADD_DATA_PARAMETERS,
  REMOVE_DATA_PARAMETERS,
  SET_DATA_PARAMETERS,

  GET_PARAMETER_DATA_ERROR,
  RECEIVE_PARAM_DATA,
  CLEAR_DATA,
} from '../constants/actionTypes';
import initialState from '../store/initialState';

export default function parameterDataReducer(state = initialState.parameterData, action) {
  switch (action.type) {
    case SOCKET_CONNECT_STARTING: {
      const newState = clone(state);
      newState.connecting = true;
      newState.connected = false;
      return newState;
    }

    case SOCKET_CONNECT_SUCCESS: {
      const newState = clone(state);
      newState.connecting = false;
      newState.connected = true;
      newState.error = null;
      return newState;
    }

    case SOCKET_CONNECT_ERROR: {
      const newState = clone(state);
      newState.connecting = false;
      newState.connected = false;
      newState.error = action.err;
      return newState;
    }

    case SOCKET_DISCONNECTED: {
      const newState = clone(state);
      newState.connecting = false;
      newState.connected = false;
      newState.socket = null;
      return newState;
    }

    case ADD_DATA_PARAMETERS: {
      const newState = clone(state);
      newState.ids = newState.ids.concat(action.paramIds);
      return newState;
    }

    case REMOVE_DATA_PARAMETERS: {
      const newState = clone(state);
      newState.ids = difference(newState.ids, action.paramIds);

      action.paramIds.forEach((paramId) => {
        if (newState.values[paramId]) {
          delete newState.values[paramId];
        }

        if (newState.timestamps[paramId]) {
          delete newState.timestamps[paramId];
        }

        if (newState.dataRecords[paramId]) {
          delete newState.dataRecords[paramId];
        }
      });

      return newState;
    }

    case SET_DATA_PARAMETERS: {
      const newState = clone(state);
      newState.ids = action.paramIds;
      console.log(newState);
      return newState;
    }

    case GET_PARAMETER_DATA_ERROR: {
      const newState = clone(state);
      newState.error = action.err;
      return newState;
    }

    case CLEAR_DATA: {
      const newState = clone(state);
      newState.dataRecords = {};
      newState.values = {};
      newState.timestamps = {};
      newState.ids = [];
      return newState;
    }

    case RECEIVE_PARAM_DATA: {
      const newState = clone(state);
      const { data } = action;
      for (let paramId in data) {
        if (data.hasOwnProperty(paramId)) {
          const dataRow = data[paramId];
          newState.dataRecords[paramId] = dataRow;
          newState.timestamps[paramId] = dataRow.dt;
          newState.values[paramId] = dataRow.value;
          // console.log(dataRow);
        }
      }
      return newState;
    }

    default: {
      return state;
    }
  }
}
