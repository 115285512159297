import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    GET_MOBILE_TOWER_BY_ID_ERROR,
    GET_MOBILE_TOWER_BY_ID_STARTING,
    GET_MOBILE_TOWER_BY_ID_SUCCESS,
    GET_MOBILE_TOWERS_ERROR,
    GET_MOBILE_TOWERS_STARTING,
    GET_MOBILE_TOWERS_SUCCESS,
    ADD_MOBILE_TOWER_SUCCESS,
    UPDATE_MOBILE_TOWER_SUCCESS,
    GET_INACTIVE_MOBILE_TOWERS_SUCCESS,
    DEACTIVATE_MOBILE_TOWER_SUCCESS,
    ACTIVATE_MOBILE_TOWER_SUCCESS,
    GET_REGIONS_SUCCESS,
} from '../../constants/actionTypes';

const sortByOrder = (newState) => {
    newState.ids.sort((cat1Id, cat2Id) => {
        const tower1 = newState.map[cat1Id];
        const tower2 = newState.map[cat2Id];
        return tower1.displayOrder - tower2.displayOrder;
    });

    newState.inactiveIds.sort((cat1Id, cat2Id) => {
        const tower1 = newState.map[cat1Id];
        const tower2 = newState.map[cat2Id];
        return tower1.displayOrder - tower2.displayOrder;
    });
}

export default function mobileTowersReducer(state = initialState.mobileTowers, action) {
    // console.log(action);

    switch (action.type) {
        case GET_MOBILE_TOWER_BY_ID_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_MOBILE_TOWER_BY_ID_SUCCESS: {
            const newState = cloneDeep(state);
            const {mobileTowerId, data} = action;
            newState.map[mobileTowerId] = data.mobileTower;
            newState.loading = false;
            newState.loaded = true;

            newState.defectsMap[mobileTowerId] = {};
            data.mobileTower.defects.forEach(d => {
                newState.defectsMap[mobileTowerId][d.id] = true;
            });

            return newState;
        }

        case GET_MOBILE_TOWER_BY_ID_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to retrieve mobile tower details by ID.';
            return newState;
        }

        case GET_MOBILE_TOWERS_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_MOBILE_TOWERS_SUCCESS: {
            const newState = cloneDeep(state);
            const ids = [];

            action.data.mobileTowers.forEach((mobileTower) => {
                const mobileTowerId = mobileTower.id;
                ids.push(mobileTowerId);
                newState.map[mobileTowerId] = mobileTower;
            });

            newState.ids = ids;

            //newState.page = action.data.page;
            //newState.pages = action.data.pages;
            //newState.limit = action.data.limit;
            //newState.total = action.data.total;
            //newState.isLastPage = newState.page >= newState.pages;
            newState.loading = false;
            newState.loaded = true;

            sortByOrder(newState);

            return newState;
        }

        case GET_REGIONS_SUCCESS: {
            const newState = cloneDeep(state);

            for (const towerId in action.data.towers) {
                const mobileTower = action.data.towers[towerId];
                newState.map[towerId] = mobileTower;

                newState.defectsMap[towerId] = {};
                mobileTower.defects.forEach(d => {
                    newState.defectsMap[towerId][d.id] = true;
                });
            }

            return newState;
        }

        case GET_INACTIVE_MOBILE_TOWERS_SUCCESS: {
            const newState = cloneDeep(state);
            const inactiveIds = [];

            action.data.mobileTowers.forEach((mobileTower) => {
                const mobileTowerId = mobileTower.id;
                inactiveIds.push(mobileTowerId);
                newState.map[mobileTowerId] = mobileTower;
            });

            newState.inactiveIds = inactiveIds;

            sortByOrder(newState);
            return newState;
        }

        case GET_MOBILE_TOWERS_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to retrieve mobileTowers.';
            return newState;
        }

        case ADD_MOBILE_TOWER_SUCCESS: {
            const newState = cloneDeep(state);
            const {mobileTower} = action.data;
            const mobileTowerId = mobileTower.id;

            // Push new location data
            newState.map[mobileTowerId] = mobileTower;
            newState.ids.push(mobileTowerId);

            // Sort by display order
            sortByOrder(newState);

            return newState;
        }

        case UPDATE_MOBILE_TOWER_SUCCESS: {
            const newState = cloneDeep(state);
            const {mobileTower} = action.data;
            const mobileTowerId = mobileTower.id;
            newState.map[mobileTowerId] = mobileTower;

            sortByOrder(newState);
            return newState;
        }

        case ACTIVATE_MOBILE_TOWER_SUCCESS: {
            const newState = cloneDeep(state);
            const {mobileTowerId} = action;
            newState.ids.push(mobileTowerId);

            newState.inactiveIds = newState.inactiveIds.filter((cId) => {
                return mobileTowerId !== cId;
            });

            if (newState.map[mobileTowerId]) {
                newState.map[mobileTowerId].isActive = 1;
            }

            sortByOrder(newState);
            return newState;
        }

        case DEACTIVATE_MOBILE_TOWER_SUCCESS: {
            const newState = cloneDeep(state);
            const {mobileTowerId} = action;
            newState.inactiveIds.push(mobileTowerId);

            newState.ids = newState.ids.filter((cId) => {
                return mobileTowerId !== cId;
            });

            if (newState.map[mobileTowerId]) {
                newState.map[mobileTowerId].isActive = 0;
            }

            sortByOrder(newState);
            return newState;
        }

        default: {
            return state;
        }
    }
}
