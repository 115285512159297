import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    ADD_PARAMETER_SUCCESS,
    GET_LOCATION_DEVICES_SUCCESS, UPDATE_PARAMETER_SUCCESS,
} from '../../constants/actionTypes';

export default function parametersReducer(state = initialState.parameters, action) {
    switch (action.type) {
        case GET_LOCATION_DEVICES_SUCCESS: {
            const { data } = action;
            const { parameters: parametersData } = data;
            const deviceIds = Object.keys(parametersData);

            const newState = cloneDeep(state);

            for (const deviceId of deviceIds) {
                const params = parametersData[deviceId];
                params.forEach((parameter) => {
                    const paramId = parameter.id;
                    if (!newState[paramId]) {
                        newState[paramId] = cloneDeep(initialState.parameters.paramId);
                    }

                    const parameterData = newState[paramId];
                    parameterData.loading = false;
                    parameterData.loaded = true;
                    parameterData.parameter = parameter;
                });
            }

            return newState;
        }

        case ADD_PARAMETER_SUCCESS:
        case UPDATE_PARAMETER_SUCCESS: {
            const { parameter } = action.data;
            const paramId = parameter.id;
            const newState = cloneDeep(state);

            if (!newState[paramId]) {
                newState[paramId] = cloneDeep(initialState.parameters.paramId);
            }

            const parameterData = newState[paramId];
            parameterData.loading = false;
            parameterData.loaded = true;
            parameterData.parameter = parameter;

            return newState;
        }

        default: {
            return state;
        }
    }
}
