import clone from 'lodash/clone';
import initialState from '../store/initialState';
import {
    SIGNUP_USER_STARTING,
    SIGNUP_USER_SUCCESS,
    SIGNUP_USER_ERROR,
} from "../constants/actionTypes";

export default function signupUserReducer(state = initialState.signup, action) {
    switch (action.type) {
        case SIGNUP_USER_STARTING: {
            const newState = clone(state);
            newState.saving = true;
            newState.saved = false;
            return newState;
        }

        case SIGNUP_USER_SUCCESS: {
            const newState = clone(state);
            newState.saving = false;
            newState.saved = true;
            newState.error = '';
            return newState;
        }

        case SIGNUP_USER_ERROR: {
            const newState = clone(state);
            newState.saving = false;
            newState.saved = false;

            newState.error = action.err;
            return newState;
        }

        default: {
            return state;
        }
    }
}
