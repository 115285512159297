import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  GET_ATTENDANCE_RECORDS_ERROR,
  GET_ATTENDANCE_RECORDS_STARTING,
  GET_ATTENDANCE_RECORDS_SUCCESS,
} from '../../constants/actionTypes';
import {chunk} from "lodash";

export default function attendanceRecordsReducer(state = initialState.attendanceRecords, action) {
  switch (action.type) {
    case GET_ATTENDANCE_RECORDS_STARTING: {
      const newState = cloneDeep(state);
      newState.loading = true;
      newState.loaded = false;
      newState.filterKey = action.filterKey;
      return newState;
    }

    case GET_ATTENDANCE_RECORDS_SUCCESS: {
      if (state.filterKey !== action.filterKey) {
        return state;
      }

      const newState = cloneDeep(state);
      const ids = [];
      const map = {};

      action.data.attendanceRecords.forEach((attendanceRecord) => {
        const attendanceRecordId = attendanceRecord.id;
        ids.push(attendanceRecordId);
        map[attendanceRecordId] = attendanceRecord;
      });

      // Paginate IDs

      const pageLimit = 10;

      newState.ids = ids;
      newState.pagedIds = chunk(ids, pageLimit);

      newState.map = map;
      //newState.page = action.data.page;
      newState.pages = newState.pagedIds.length;
      newState.limit = pageLimit;
      newState.total = ids.length;
      //newState.isLastPage = newState.page >= newState.pages;
      newState.loading = false;
      newState.loaded = true;

      return newState;
    }

    case GET_ATTENDANCE_RECORDS_ERROR: {
      if (state.filterKey !== action.filterKey) {
        return state;
      }

      const newState = cloneDeep(state);
      newState.loading = false;
      newState.loaded = false;
      newState.error = action.err ? action.err : 'Failed to retrieve attendance records.';
      return newState;
    }

    default: {
      return state;
    }
  }
}
