import { cloneDeep } from 'lodash';
import {
    GET_PRODUCTS_STARTING,
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCTS_ERROR,

    GET_PRODUCT_BY_ID_STARTING,
    GET_PRODUCT_BY_ID_SUCCESS,
    GET_PRODUCT_BY_ID_ERROR,
} from '../../constants/actionTypes';
import initialState from '../../store/initialState';

const sortByOrder = (newState) => {
    newState.ids.sort((product1Id, product2Id) => {
        const product1 = newState.map[product1Id];
        const product2 = newState.map[product2Id];
        return product1.displayOrder - product2.displayOrder;
    });
}

export default function productsReducer(state = initialState.products, action) {
    switch (action.type) {

        case GET_PRODUCTS_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_PRODUCTS_SUCCESS: {
            const newState = cloneDeep(state);

            const ids = [];
            action.data.products.forEach((product) => {
                const productId = product.id;
                ids.push(productId);
                newState.map[productId] = product;
            });

            newState.ids = ids;

            //newState.page = action.data.page;
            //newState.pages = action.data.pages;
            //newState.limit = action.data.limit;
            //newState.total = action.data.total;
            //newState.isLastPage = newState.page >= newState.pages;

            newState.loading = false;
            newState.loaded = true;

            sortByOrder(newState);

            return newState;
        }

        case GET_PRODUCTS_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to retrive Products';
            return newState;
        }

        case GET_PRODUCT_BY_ID_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_PRODUCT_BY_ID_SUCCESS: {
            const newState = cloneDeep(state);
            const { productId, data } = action;
            newState.map[productId] = data.product;
            newState.loading = false;
            newState.loaded = true;
            return newState;
        }

        case GET_PRODUCT_BY_ID_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to get Product details by id';
            return newState;
        }

        default: {
            return state;
        }
    }
}