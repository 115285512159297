import moment from 'moment';
import clone from 'lodash/cloneDeep';
import {
  SOCKET_CONNECT_STARTING,
  SOCKET_CONNECT_SUCCESS,
  SOCKET_CONNECT_ERROR,
  SOCKET_DISCONNECTED,
} from '../constants/actionTypes';
import initialState from '../store/initialState';

export default function socketReducer(state = initialState.socket, action) {
  switch (action.type) {
    case SOCKET_CONNECT_STARTING: {
      const newState = clone(state);
      newState.connecting = true;
      newState.connected = false;
      newState.loading = true;
      return newState;
    }

    case SOCKET_CONNECT_SUCCESS: {
      const newState = clone(state);
      newState.loading = false;
      newState.connecting = false;
      newState.connected = true;
      newState.error = null;
      newState.socket = null;
      newState.lastPongTime = moment();

      if (!newState.firstConnectDone) {
        newState.firstConnectDone = true;
      }

      return newState;
    }

    //case GET_USER_INFO_SUCCESS: {
    //  if (state.firstConnectDone) {
    //    return state;
    //  }
    //
    //  const newState = clone(state);
    //  newState.firstConnectDone = true;
    //  return newState;
    //}

    case SOCKET_CONNECT_ERROR: {
      const newState = clone(state);
      newState.loading = false;
      newState.connecting = false;
      newState.connected = false;
      newState.error = action.err;
      return newState;
    }

    case SOCKET_DISCONNECTED: {
      const newState = clone(state);
      newState.loading = false;
      newState.connecting = false;
      newState.connected = false;
      newState.socket = null;
      return newState;
    }

    default: {
      return state;
    }
  }
}

