import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    GET_POSTS_ERROR,
    GET_POSTS_STARTING,
    GET_POSTS_SUCCESS,
    ADD_POST_SUCCESS,
    UPDATE_POST_SUCCESS,
    GET_INACTIVE_POSTS_SUCCESS,
    DEACTIVATE_POST_SUCCESS,
    ACTIVATE_POST_SUCCESS,
} from '../../constants/actionTypes';

const sortByOrder = (newState) => {

};

export default function postsReducer(state = initialState.posts, action) {
    switch (action.type) {
        case GET_POSTS_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            newState.error = null;
            return newState;
        }

        case GET_POSTS_SUCCESS: {
            const newState = cloneDeep(state);
            const ids = [];

            action.data.posts.forEach((post) => {
                const postId = post.id;
                ids.push(postId);
                newState.map[postId] = post;
            });

            newState.ids = ids;

            //newState.page = action.data.page;
            //newState.pages = action.data.pages;
            //newState.limit = action.data.limit;
            //newState.total = action.data.total;
            //newState.isLastPage = newState.page >= newState.pages;
            newState.loading = false;
            newState.loaded = true;

            sortByOrder(newState);

            return newState;
        }

        case GET_INACTIVE_POSTS_SUCCESS: {
            const newState = cloneDeep(state);
            const inactiveIds = [];

            action.data.posts.forEach((post) => {
                const postId = post.id;
                inactiveIds.push(postId);
                newState.map[postId] = post;
            });

            newState.inactiveIds = inactiveIds;

            sortByOrder(newState);
            return newState;
        }

        case GET_POSTS_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to retrieve posts.';
            return newState;
        }

        case ADD_POST_SUCCESS: {
            const newState = cloneDeep(state);
            const {post} = action.data;
            const postId = post.id;

            // Push new post data
            newState.map[postId] = post;
            newState.ids = [postId].concat(newState.ids);

            // Sort by display order
            sortByOrder(newState);

            return newState;
        }

        case UPDATE_POST_SUCCESS: {
            const newState = cloneDeep(state);
            const {post} = action.data;
            const postId = post.id;
            newState.map[postId] = post;

            sortByOrder(newState);
            return newState;
        }

        case ACTIVATE_POST_SUCCESS: {
            const newState = cloneDeep(state);
            const {postId} = action;
            newState.ids.push(postId);

            newState.inactiveIds = newState.inactiveIds.filter((pId) => {
                return postId !== pId;
            });
            if (newState.map[postId]) {
                newState.map[postId].isActive = 1;
            }
            sortByOrder(newState);
            return newState;
        }

        case DEACTIVATE_POST_SUCCESS: {
            const newState = cloneDeep(state);
            const {postId} = action;
            newState.inactiveIds.push(postId);

            newState.ids = newState.ids.filter((pId) => {
                return postId !== pId;
            });

            if (newState.map[postId]) {
                newState.map[postId].isActive = 0;
            }

            sortByOrder(newState);
            return newState;
        }

        default: {
            return state;
        }
    }
}
