import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  CLOSE_AHU_ON_OFF_REQUEST_STARTING,
  CLOSE_AHU_ON_OFF_REQUEST_SUCCESS,
  CLOSE_AHU_ON_OFF_REQUEST_ERROR,
} from '../../constants/actionTypes';

export default function closeAhuOnOffRequestReducer(state = initialState.closeAhuOnOffRequest, action) {
  switch (action.type) {
    case CLOSE_AHU_ON_OFF_REQUEST_STARTING: {
      const {requestId} = action;
      const newState = clone(state);

      if (!newState[requestId]) {
        newState[requestId] = clone(initialState.closeAhuOnOffRequest.requestId);
      }

      const requestData = newState[requestId];
      requestData.saving = true;
      requestData.saved = false;
      requestData.error = null;

      return newState;
    }

    case CLOSE_AHU_ON_OFF_REQUEST_SUCCESS: {
      const {requestId} = action;
      const newState = clone(state);

      if (!newState[requestId]) {
        newState[requestId] = clone(initialState.closeAhuOnOffRequest.requestId);
      }

      const requestData = newState[requestId];
      requestData.saving = false;
      requestData.saved = true;
      requestData.error = null;
      return newState;
    }

    case CLOSE_AHU_ON_OFF_REQUEST_ERROR: {
      const {requestId} = action;
      const newState = clone(state);

      if (!newState[requestId]) {
        newState[requestId] = clone(initialState.closeAhuOnOffRequest.requestId);
      }

      const requestData = newState[requestId];
      requestData.saving = false;
      requestData.saved = false;
      requestData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
