import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  GET_APPLICATIONS_ERROR,
  GET_APPLICATIONS_STARTING,
  GET_APPLICATIONS_SUCCESS,
} from '../../constants/actionTypes';

const sortByOrder = (newState) => {
  newState.ids.sort((application1Id, application2Id) => {
    const application1 = newState.map[application1Id];
    const application2 = newState.map[application2Id];
    return application1.displayOrder - application2.displayOrder;
  });
}

export default function applicationsReducer(state = initialState.applications, action) {
  switch (action.type) {
    case GET_APPLICATIONS_STARTING: {
      const newState = cloneDeep(state);
      newState.loading = true;
      newState.loaded = false;
      return newState;
    }

    case GET_APPLICATIONS_SUCCESS: {
      const newState = cloneDeep(state);
      const ids = [];

      console.log(action.data);
      
      action.data.applications.forEach((application) => {
        const applicationId = application.id;
        ids.push(applicationId);
        newState.map[applicationId] = application;
        });

      newState.ids = ids;
      
      //newState.page = action.data.page;
      //newState.pages = action.data.pages;
      //newState.limit = action.data.limit;
      //newState.total = action.data.total;
      //newState.isLastPage = newState.page >= newState.pages;
      newState.loading = false;
      newState.loaded = true;
      
      sortByOrder(newState);

      return newState;
    }
  
    case GET_APPLICATIONS_ERROR: {
      const newState = cloneDeep(state);
      newState.loading = false;
      newState.loaded = false;
      newState.error = action.err ? action.err : 'Failed to retrieve applications.';
      return newState;
    }

    default: {
      return state;
    }
  }
}
