import {useEffect, useRef} from 'react';
import WebViewer from '@pdftron/pdfjs-express-viewer';

const TowerSiteReport = () => {
  const viewer = useRef(null);

  useEffect(() => {
    WebViewer(
        {
          path: '/pdfjs-express',
          licenseKey: 'Y4lLh43AZEee3d5g1EfQ',
          initialDoc: '/files/report1.pdf',
        },
        viewer.current,
    ).then((instance) => {
      // now you can access APIs through the WebViewer instance
      const { Core } = instance;

      // adding an event listener for when a document is loaded
      Core.documentViewer.addEventListener('documentLoaded', () => {
        console.log('document loaded');
      });

      // adding an event listener for when the page number has changed
      Core.documentViewer.addEventListener('pageNumberUpdated', (pageNumber) => {
        console.log(`Page number is: ${pageNumber}`);
      });
    });
  }, []);

  return (
      <div className="pdf-file-container">
        <div className="webviewer" ref={viewer} style={{height: "100vh"}}></div>
      </div>
  );
};
export default TowerSiteReport;