import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    DEACTIVATE_ACK_MESSAGE_STARTING,
    DEACTIVATE_ACK_MESSAGE_SUCCESS,
    DEACTIVATE_ACK_MESSAGE_ERROR,
} from '../../constants/actionTypes';

export default function deactivateAckMessageReducer(state = initialState.deactivateAckMessage, action) {
    switch(action.type) {
        case DEACTIVATE_ACK_MESSAGE_STARTING: {
            const {ackMessageId} = action;
            const newState = clone(state);
            
            if(!newState[ackMessageId]) {
                newState[ackMessageId] = clone(initialState.deactivateAckMessage.ackMessageId);
            }
            
            const AckMessageData = newState[ackMessageId];
            AckMessageData.saving = true;
            AckMessageData.saved = false;
            AckMessageData.error = null;
            
            return newState;
        }
        case DEACTIVATE_ACK_MESSAGE_SUCCESS: {
            const {ackMessageId} = action;
            const newState = clone(state);
            
            if(!newState[ackMessageId]) {
                newState[ackMessageId] = clone(initialState.deactivateAckMessage.ackMessageId);
            }
            const AckmessageData = newState[ackMessageId];
            AckmessageData.saving = false;
            AckmessageData.saved = true;
            AckmessageData.error = null;
            
            return newState;
        }
        case DEACTIVATE_ACK_MESSAGE_ERROR: {
            const {ackMessageId} = action;
            const newState = clone(state);
            
            if(!newState[ackMessageId]) {
                newState[ackMessageId] = clone(initialState.deactivateAckMessage.ackMessageId);
            }
            const AckMessageData = newState[ackMessageId];
            AckMessageData.saving = false;
            AckMessageData.saved = false;
            AckMessageData.error = action.err;
            
            return newState;
        }
        default: {
            return state;
        }
    }
}