import cloneDeep from 'lodash/cloneDeep';
import initialState from '../store/initialState';
import {
  ADD_DEVICE_SUCCESS,
  GET_LOCATION_DEVICES_ERROR,
  GET_LOCATION_DEVICES_STARTING,
  GET_LOCATION_DEVICES_SUCCESS, UPDATE_DEVICE_SUCCESS,
} from '../constants/actionTypes';

export default function locationDevicesReducer(state = initialState.locationDevices, action) {
  switch (action.type) {
    case GET_LOCATION_DEVICES_STARTING: {
      const { locationId } = action;
      const newState = cloneDeep(state);

      if (!newState[locationId]) {
        newState[locationId] = cloneDeep(initialState.locationDevices.locationId);
      }

      const devicesData = newState[locationId];
      devicesData.ids = [];
      devicesData.map = {};
      devicesData.loading = true;
      devicesData.loaded = false;
      devicesData.error = null;

      return newState;
    }

    case GET_LOCATION_DEVICES_SUCCESS: {
      const { data, locationId } = action;
      const newState = cloneDeep(state);

      if (!newState[locationId]) {
        newState[locationId] = cloneDeep(initialState.locationDevices.locationId);
      }

      const devicesData = newState[locationId];
      const ids = [];
      const map = {};
      const uniqueDeviceTypes = [];
      const deviceTypeOfIds = {};
      const paramIds = {};

      data.mainDevices.forEach((device) => {
        const deviceId = device.id;
        ids.push(deviceId);
        map[deviceId] = device;
      });

      devicesData.relatedDevices = data.relatedDevices;
      
      const deviceIds = Object.keys(data.parameters);
      for (const deviceId of deviceIds) {
        if (!paramIds[deviceId]) {
          paramIds[deviceId] = [];
        }

        paramIds[deviceId] = data.parameters[deviceId].map((parameter) => parameter.id);
      }

      devicesData.parameters = paramIds;
      devicesData.mainDeviceIds = ids;
      devicesData.mainDevicesMap = map;

      devicesData.deviceTypeOfIds = deviceTypeOfIds;
      devicesData.uniqueDeviceTypes = uniqueDeviceTypes;

      devicesData.loading = false;
      devicesData.loaded = true;
      //devicesData.page = action.data.page;
      //devicesData.pages = action.data.pages;
      //devicesData.limit = action.data.limit;
      //devicesData.total = action.data.total;
      //devicesData.isLastPage = devicesData.page >= devicesData.pages;

      return newState;
    }

    case GET_LOCATION_DEVICES_ERROR: {
      const { locationId } = action;
      const newState = cloneDeep(state);

      if (!newState[locationId]) {
        newState[locationId] = cloneDeep(initialState.locationDevices.locationId);
      }

      const devicesData = newState[locationId];
      devicesData.loading = false;
      devicesData.loaded = false;
      devicesData.error = action.err;
      return newState;
    }

    case ADD_DEVICE_SUCCESS: {
      const { device } = action.data;
      const newState = cloneDeep(state);

      const { locationId, parentDeviceId } = device;

      if (!newState[locationId]) {
        newState[locationId] = cloneDeep(initialState.locationDevices.locationId);
      }

      const devicesData = newState[locationId];

      if (parentDeviceId === 0) {
        devicesData.mainDeviceIds.push(device.id);
        devicesData.mainDevicesMap[device.id] = device;
      } else {
        if (devicesData.relatedDevices[parentDeviceId]) {
          devicesData.relatedDevices[parentDeviceId].push(device);
        } else {
          devicesData.relatedDevices[parentDeviceId] = [device];
        }
      }

      return newState;
    }

    case UPDATE_DEVICE_SUCCESS: {
      const { device } = action.data;
      const newState = cloneDeep(state);

      const { locationId, parentDeviceId } = device;

      if (!newState[locationId]) {
        newState[locationId] = cloneDeep(initialState.locationDevices.locationId);
      }

      const devicesData = newState[locationId];

      if (parentDeviceId === 0) {
        devicesData.mainDevicesMap[device.id] = device;
      } else {
        if (devicesData.relatedDevices[parentDeviceId]) {
          devicesData.relatedDevices[parentDeviceId] = devicesData.relatedDevices[parentDeviceId].map(function (rd) {
            if (rd.id === device.id) {
              return device;
            } else {
              return rd;
            }
          });
        }
      }

      return newState;
    }

    default: {
      return state;
    }
  }
}
