import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    ACTIVATE_POST_STARTING,
    ACTIVATE_POST_SUCCESS,
    ACTIVATE_POST_ERROR,
} from '../../constants/actionTypes';

export default function activatePostReducer(state = initialState.activatePost, action) {
    switch (action.type) {
        case ACTIVATE_POST_STARTING: {
            const { postId } = action;
            const newState = clone(state);

            if (!newState[postId]) {
                newState[postId] = clone(initialState.activatePost.postId);
            }

            const postData = newState[postId];
            postData.saving = true;
            postData.saved = false;
            postData.error = null;

            return newState;
        }

        case ACTIVATE_POST_SUCCESS: {
            const { postId } = action;
            const newState = clone(state);

            if (!newState[postId]) {
                newState[postId] = clone(initialState.activatePost.postId);
            }

            const postData = newState[postId];
            postData.saving = false;
            postData.saved = true;
            postData.error = null;
            return newState;
        }

        case ACTIVATE_POST_ERROR: {
            const { postId } = action;
            const newState = clone(state);

            if (!newState[postId]) {
                newState[postId] = clone(initialState.activatePost.postId);
            }

            const postData = newState[postId];
            postData.saving = false;
            postData.saved = false;
            postData.error = action.err;
            return newState;
        }

        default: {
            return state;
        }
    }
}
