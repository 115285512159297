import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  GET_APPLICATION_LICENSES_ERROR,
  GET_APPLICATION_LICENSES_STARTING,
  GET_APPLICATION_LICENSES_SUCCESS,

  ADD_LICENSE_SUCCESS,
  UPDATE_LICENSE_SUCCESS,
} from '../../constants/actionTypes';

export default function licensesReducer(state = initialState.licenses, action) {
  switch (action.type) {
    case GET_APPLICATION_LICENSES_STARTING: {
      const { applicationId } = action;
      const newState = cloneDeep(state);

      if (!newState[applicationId]) {
        newState[applicationId] = cloneDeep(initialState.licenses.applicationId);
      }

      const licensesData = newState[applicationId];
      licensesData.ids = [];
      licensesData.map = {};
      licensesData.loading = true;
      licensesData.loaded = false;
      licensesData.error = null;

      return newState;
    }

    case GET_APPLICATION_LICENSES_SUCCESS: {
      const { data, applicationId } = action;
      const newState = cloneDeep(state);

      if (!newState[applicationId]) {
        newState[applicationId] = cloneDeep(initialState.licenses.applicationId);
      }

      const licensesData = newState[applicationId];
      const ids = [];
      const map = {};

      data.licenses.forEach((license) => {
        ids.push(license.id);
        map[license.id] = license;
      });

      licensesData.ids = ids;
      licensesData.map = map;
      licensesData.loading = false;
      licensesData.loaded = true;

      //licensesData.page = action.data.page;
      //licensesData.pages = action.data.pages;
      //licensesData.limit = action.data.limit;
      //licensesData.total = action.data.total;
      //licensesData.isLastPage = licensesData.page >= licensesData.pages;

      return newState;
    }

    case GET_APPLICATION_LICENSES_ERROR: {
      const { applicationId } = action;
      const newState = cloneDeep(state);

      if (!newState[applicationId]) {
        newState[applicationId] = cloneDeep(initialState.licenses.applicationId);
      }

      const licensesData = newState[applicationId];
      licensesData.loading = false;
      licensesData.loaded = false;
      licensesData.error = action.err;
      return newState;
    }

    case ADD_LICENSE_SUCCESS: {
      const newState = cloneDeep(state);
      const { license } = action.data;
      const { id: licenseId, applicationId } = license;

      if (!newState[applicationId]) {
        newState[applicationId] = cloneDeep(initialState.licenses.applicationId);
      }

      const licensesData = newState[applicationId];
      licensesData.ids.push(licenseId);
      licensesData.map[licenseId] = license;

      // TODO Sort new ids
      return newState;
    }

    case UPDATE_LICENSE_SUCCESS: {
      const newState = cloneDeep(state);
      const { license } = action.data;
      const { id: licenseId, applicationId } = license;

      if (!newState[applicationId]) {
        newState[applicationId] = cloneDeep(initialState.licenses.applicationId);
      }

      const licensesData = newState[applicationId];
      licensesData.map[licenseId] = license;
      return newState;
    }

    default: {
      return state;
    }
  }
}
