import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  GET_ATTENDANCE_RECORDS_SUCCESS,
} from '../../constants/actionTypes';

export default function areasReducer(state = initialState.attendanceAreas, action) {
  switch (action.type) {
    case GET_ATTENDANCE_RECORDS_SUCCESS: {
      const newState = cloneDeep(state);
      const map = {};

      action.data.areas.forEach((employee) => {
        const employeeId = employee.id;
        map[employeeId] = employee;
      });

      newState.map = map;
      return newState;
    }

    default: {
      return state;
    }
  }
}
