import cloneDeep from 'lodash/cloneDeep';
import initialState from '../store/initialState';
import {
    GET_TEST_DETAILS_STARTING,
    GET_TEST_DETAILS_SUCCESS,
    GET_TEST_DETAILS_ERROR, UPDATE_TEST_DETAILS_SUCCESS, UPDATE_TEST_DETAILS_STARTING,
} from '../constants/actionTypes';

export default function testBenchStatusReducer(state = initialState.testBenchStatus, action) {
    switch (action.type) {
        case GET_TEST_DETAILS_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case UPDATE_TEST_DETAILS_STARTING: {
            const newState = cloneDeep(state);
            newState.updating = true;
            newState.updated = false;
            return newState;
        }

        case GET_TEST_DETAILS_SUCCESS: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = true;
            newState.isTestRunning = action.data.isRunning;
            newState.currentTest = action.data.testDetails;
            return newState;
        }

        case UPDATE_TEST_DETAILS_SUCCESS: {
            const newState = cloneDeep(state);
            newState.updating = false;
            newState.updated = true;
            newState.isTestRunning = action.data.isRunning;
            newState.currentTest = action.data.testDetails;
            return newState;
        }

        default: {
            return state;
        }
    }
}
