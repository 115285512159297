import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  GET_ROOM_DEVICES_ERROR,
  GET_ROOM_DEVICES_STARTING,
  GET_ROOM_DEVICES_SUCCESS,
} from '../../constants/actionTypes';

export default function roomWiseDevicesReducer(state = initialState.roomWiseDevices, action) {
  switch (action.type) {
    case GET_ROOM_DEVICES_STARTING: {
      const { roomId } = action;
      const newState = cloneDeep(state);

      if (!newState[roomId]) {
        newState[roomId] = cloneDeep(initialState.roomWiseDevices.roomId);
      }

      const roomsData = newState[roomId];
      roomsData.ids = [];
      roomsData.map = {};
      roomsData.loading = true;
      roomsData.loaded = false;
      roomsData.error = null;

      return newState;
    }

    case GET_ROOM_DEVICES_SUCCESS: {
      const { data, roomId } = action;
      const newState = cloneDeep(state);

      if (!newState[roomId]) {
        newState[roomId] = cloneDeep(initialState.roomWiseDevices.roomId);
      }

      const roomsData = newState[roomId];
      const ids = [];
      const map = {};

      data.devices.forEach((device) => {
        ids.push(device.id);
        map[device.id] = device;
      });

      roomsData.ids = ids;
      roomsData.map = map;
      roomsData.loading = false;
      roomsData.loaded = true;

      //roomsData.page = action.data.page;
      //roomsData.pages = action.data.pages;
      //roomsData.limit = action.data.limit;
      //roomsData.total = action.data.total;
      //roomsData.isLastPage = roomsData.page >= roomsData.pages;

      return newState;
    }

    case GET_ROOM_DEVICES_ERROR: {
      const { roomId } = action;
      const newState = cloneDeep(state);

      if (!newState[roomId]) {
        newState[roomId] = cloneDeep(initialState.roomWiseDevices.roomId);
      }

      const roomsData = newState[roomId];
      roomsData.loading = false;
      roomsData.loaded = false;
      roomsData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
