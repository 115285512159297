import cloneDeep from "lodash/cloneDeep";
import initialState from "../../store/initialState";

import {
    GET_ALARM_DATA_STARTING,
    GET_ALARM_DATA_SUCCESS,
    GET_ALARM_DATA_ERROR,
} from '../../constants/actionTypes';
import { chunk } from "lodash";

export default function alarmsReducer(state = initialState.alarms, action) {
    switch (action.type) {
        case GET_ALARM_DATA_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_ALARM_DATA_SUCCESS: {
            const newState = cloneDeep(state);
            const ids = [];
            const map = {};

            action.data.alarms.forEach((alarm) => {
                const alarmId = alarm.id;
                ids.push(alarmId);
                map[alarmId] = alarm;
            });

            const pageLimit = 10;

            newState.ids = ids;
            newState.pagedIds = chunk(ids, pageLimit);
            newState.map = map;

            //newState.page = action.data.page;
            newState.pages = newState.pagedIds.length;
            newState.limit = pageLimit;
            newState.total = ids.length;
            //newState.isLastPage = newState.page >= newState.pages;

            newState.loading = false;
            newState.loaded = true;

            return newState;
        }

        case GET_ALARM_DATA_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to retrive alarms.';
            return newState;
        }

        default: {
            return state;
        }
    }
}
