import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  DEACTIVATE_POST_STARTING,
  DEACTIVATE_POST_SUCCESS,
  DEACTIVATE_POST_ERROR,
} from '../../constants/actionTypes';

export default function deactivatePostReducer(state = initialState.deactivatePost, action) {
  switch (action.type) {
    case DEACTIVATE_POST_STARTING: {
      const {postId} = action;
      const newState = clone(state);

      if (!newState[postId]) {
        newState[postId] = clone(initialState.deactivatePost.postId);
      }

      const postData = newState[postId];
      postData.saving = true;
      postData.saved = false;
      postData.error = null;

      return newState;
    }

    case DEACTIVATE_POST_SUCCESS: {
      const {postId} = action;
      const newState = clone(state);

      if (!newState[postId]) {
        newState[postId] = clone(initialState.deactivatePost.postId);
      }

      const postData = newState[postId];
      postData.saving = false;
      postData.saved = true;
      postData.error = null;
      return newState;
    }

    case DEACTIVATE_POST_ERROR: {
      const {postId} = action;
      const newState = clone(state);

      if (!newState[postId]) {
        newState[postId] = clone(initialState.deactivatePost.postId);
      }

      const postData = newState[postId];
      postData.saving = false;
      postData.saved = false;
      postData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
