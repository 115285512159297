import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  UPDATE_ROOM_STARTING,
  UPDATE_ROOM_SUCCESS,
  UPDATE_ROOM_ERROR,
} from '../../constants/actionTypes';

export default function updateRoomReducer(state = initialState.updateRoom, action) {
  switch (action.type) {
    case UPDATE_ROOM_STARTING: {
      const {roomId} = action;
      const newState = clone(state);

      if (!newState[roomId]) {
        newState[roomId] = clone(initialState.updateRoom.roomId);
      }

      const updateData = newState[roomId];
      updateData.saving = true;
      updateData.saved = false;
      updateData.error = null;

      return newState;
    }

    case UPDATE_ROOM_SUCCESS: {
      const {roomId} = action;
      const newState = clone(state);

      if (!newState[roomId]) {
        newState[roomId] = clone(initialState.updateRoom.roomId);
      }

      const updateData = newState[roomId];
      updateData.saving = false;
      updateData.saved = true;
      updateData.error = null;
      return newState;
    }

    case UPDATE_ROOM_ERROR: {
      const {roomId} = action;
      const newState = clone(state);

      if (!newState[roomId]) {
        newState[roomId] = clone(initialState.updateRoom.roomId);
      }

      const updateData = newState[roomId];
      updateData.saving = false;
      updateData.saved = false;
      updateData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
