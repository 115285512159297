import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  GET_LOCATION_ROOMS_ERROR,
  GET_LOCATION_ROOMS_STARTING,
  GET_LOCATION_ROOMS_SUCCESS,

  ADD_ROOM_SUCCESS,
  UPDATE_ROOM_SUCCESS,
} from '../../constants/actionTypes';

export default function roomsReducer(state = initialState.rooms, action) {
  switch (action.type) {
    case GET_LOCATION_ROOMS_STARTING: {
      const { locationId } = action;
      const newState = cloneDeep(state);

      if (!newState[locationId]) {
        newState[locationId] = cloneDeep(initialState.rooms.locationId);
      }

      const roomsData = newState[locationId];
      roomsData.ids = [];
      roomsData.map = {};
      roomsData.loading = true;
      roomsData.loaded = false;
      roomsData.error = null;

      return newState;
    }

    case GET_LOCATION_ROOMS_SUCCESS: {
      const { data, locationId } = action;
      const newState = cloneDeep(state);

      if (!newState[locationId]) {
        newState[locationId] = cloneDeep(initialState.rooms.locationId);
      }

      const roomsData = newState[locationId];
      const ids = [];
      const map = {};

      data.rooms.forEach((room) => {
        ids.push(room.id);
        map[room.id] = room;
      });

      roomsData.devicesMap = data.roomDevices;
      roomsData.monitoringDeviceTypes = data.monitoringDeviceTypes;
      roomsData.roomDevicesByType = data.roomDevicesByType;
      roomsData.ids = ids;
      roomsData.map = map;
      roomsData.loading = false;
      roomsData.loaded = true;

      //roomsData.page = action.data.page;
      //roomsData.pages = action.data.pages;
      //roomsData.limit = action.data.limit;
      //roomsData.total = action.data.total;
      //roomsData.isLastPage = roomsData.page >= roomsData.pages;

      return newState;
    }

    case GET_LOCATION_ROOMS_ERROR: {
      const { locationId } = action;
      const newState = cloneDeep(state);

      if (!newState[locationId]) {
        newState[locationId] = cloneDeep(initialState.rooms.locationId);
      }

      const roomsData = newState[locationId];
      roomsData.loading = false;
      roomsData.loaded = false;
      roomsData.error = action.err;
      return newState;
    }

    case ADD_ROOM_SUCCESS: {
      const newState = cloneDeep(state);
      const { room } = action.data;
      const { id: roomId, locationId } = room;

      if (!newState[locationId]) {
        newState[locationId] = cloneDeep(initialState.rooms.locationId);
      }

      const roomsData = newState[locationId];
      roomsData.ids.push(roomId);
      roomsData.map[roomId] = room;

      // TODO Sort new ids
      return newState;
    }

    case UPDATE_ROOM_SUCCESS: {
      const newState = cloneDeep(state);
      const { room } = action.data;
      const { id: roomId, locationId } = room;

      if (!newState[locationId]) {
        newState[locationId] = cloneDeep(initialState.rooms.locationId);
      }

      const roomsData = newState[locationId];
      roomsData.map[roomId] = room;
      return newState;
    }

    default: {
      return state;
    }
  }
}
