import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  GET_AHU_FILTER_CLEANING_REQUESTS_ERROR,
  GET_AHU_FILTER_CLEANING_REQUESTS_STARTING,
  GET_AHU_FILTER_CLEANING_REQUESTS_SUCCESS,

  ADD_AHU_FILTER_CLEANING_REQUEST_SUCCESS,
  ACKNOWLEDGE_AHU_FILTER_CLEANING_REQUEST_SUCCESS,
  CLOSE_AHU_FILTER_CLEANING_REQUEST_SUCCESS,
  MARK_AS_DONE_AHU_FILTER_CLEANING_REQUEST_SUCCESS,
} from '../../constants/actionTypes';

export default function ahuFilterCleaningRequestsReducer(state = initialState.ahuFilterCleaningRequests, action) {
  switch (action.type) {
    case GET_AHU_FILTER_CLEANING_REQUESTS_STARTING: {
      const newState = cloneDeep(state);
      newState.loading = true;
      newState.loaded = false;
      newState.filterKey = action.filterKey;
      return newState;
    }

    case GET_AHU_FILTER_CLEANING_REQUESTS_SUCCESS: {
      if (state.filterKey !== action.filterKey) {
        return state;
      }

      const newState = cloneDeep(state);
      const ids = [];
      const map = {};

      action.data.ahuFilterCleaningRequests.forEach((ahuFilterCleaningRequest) => {
        const ahuFilterCleaningRequestId = ahuFilterCleaningRequest.id;
        ids.push(ahuFilterCleaningRequestId);
        map[ahuFilterCleaningRequestId] = ahuFilterCleaningRequest;
      });

      newState.ids = ids;
      newState.map = map;
      //newState.page = action.data.page;
      //newState.pages = action.data.pages;
      //newState.limit = action.data.limit;
      //newState.total = action.data.total;
      //newState.isLastPage = newState.page >= newState.pages;
      newState.loading = false;
      newState.loaded = true;

      return newState;
    }

    case GET_AHU_FILTER_CLEANING_REQUESTS_ERROR: {
      if (state.filterKey !== action.filterKey) {
        return state;
      }

      const newState = cloneDeep(state);
      newState.loading = false;
      newState.loaded = false;
      newState.error = action.err ? action.err : 'Failed to retrieve ahu filter cleaning requests.';
      return newState;
    }

    case ADD_AHU_FILTER_CLEANING_REQUEST_SUCCESS: {
      const newState = cloneDeep(state);
      const { ahuFilterCleaningRequest } = action.data;
      const ahuFilterCleaningRequestId = ahuFilterCleaningRequest.id;

      // Push new data
      newState.map[ahuFilterCleaningRequestId] = ahuFilterCleaningRequest;
      newState.ids = [ahuFilterCleaningRequestId].concat(newState.ids);
      // newState.ids.push(ahuFilterCleaningRequestId);

      return newState;
    }

    case ACKNOWLEDGE_AHU_FILTER_CLEANING_REQUEST_SUCCESS:
    case MARK_AS_DONE_AHU_FILTER_CLEANING_REQUEST_SUCCESS: {
      const newState = cloneDeep(state);
      const { ahuFilterCleaningRequest } = action.data;
      const ahuFilterCleaningRequestId = ahuFilterCleaningRequest.id;

      // Push new data
      newState.map[ahuFilterCleaningRequestId] = ahuFilterCleaningRequest;
      return newState;
    }

    case CLOSE_AHU_FILTER_CLEANING_REQUEST_SUCCESS: {
      const newState = cloneDeep(state);
      const { ahuFilterCleaningRequest } = action.data;
      const ahuFilterCleaningRequestId = ahuFilterCleaningRequest.id;

      newState.ids = newState.ids.filter((id) => id !== ahuFilterCleaningRequestId);
      return newState;
    }

    default: {
      return state;
    }
  }
}
