import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    GET_HYDRAULIC_TEST_BENCH_DATA_STARTING,
    GET_HYDRAULIC_TEST_BENCH_DATA_SUCCESS,
    GET_HYDRAULIC_TEST_BENCH_DATA_ERROR,
} from '../../constants/actionTypes';
import { chunk } from "lodash";


export default function hydraulicTestBenchReducer(state = initialState.hydraulicTestBench, action) {
    switch (action.type) {
        case GET_HYDRAULIC_TEST_BENCH_DATA_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_HYDRAULIC_TEST_BENCH_DATA_SUCCESS: {
            const newState = cloneDeep(state);

            newState.dtList = action.data.dtList;
            newState.reportData = action.data.reportData;
            newState.deviceIds = action.data.deviceIds;
            newState.devicesMap = action.data.devicesMap;
            newState.deviceParamIdsMap = action.data.deviceParamIdsMap;
            newState.parametersMap = action.data.parametersMap;

            const pageLimit = 18;
            newState.pagedDtList = chunk(newState.dtList, pageLimit);

            //newState.page = action.data.page;
            newState.pages = newState.pagedDtList.length;
            newState.limit = pageLimit;
            newState.total = newState.dtList.length;
            //newState.isLastPage = newState.page >= newState.pages;

            newState.loading = false;
            newState.loaded = true;

            return newState;
        }

        case GET_HYDRAULIC_TEST_BENCH_DATA_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to retrive hydraulic test bench.';
        }

        default: {
            return state;
        }
    }
}


