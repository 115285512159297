import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    GET_ACK_MESSAGES_ERROR,
    GET_ACK_MESSAGES_STARTING,
    GET_ACK_MESSAGES_SUCCESS,
    ADD_ACK_MESSAGE_SUCCESS,
    UPDATE_ACK_MESSAGE_SUCCESS,
} from '../../constants/actionTypes';

export default function ackMessagesReducer(state = initialState.ackMessages, action) {
    switch (action.type) {
        case GET_ACK_MESSAGES_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

    case GET_ACK_MESSAGES_SUCCESS: {
        const newState = cloneDeep(state);
        const ids = [];
        const map = {};

        action.data.ackMessages.forEach((ackMessage) => {
            const ackMessageId = ackMessage.id;
            ids.push(ackMessageId);
            map[ackMessageId] = ackMessage;
        });

        newState.ids = ids;
        newState.map = map;
        //newState.page = action.data.page;
        //newState.pages = action.data.pages;
        //newState.limit = action.data.limit;
        //newState.total = action.data.total;
        //newState.isLastPage = newState.page >= newState.pages;
        newState.loading = false;
        newState.loaded = true;

        return newState;
    }

    case GET_ACK_MESSAGES_ERROR: {
        const newState = cloneDeep(state);
        newState.loading = false;
        newState.loaded = false;
        newState.error = action.err ? action.err : 'Failed to retrieve ack messages.';
        return newState;
    }

    case ADD_ACK_MESSAGE_SUCCESS: {
        const newState = cloneDeep(state);
        const { ackMessage } = action.data;
        const ackMessageId = ackMessage.id;

        // Push new ack message data
        newState.map[ackMessageId] = ackMessage;
        newState.ids.push(ackMessageId);
        
        newState.ids.sort((loc1Id, loc2Id) => {
            const loc1 = newState.map[loc1Id];
            const loc2 = newState.map[loc2Id];
            return loc1.id - loc2.id;
        });

        // TODO Sort by display order

        return newState;
    }

    case UPDATE_ACK_MESSAGE_SUCCESS: {
        const newState = cloneDeep(state);
        const { ackMessage } = action.data;
        const ackMessageId = ackMessage.id;
        newState.map[ackMessageId] = ackMessage;
        return newState;
    }

    default: {
        return state;
    }
  }
}
