import { cloneDeep } from 'lodash';
import {
    SEND_ENQUIRY_REQUEST_STARTING,
    SEND_ENQUIRY_REQUEST_SUCCESS,
    SEND_ENQUIRY_REQUEST_ERROR,
} from '../constants/actionTypes';
import initialState from '../store/initialState';

export default function enquiryReducer(state = initialState.enquiryRequest, action) {
    switch (action.type) {
        case SEND_ENQUIRY_REQUEST_STARTING: {
            const newState = cloneDeep(state);
            newState.saving = true;
            newState.saved = false;
            return newState;
        }

        case SEND_ENQUIRY_REQUEST_SUCCESS: {
            const newState = cloneDeep(state);
            newState.saving = false;
            newState.saved = true;
            newState.error = '';
            return newState;
        }

        case SEND_ENQUIRY_REQUEST_ERROR: {
            const newState = cloneDeep(state);
            newState.saving = false;
            newState.saved = false;

            newState.error = action.err;
            return newState;
        }

        default: {
            return state;
        }
    }
}

