import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  DEACTIVATE_NETWORK_STARTING,
  DEACTIVATE_NETWORK_SUCCESS,
  DEACTIVATE_NETWORK_ERROR,
} from '../../constants/actionTypes';

export default function deactivateNetworkReducer(state = initialState.deactivateNetwork, action) {
  switch (action.type) {
    case DEACTIVATE_NETWORK_STARTING: {
      const {networkId} = action;
      const newState = clone(state);

      if (!newState[networkId]) {
        newState[networkId] = clone(initialState.deactivateNetwork.networkId);
      }

      const locationData = newState[networkId];
      locationData.saving = true;
      locationData.saved = false;
      locationData.error = null;

      return newState;
    }

    case DEACTIVATE_NETWORK_SUCCESS: {
      const {networkId} = action;
      const newState = clone(state);

      if (!newState[networkId]) {
        newState[networkId] = clone(initialState.deactivateNetwork.networkId);
      }

      const locationData = newState[networkId];
      locationData.saving = false;
      locationData.saved = true;
      locationData.error = null;
      return newState;
    }

    case DEACTIVATE_NETWORK_ERROR: {
      const {networkId} = action;
      const newState = clone(state);

      if (!newState[networkId]) {
        newState[networkId] = clone(initialState.deactivateNetwork.networkId);
      }

      const locationData = newState[networkId];
      locationData.saving = false;
      locationData.saved = false;
      locationData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
