export const IGNORE_ACTION = 'IGNORE_ACTION';
export const SOCKET_CONNECT_STARTING = 'SOCKET_CONNECT_STARTING';
export const SOCKET_CONNECT_SUCCESS = 'SOCKET_CONNECT_SUCCESS';
export const SOCKET_CONNECT_ERROR = 'SOCKET_CONNECT_ERROR';
export const SOCKET_DISCONNECTED = 'SOCKET_DISCONNECTED';
export const HEARTBEAT_SUCCESS = 'HEARTBEAT_SUCCESS';
export const RECEIVE_ALERTS = 'RECEIVE_ALERTS';
export const RECEIVE_PARAM_DATA = 'RECEIVE_PARAM_DATA';

export const GET_USER_INFO_STARTING = 'GET_USER_INFO_STARTING';
export const GET_USER_INFO_ERROR = 'GET_USER_INFO_ERROR';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';

export const SAVE_PREV_ROUTE = 'SAVE_PREV_ROUTE';
export const FORCE_USER_LOGIN = 'FORCE_USER_LOGIN';

export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_DATA_NONE = 'SET_USER_DATA_NONE';

export const LOGIN_CHECK_STARTING = 'LOGIN_CHECK_STARTING';
export const LOGIN_CHECK_ERROR = 'LOGIN_CHECK_ERROR';
export const LOGIN_CHECK_SUCCESS = 'LOGIN_CHECK_SUCCESS';

export const AUTO_LOGIN_STARTING = 'AUTO_LOGIN_STARTING';
export const AUTO_LOGIN_ERROR = 'AUTO_LOGIN_ERROR';
export const AUTO_LOGIN_SUCCESS = 'AUTO_LOGIN_SUCCESS';

export const LOGOUT_STARTING = 'LOGOUT_STARTING';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const ENABLE_FULL_SCREEN = 'ENABLE_FULL_SCREEN';
export const DISABLE_FULL_SCREEN = 'DISABLE_FULL_SCREEN';

export const GET_LOCATIONS_STARTING = 'GET_LOCATIONS_STARTING';
export const GET_LOCATIONS_ERROR = 'GET_LOCATIONS_ERROR';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';

export const RESET_PASSWORD_STARTING = 'RESET_PASSWORD_STARTING';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const GET_ALARMS_STARTING = 'GET_ALARMS_STARTING';
export const GET_ALARMS_SUCCESS = 'GET_ALARMS_SUCCESS';
export const GET_ALARMS_ERROR = 'GET_ALARMS_ERROR';

export const GET_ALERTS_STARTING = 'GET_ALERTS_STARTING';
export const GET_ALERTS_SUCCESS = 'GET_ALERTS_SUCCESS';
export const GET_ALERTS_ERROR = 'GET_ALERTS_ERROR';

export const ACKNOWLEDGE_ALARM_STARTING = 'ACKNOWLEDGE_ALARM_STARTING';
export const ACKNOWLEDGE_ALARM_SUCCESS = 'ACKNOWLEDGE_ALARM_SUCCESS';
export const ACKNOWLEDGE_ALARM_ERROR = 'ACKNOWLEDGE_ALARM_ERROR';

export const UPDATE_PASSWORD_STARTING = 'UPDATE_PASSWORD_STARTING';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR'

export const UPDATE_AUTHORIZATION_PASSWORD_STARTING = 'UPDATE_AUTHORIZATION_PASSWORD_STARTING';
export const UPDATE_AUTHORIZATION_PASSWORD_SUCCESS = 'UPDATE_AUTHORIZATION_PASSWORD_SUCCESS';
export const UPDATE_AUTHORIZATION_PASSWORD_ERROR = 'UPDATE_AUTHORIZATION_PASSWORD_ERROR';

export const GET_FLOOR_PLAN_DATA_STARTING = 'GET_FLOOR_PLAN_DATA_STARTING';
export const GET_FLOOR_PLAN_DATA_SUCCESS = 'GET_FLOOR_PLAN_DATA_SUCCESS';
export const GET_FLOOR_PLAN_DATA_ERROR = 'GET_FLOOR_PLAN_DATA_ERROR';

export const GET_USERS_STARTING = 'GET_USERS_STARTING';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

export const ADD_USER_STARTING = 'ADD_USER_STARTING';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';

export const UPDATE_USER_DETAILS_STARTING = 'UPDATE_USER_DETAILS_STARTING';
export const UPDATE_USER_DETAILS_SUCCESS = 'UPDATE_USER_DETAILS_SUCCESS';
export const UPDATE_USER_DETAILS_ERROR = 'UPDATE_USER_DETAILS_ERROR';

export const UPDATE_PARAM_LIMITS_STARTING = 'UPDATE_PARAM_LIMITS_STARTING';
export const UPDATE_PARAM_LIMITS_SUCCESS = 'UPDATE_PARAM_LIMITS_SUCCESS';
export const UPDATE_PARAM_LIMITS_ERROR = 'UPDATE_PARAM_LIMITS_ERROR';

export const GET_LOCATION_STARTING = 'GET_LOCATION_STARTING';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_ERROR = 'GET_LOCATION_ERROR';

export const GET_LOCATION_DEVICES_STARTING = 'GET_LOCATION_DEVICES_STARTING';
export const GET_LOCATION_DEVICES_SUCCESS = 'GET_LOCATION_DEVICES_SUCCESS';
export const GET_LOCATION_DEVICES_ERROR = 'GET_LOCATION_DEVICES_ERROR';

export const GET_AREA_DEVICES_STARTING = 'GET_AREA_DEVICES_STARTING';

export const GET_AREA_DEVICES_SUCCESS = 'GET_AREA_DEVICES_SUCCESS';

export const GET_AREA_DEVICES_ERROR = 'GET_AREA_DEVICES_ERROR';


export const GET_LOCATION_ROOMS_STARTING = 'GET_LOCATION_ROOMS_STARTING';
export const GET_LOCATION_ROOMS_SUCCESS = 'GET_LOCATION_ROOMS_SUCCESS';
export const GET_LOCATION_ROOMS_ERROR = 'GET_LOCATION_ROOMS_ERROR';

export const GET_LOCATION_AREAS_STARTING = 'GET_LOCATION_AREAS_STARTING';
export const GET_LOCATION_AREAS_SUCCESS = 'GET_LOCATION_AREAS_SUCCESS';
export const GET_LOCATION_AREAS_ERROR = 'GET_LOCATION_AREAS_ERROR';

export const GET_AREA_ROOMS_STARTING = 'GET_AREA_ROOMS_STARTING';
export const GET_AREA_ROOMS_SUCCESS = 'GET_AREA_ROOMS_SUCCESS';
export const GET_AREA_ROOMS_ERROR = 'GET_AREA_ROOMS_ERROR';

export const GET_DEVICE_STARTING = 'GET_DEVICE_STARTING';
export const GET_DEVICE_SUCCESS = 'GET_DEVICE_SUCCESS';
export const GET_DEVICE_ERROR = 'GET_DEVICE_ERROR';

export const START_DEVICE_STARTING = 'START_DEVICE_STARTING';
export const START_DEVICE_SUCCESS = 'START_DEVICE_SUCCESS';
export const START_DEVICE_ERROR = 'START_DEVICE_ERROR';

export const STOP_DEVICE_STARTING = 'STOP_DEVICE_STARTING';
export const STOP_DEVICE_SUCCESS = 'STOP_DEVICE_SUCCESS';
export const STOP_DEVICE_ERROR = 'STOP_DEVICE_ERROR';

export const START_ALL_DEVICES_STARTING = 'START_ALL_DEVICES_STARTING';
export const START_ALL_DEVICES_SUCCESS = 'START_ALL_DEVICES_SUCCESS';
export const START_ALL_DEVICES_ERROR = 'START_ALL_DEVICES_ERROR';

export const STOP_ALL_DEVICES_STARTING = 'STOP_ALL_DEVICES_STARTING';
export const STOP_ALL_DEVICES_SUCCESS = 'STOP_ALL_DEVICES_SUCCESS';
export const STOP_ALL_DEVICES_ERROR = 'STOP_ALL_DEVICES_ERROR';

export const GET_CONTROL_SCHEDULES_STARTING = 'GET_CONTROL_SCHEDULES_STARTING';
export const GET_CONTROL_SCHEDULES_SUCCESS = 'GET_CONTROL_SCHEDULES_SUCCESS';
export const GET_CONTROL_SCHEDULES_ERROR = 'GET_CONTROL_SCHEDULES_ERROR';

export const CREATE_CONTROL_SCHEDULE_STARTING = 'CREATE_CONTROL_SCHEDULE_STARTING';
export const CREATE_CONTROL_SCHEDULE_SUCCESS = 'CREATE_CONTROL_SCHEDULE_SUCCESS';
export const CREATE_CONTROL_SCHEDULE_ERROR = 'CREATE_CONTROL_SCHEDULE_ERROR';

export const DELETE_CONTROL_SCHEDULE_STARTING = 'DELETE_CONTROL_SCHEDULE_STARTING';
export const DELETE_CONTROL_SCHEDULE_SUCCESS = 'DELETE_CONTROL_SCHEDULE_SUCCESS';
export const DELETE_CONTROL_SCHEDULE_ERROR = 'DELETE_CONTROL_SCHEDULE_ERROR';

export const UPDATE_CONTROL_SCHEDULE_STARTING = 'UPDATE_CONTROL_SCHEDULE_STARTING';
export const UPDATE_CONTROL_SCHEDULE_SUCCESS = 'UPDATE_CONTROL_SCHEDULE_SUCCESS';
export const UPDATE_CONTROL_SCHEDULE_ERROR = 'UPDATE_CONTROL_SCHEDULE_ERROR';

export const GET_COMMON_DEVICES_STARTING = 'GET_COMMON_DEVICES_STARTING';
export const GET_COMMON_DEVICES_SUCCESS = 'GET_COMMON_DEVICES_SUCCESS';
export const GET_COMMON_DEVICES_ERROR = 'GET_COMMON_DEVICES_ERROR';

export const GET_REPORT_TEMPLATES_STARTING = 'GET_REPORT_TEMPLATES_STARTING';
export const GET_REPORT_TEMPLATES_SUCCESS = 'GET_REPORT_TEMPLATES_SUCCESS';
export const GET_REPORT_TEMPLATES_ERROR = 'GET_REPORT_TEMPLATES_ERROR';

export const STOP_HOOTERS_STARTING = 'STOP_HOOTERS_STARTING';
export const STOP_HOOTERS_SUCCESS = 'STOP_HOOTERS_SUCCESS';
export const STOP_HOOTERS_ERROR = 'STOP_HOOTERS_ERROR';

export const GET_BACKUPS_STARTING = 'GET_BACKUPS_STARTING';
export const GET_BACKUPS_SUCCESS = 'GET_BACKUPS_SUCCESS';
export const GET_BACKUPS_ERROR = 'GET_BACKUPS_ERROR';

export const CREATE_BACKUP_STARTING = 'CREATE_BACKUP_STARTING';
export const CREATE_BACKUP_SUCCESS = 'CREATE_BACKUP_SUCCESS';
export const CREATE_BACKUP_ERROR = 'CREATE_BACKUP_ERROR';

export const GET_ACK_MESSAGES_STARTING = 'GET_ACK_MESSAGES_STARTING';
export const GET_ACK_MESSAGES_SUCCESS = 'GET_ACK_MESSAGES_SUCCESS';
export const GET_ACK_MESSAGES_ERROR = 'GET_ACK_MESSAGES_ERROR';

export const ADD_ACK_MESSAGE_STARTING = 'ADD_ACK_MESSAGE_STARTING';
export const ADD_ACK_MESSAGE_SUCCESS = 'ADD_ACK_MESSAGE_SUCCESS';
export const ADD_ACK_MESSAGE_ERROR = 'ADD_ACK_MESSAGE_ERROR';

export const UPDATE_ACK_MESSAGE_STARTING = 'UPDATE_ACK_MESSAGE_STARTING';
export const UPDATE_ACK_MESSAGE_SUCCESS = 'UPDATE_ACK_MESSAGE_SUCCESS';
export const UPDATE_ACK_MESSAGE_ERROR = 'UPDATE_ACK_MESSAGE_ERROR';

export const DELETE_ACK_MESSAGE_STARTING = 'DELETE_ACK_MESSAGE_STARTING';
export const DELETE_ACK_MESSAGE_SUCCESS = 'DELETE_ACK_MESSAGE_SUCCESS';
export const DELETE_ACK_MESSAGE_ERROR = 'DELETE_ACK_MESSAGE_ERROR';

export const CHECK_HMI_MAPPING_STARTING = 'CHECK_HMI_MAPPING_STARTING';
export const CHECK_HMI_MAPPING_SUCCESS = 'CHECK_HMI_MAPPING_SUCCESS';
export const CHECK_HMI_MAPPING_ERROR = 'CHECK_HMI_MAPPING_ERROR';

export const GET_HMI_MAPPING_STARTING = 'GET_HMI_MAPPING_STARTING';
export const GET_HMI_MAPPING_SUCCESS = 'GET_HMI_MAPPING_SUCCESS';
export const GET_HMI_MAPPING_ERROR = 'GET_HMI_MAPPING_ERROR';

export const DELETE_ALL_BACKUPS_STARTING = 'DELETE_ALL_BACKUPS_STARTING';
export const DELETE_ALL_BACKUPS_SUCCESS = 'DELETE_ALL_BACKUPS_SUCCESS';
export const DELETE_ALL_BACKUPS_ERROR = 'DELETE_ALL_BACKUPS_ERROR';

export const DELETE_ALL_ALARMS_STARTING = 'DELETE_ALL_ALARMS_STARTING';
export const DELETE_ALL_ALARMS_SUCCESS = 'DELETE_ALL_ALARMS_SUCCESS';
export const DELETE_ALL_ALARMS_ERROR = 'DELETE_ALL_ALARMS_ERROR';

export const SAVE_REPORT_SETTINGS_STARTING = 'SAVE_REPORT_SETTINGS_STARTING';
export const SAVE_REPORT_SETTINGS_SUCCESS = 'SAVE_REPORT_SETTINGS_SUCCESS';
export const SAVE_REPORT_SETTINGS_ERROR = 'SAVE_REPORT_SETTINGS_ERROR';

export const UPDATE_ROLE_STARTING = 'UPDATE_ROLE_STARTING';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_ERROR = 'UPDATE_ROLE_ERROR';

export const START_POLLING_PROCESS_STARTING = 'START_POLLING_PROCESS_STARTING';
export const START_POLLING_PROCESS_SUCCESS = 'START_POLLING_PROCESS_SUCCESS';
export const START_POLLING_PROCESS_ERROR = 'START_POLLING_PROCESS_ERROR';

export const STOP_POLLING_PROCESS_STARTING = 'STOP_POLLING_PROCESS_STARTING';
export const STOP_POLLING_PROCESS_SUCCESS = 'STOP_POLLING_PROCESS_SUCCESS';
export const STOP_POLLING_PROCESS_ERROR = 'STOP_POLLING_PROCESS_ERROR';

export const GET_PARAM_DATA_STARTING = 'GET_PARAM_DATA_STARTING';
export const GET_PARAM_DATA_SUCCESS = 'GET_PARAM_DATA_SUCCESS';
export const GET_PARAM_DATA_ERROR = 'GET_PARAM_DATA_ERROR';

export const GET_CATEGORIES_STARTING = 'GET_CATEGORIES_STARTING';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';
export const GET_INACTIVE_CATEGORIES_STARTING = 'GET_INACTIVE_CATEGORIES_STARTING';
export const GET_INACTIVE_CATEGORIES_SUCCESS = 'GET_INACTIVE_CATEGORIES_SUCCESS';
export const GET_INACTIVE_CATEGORIES_ERROR = 'GET_INACTIVE_CATEGORIES_ERROR';

export const ADD_CATEGORY_STARTING = 'ADD_CATEGORY_STARTING';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_ERROR = 'ADD_CATEGORY_ERROR';

export const UPDATE_CATEGORY_STARTING = 'UPDATE_CATEGORY_STARTING';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR';

export const DEACTIVATE_CATEGORY_STARTING = 'DEACTIVATE_CATEGORY_STARTING';
export const DEACTIVATE_CATEGORY_SUCCESS = 'DEACTIVATE_CATEGORY_SUCCESS';
export const DEACTIVATE_CATEGORY_ERROR = 'DEACTIVATE_CATEGORY_ERROR';

export const ACTIVATE_CATEGORY_STARTING = 'ACTIVATE_CATEGORY_STARTING';
export const ACTIVATE_CATEGORY_SUCCESS = 'ACTIVATE_CATEGORY_SUCCESS';
export const ACTIVATE_CATEGORY_ERROR = 'ACTIVATE_CATEGORY_ERROR';

export const GET_CONTROL_REQUESTS_STARTING = 'GET_CONTROL_REQUESTS_STARTING';
export const GET_CONTROL_REQUESTS_SUCCESS = 'GET_CONTROL_REQUESTS_SUCCESS';
export const GET_CONTROL_REQUESTS_ERROR = 'GET_CONTROL_REQUESTS_ERROR';

export const CREATE_CONTROL_REQUEST_STARTING = 'CREATE_CONTROL_REQUEST_STARTING';
export const CREATE_CONTROL_REQUEST_SUCCESS = 'CREATE_CONTROL_REQUEST_SUCCESS';
export const CREATE_CONTROL_REQUEST_ERROR = 'CREATE_CONTROL_REQUEST_ERROR';

export const UPDATE_CONTROL_REQUEST_STARTING = 'UPDATE_CONTROL_REQUEST_STARTING';
export const UPDATE_CONTROL_REQUEST_SUCCESS = 'UPDATE_CONTROL_REQUEST_SUCCESS';
export const UPDATE_CONTROL_REQUEST_ERROR = 'UPDATE_CONTROL_REQUEST_ERROR';

export const ACKNOWLEDGE_CONTROL_REQUEST_STARTING = 'ACKNOWLEDGE_CONTROL_REQUEST_STARTING';
export const ACKNOWLEDGE_CONTROL_REQUEST_SUCCESS = 'ACKNOWLEDGE_CONTROL_REQUEST_SUCCESS';
export const ACKNOWLEDGE_CONTROL_REQUEST_ERROR = 'ACKNOWLEDGE_CONTROL_REQUEST_ERROR';

export const SAVE_MAINTENANCE_SETTINGS_STARTING = 'SAVE_MAINTENANCE_SETTINGS_STARTING';
export const SAVE_MAINTENANCE_SETTINGS_SUCCESS = 'SAVE_MAINTENANCE_SETTINGS_SUCCESS';
export const SAVE_MAINTENANCE_SETTINGS_ERROR = 'SAVE_MAINTENANCE_SETTINGS_ERROR';

export const GET_CLIENT_INFO_STARTING = 'GET_CLIENT_INFO_STARTING';
export const GET_CLIENT_INFO_SUCCESS = 'GET_CLIENT_INFO_SUCCESS';
export const GET_CLIENT_INFO_ERROR = 'GET_CLIENT_INFO_ERROR';

export const ADD_LOCATION_STARTING = 'ADD_LOCATION_STARTING';
export const ADD_LOCATION_SUCCESS = 'ADD_LOCATION_SUCCESS';
export const ADD_LOCATION_ERROR = 'ADD_LOCATION_ERROR';

export const UPDATE_LOCATION_STARTING = 'UPDATE_LOCATION_STARTING';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_ERROR = 'UPDATE_LOCATION_ERROR';

export const ACTIVATE_LOCATION_STARTING = 'ACTIVATE_LOCATION_STARTING';
export const ACTIVATE_LOCATION_SUCCESS = 'ACTIVATE_LOCATION_SUCCESS';
export const ACTIVATE_LOCATION_ERROR = 'ACTIVATE_LOCATION_ERROR';

export const DEACTIVATE_LOCATION_STARTING = 'DEACTIVATE_LOCATION_STARTING';
export const DEACTIVATE_LOCATION_SUCCESS = 'DEACTIVATE_LOCATION_SUCCESS';
export const DEACTIVATE_LOCATION_ERROR = 'DEACTIVATE_LOCATION_ERROR';

export const ADD_ROOM_STARTING = 'ADD_ROOM_STARTING';
export const ADD_ROOM_SUCCESS = 'ADD_ROOM_SUCCESS';
export const ADD_ROOM_ERROR = 'ADD_ROOM_ERROR';

export const ADD_AREA_STARTING = 'ADD_AREA_STARTING';

export const ADD_AREA_SUCCESS = 'ADD_AREA_SUCCESS';

export const ADD_AREA_ERROR = 'ADD_AREA_ERROR';


export const UPDATE_ROOM_STARTING = 'UPDATE_ROOM_STARTING';
export const UPDATE_ROOM_SUCCESS = 'UPDATE_ROOM_SUCCESS';
export const UPDATE_ROOM_ERROR = 'UPDATE_ROOM_ERROR';

export const UPDATE_AREA_STARTING = 'UPDATE_AREA_STARTING';
export const UPDATE_AREA_SUCCESS = 'UPDATE_AREA_SUCCESS';

export const UPDATE_AREA_ERROR = 'UPDATE_AREA_ERROR';


export const ACTIVATE_ROOM_STARTING = 'ACTIVATE_ROOM_STARTING';
export const ACTIVATE_ROOM_SUCCESS = 'ACTIVATE_ROOM_SUCCESS';
export const ACTIVATE_ROOM_ERROR = 'ACTIVATE_ROOM_ERROR';

export const ACTIVATE_AREA_STARTING = 'ACTIVATE_AREA_STARTING';

export const ACTIVATE_AREA_SUCCESS = 'ACTIVATE_AREA_SUCCESS';

export const ACTIVATE_AREA_ERROR = 'ACTIVATE_AREA_ERROR';


export const DEACTIVATE_ROOM_STARTING = 'DEACTIVATE_ROOM_STARTING';
export const DEACTIVATE_ROOM_SUCCESS = 'DEACTIVATE_ROOM_SUCCESS';
export const DEACTIVATE_ROOM_ERROR = 'DEACTIVATE_ROOM_ERROR';

export const DEACTIVATE_AREA_STARTING = 'DEACTIVATE_AREA_STARTING';

export const DEACTIVATE_AREA_SUCCESS = 'DEACTIVATE_AREA_SUCCESS';

export const DEACTIVATE_AREA_ERROR = 'DACTIVATE_AREA_ERROR';

export const GET_ROOM_DEVICES_STARTING = 'GET_ROOM_DEVICES_STARTING';
export const GET_ROOM_DEVICES_SUCCESS = 'GET_ROOM_DEVICES_SUCCESS';
export const GET_ROOM_DEVICES_ERROR = 'GET_ROOM_DEVICES_ERROR';

export const ADD_DEVICE_STARTING = 'ADD_DEVICE_STARTING';
export const ADD_DEVICE_SUCCESS = 'ADD_DEVICE_SUCCESS';
export const ADD_DEVICE_ERROR = 'ADD_DEVICE_ERROR';

export const ADD_AHU_DEVICE_STARTING = 'ADD_AHU_DEVICE_STARTING';
export const ADD_AHU_DEVICE_SUCCESS = 'ADD_AHU_DEVICE_SUCCESS';
export const ADD_AHU_DEVICE_ERROR = 'ADD_AHU_DEVICE_ERROR';

export const UPDATE_DEVICE_STARTING = 'UPDATE_DEVICE_STARTING';
export const UPDATE_DEVICE_SUCCESS = 'UPDATE_DEVICE_SUCCESS';
export const UPDATE_DEVICE_ERROR = 'UPDATE_DEVICE_ERROR';

export const ACTIVATE_DEVICE_STARTING = 'ACTIVATE_DEVICE_STARTING';
export const ACTIVATE_DEVICE_SUCCESS = 'ACTIVATE_DEVICE_SUCCESS';
export const ACTIVATE_DEVICE_ERROR = 'ACTIVATE_DEVICE_ERROR';

export const DEACTIVATE_DEVICE_STARTING = 'DEACTIVATE_DEVICE_STARTING';
export const DEACTIVATE_DEVICE_SUCCESS = 'DEACTIVATE_DEVICE_SUCCESS';
export const DEACTIVATE_DEVICE_ERROR = 'DEACTIVATE_DEVICE_ERROR';

export const GET_NETWORKS_STARTING = 'GET_NETWORKS_STARTING';
export const GET_NETWORKS_SUCCESS = 'GET_NETWORKS_SUCCESS';
export const GET_NETWORKS_ERROR = 'GET_NETWORKS_ERROR';

export const ADD_NETWORK_STARTING = 'ADD_NETWORK_STARTING';
export const ADD_NETWORK_SUCCESS = 'ADD_NETWORK_SUCCESS';
export const ADD_NETWORK_ERROR = 'ADD_NETWORK_ERROR';

export const UPDATE_NETWORK_STARTING = 'UPDATE_NETWORK_STARTING';
export const UPDATE_NETWORK_SUCCESS = 'UPDATE_NETWORK_SUCCESS';
export const UPDATE_NETWORK_ERROR = 'UPDATE_NETWORK_ERROR';

export const ACTIVATE_NETWORK_STARTING = 'ACTIVATE_NETWORK_STARTING';
export const ACTIVATE_NETWORK_SUCCESS = 'ACTIVATE_NETWORK_SUCCESS';
export const ACTIVATE_NETWORK_ERROR = 'ACTIVATE_NETWORK_ERROR';

export const DEACTIVATE_NETWORK_STARTING = 'DEACTIVATE_NETWORK_STARTING';
export const DEACTIVATE_NETWORK_SUCCESS = 'DEACTIVATE_NETWORK_SUCCESS';
export const DEACTIVATE_NETWORK_ERROR = 'DEACTIVATE_NETWORK_ERROR';

export const GET_DEVICE_PARAMETERS_STARTING = 'GET_DEVICE_PARAMETERS_STARTING';
export const GET_DEVICE_PARAMETERS_SUCCESS = 'GET_DEVICE_PARAMETERS_SUCCESS';
export const GET_DEVICE_PARAMETERS_ERROR = 'GET_DEVICE_PARAMETERS_ERROR';

export const ADD_PARAMETER_STARTING = 'ADD_PARAMETER_STARTING';
export const ADD_PARAMETER_SUCCESS = 'ADD_PARAMETER_SUCCESS';
export const ADD_PARAMETER_ERROR = 'ADD_PARAMETER_ERROR';

export const UPDATE_PARAMETER_STARTING = 'UPDATE_PARAMETER_STARTING';
export const UPDATE_PARAMETER_SUCCESS = 'UPDATE_PARAMETER_SUCCESS';
export const UPDATE_PARAMETER_ERROR = 'UPDATE_PARAMETER_ERROR';

export const ACTIVATE_PARAMETER_STARTING = 'ACTIVATE_PARAMETER_STARTING';
export const ACTIVATE_PARAMETER_SUCCESS = 'ACTIVATE_PARAMETER_SUCCESS';
export const ACTIVATE_PARAMETER_ERROR = 'ACTIVATE_PARAMETER_ERROR';

export const DEACTIVATE_PARAMETER_STARTING = 'DEACTIVATE_PARAMETER_STARTING';
export const DEACTIVATE_PARAMETER_SUCCESS = 'DEACTIVATE_PARAMETER_SUCCESS';
export const DEACTIVATE_PARAMETER_ERROR = 'DEACTIVATE_PARAMETER_ERROR';

export const GET_ROLES_STARTING = 'GET_ROLES_STARTING';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_ERROR = 'GET_ROLES_ERROR';

export const ADD_ROLE_STARTING = 'ADD_ROLE_STARTING';
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';
export const ADD_ROLE_ERROR = 'ADD_ROLE_ERROR';

export const ACTIVATE_ROLE_STARTING = 'ACTIVATE_ROLE_STARTING';
export const ACTIVATE_ROLE_SUCCESS = 'ACTIVATE_ROLE_SUCCESS';
export const ACTIVATE_ROLE_ERROR = 'ACTIVATE_ROLE_ERROR';

export const DEACTIVATE_ROLE_STARTING = 'DEACTIVATE_ROLE_STARTING';
export const DEACTIVATE_ROLE_SUCCESS = 'DEACTIVATE_ROLE_SUCCESS';
export const DEACTIVATE_ROLE_ERROR = 'DEACTIVATE_ROLE_ERROR';

export const GET_ROLE_PERMISSIONS_STARTING = 'GET_ROLE_PERMISSIONS_STARTING';
export const GET_ROLE_PERMISSIONS_SUCCESS = 'GET_ROLE_PERMISSIONS_SUCCESS';
export const GET_ROLE_PERMISSIONS_ERROR = 'GET_ROLE_PERMISSIONS_ERROR';

export const UPDATE_ROLE_PERMISSIONS_STARTING = 'UPDATE_ROLE_PERMISSIONS_STARTING';
export const UPDATE_ROLE_PERMISSIONS_SUCCESS = 'UPDATE_ROLE_PERMISSIONS_SUCCESS';
export const UPDATE_ROLE_PERMISSIONS_ERROR = 'UPDATE_ROLE_PERMISSIONS_ERROR';

export const SET_DEVICE_ROOMS_STARTING = 'SET_DEVICE_ROOMS_STARTING';
export const SET_DEVICE_ROOMS_SUCCESS = 'SET_DEVICE_ROOMS_SUCCESS';
export const SET_DEVICE_ROOMS_ERROR = 'SET_DEVICE_ROOMS_ERROR';

export const ADD_REPORT_TEMPLATE_STARTING = 'ADD_REPORT_TEMPLATE_STARTING';
export const ADD_REPORT_TEMPLATE_SUCCESS = 'ADD_REPORT_TEMPLATE_SUCCESS';
export const ADD_REPORT_TEMPLATE_ERROR = 'ADD_REPORT_TEMPLATE_ERROR';

export const ACTIVATE_REPORT_TEMPLATE_STARTING = 'ACTIVATE_REPORT_TEMPLATE_STARTING';
export const ACTIVATE_REPORT_TEMPLATE_SUCCESS = 'ACTIVATE_REPORT_TEMPLATE_SUCCESS';
export const ACTIVATE_REPORT_TEMPLATE_ERROR = 'ACTIVATE_REPORT_TEMPLATE_ERROR';

export const DEACTIVATE_REPORT_TEMPLATE_STARTING = 'DEACTIVATE_REPORT_TEMPLATE_STARTING';
export const DEACTIVATE_REPORT_TEMPLATE_SUCCESS = 'DEACTIVATE_REPORT_TEMPLATE_SUCCESS';
export const DEACTIVATE_REPORT_TEMPLATE_ERROR = 'DEACTIVATE_REPORT_TEMPLATE_ERROR';

export const UPDATE_REPORT_TEMPLATE_STARTING = 'UPDATE_REPORT_TEMPLATE_SARTING';
export const UPDATE_REPORT_TEMPLATE_SUCCESS = 'UPDATE_REPORT_TEMPLATE_SUCCESS';
export const UPDATE_REPORT_TEMPLATE_ERROR = 'UPDATE_REPORT_TEMPLATE_ERROR';

export const FILE_UPLOAD_STARTING = 'FILE_UPLOAD_STARTING';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR';
export const RESET_LAST_FILE_UPLOAD = 'RESET_LAST_FILE_UPLOAD';

export const UPDATE_CLIENT_INFO_ERROR = 'UPDATE_CLIENT_INFO_ERROR';
export const UPDATE_CLIENT_INFO_STARTING = 'UPDATE_CLIENT_INFO_STARTING';
export const UPDATE_CLIENT_INFO_SUCCESS = 'UPDATE_CLIENT_INFO_SUCCESS';

export const GET_AHU_FILTER_CLEANING_REQUESTS_STARTING = 'GET_AHU_FILTER_CLEANING_REQUESTS_STARTING';
export const GET_AHU_FILTER_CLEANING_REQUESTS_SUCCESS = 'GET_AHU_FILTER_CLEANING_REQUESTS_SUCCESS';
export const GET_AHU_FILTER_CLEANING_REQUESTS_ERROR = 'GET_AHU_FILTER_CLEANING_REQUESTS_ERROR';

export const ADD_AHU_FILTER_CLEANING_REQUEST_STARTING = 'ADD_AHU_FILTER_CLEANING_REQUEST_STARTING';
export const ADD_AHU_FILTER_CLEANING_REQUEST_SUCCESS = 'ADD_AHU_FILTER_CLEANING_REQUEST_SUCCESS';
export const ADD_AHU_FILTER_CLEANING_REQUEST_ERROR = 'ADD_AHU_FILTER_CLEANING_REQUEST_ERROR';

export const MARK_AS_DONE_AHU_FILTER_CLEANING_REQUEST_STARTING = 'MARK_AS_DONE_AHU_FILTER_CLEANING_REQUEST_STARTING';
export const MARK_AS_DONE_AHU_FILTER_CLEANING_REQUEST_SUCCESS = 'MARK_AS_DONE_AHU_FILTER_CLEANING_REQUEST_SUCCESS';
export const MARK_AS_DONE_AHU_FILTER_CLEANING_REQUEST_ERROR = 'MARK_AS_DONE_AHU_FILTER_CLEANING_REQUEST_ERROR';

export const CLOSE_AHU_FILTER_CLEANING_REQUEST_STARTING = 'CLOSE_AHU_FILTER_CLEANING_REQUEST_STARTING';
export const CLOSE_AHU_FILTER_CLEANING_REQUEST_SUCCESS = 'CLOSE_AHU_FILTER_CLEANING_REQUEST_SUCCESS';
export const CLOSE_AHU_FILTER_CLEANING_REQUEST_ERROR = 'CLOSE_AHU_FILTER_CLEANING_REQUEST_ERROR';

export const ACKNOWLEDGE_AHU_FILTER_CLEANING_REQUEST_STARTING = 'ACKNOWLEDGE_AHU_FILTER_CLEANING_REQUEST_STARTING';
export const ACKNOWLEDGE_AHU_FILTER_CLEANING_REQUEST_SUCCESS = 'ACKNOWLEDGE_AHU_FILTER_CLEANING_REQUEST_SUCCESS';
export const ACKNOWLEDGE_AHU_FILTER_CLEANING_REQUEST_ERROR = 'ACKNOWLEDGE_AHU_FILTER_CLEANING_REQUEST_ERROR';

export const GET_AHU_ON_OFF_REQUESTS_STARTING = 'GET_AHU_ON_OFF_REQUESTS_STARTING';
export const GET_AHU_ON_OFF_REQUESTS_SUCCESS = 'GET_AHU_ON_OFF_REQUESTS_SUCCESS';
export const GET_AHU_ON_OFF_REQUESTS_ERROR = 'GET_AHU_ON_OFF_REQUESTS_ERROR';

export const ADD_AHU_ON_OFF_REQUEST_STARTING = 'ADD_AHU_ON_OFF_REQUEST_STARTING';
export const ADD_AHU_ON_OFF_REQUEST_SUCCESS = 'ADD_AHU_ON_OFF_REQUEST_SUCCESS';
export const ADD_AHU_ON_OFF_REQUEST_ERROR = 'ADD_AHU_ON_OFF_REQUEST_ERROR';

export const ACKNOWLEDGE_AHU_ON_OFF_REQUEST_STARTING = 'ACKNOWLEDGE_AHU_ON_OFF_REQUEST_STARTING';
export const ACKNOWLEDGE_AHU_ON_OFF_REQUEST_SUCCESS = 'ACKNOWLEDGE_AHU_ON_OFF_REQUEST_SUCCESS';
export const ACKNOWLEDGE_AHU_ON_OFF_REQUEST_ERROR = 'ACKNOWLEDGE_AHU_ON_OFF_REQUEST_ERROR';

export const CLOSE_AHU_ON_OFF_REQUEST_STARTING = 'CLOSE_AHU_ON_OFF_REQUEST_STARTING';
export const CLOSE_AHU_ON_OFF_REQUEST_SUCCESS = 'CLOSE_AHU_ON_OFF_REQUEST_SUCCESS';
export const CLOSE_AHU_ON_OFF_REQUEST_ERROR = 'CLOSE_AHU_ON_OFF_REQUEST_ERROR';

export const MARK_AS_DONE_AHU_ON_OFF_REQUEST_STARTING = 'MARK_AS_DONE_AHU_ON_OFF_REQUEST_STARTING';
export const MARK_AS_DONE_AHU_ON_OFF_REQUEST_SUCCESS = 'MARK_AS_DONE_AHU_ON_OFF_REQUEST_SUCCESS';
export const MARK_AS_DONE_AHU_ON_OFF_REQUEST_ERROR = 'MARK_AS_DONE_AHU_ON_OFF_REQUEST_ERROR';

export const GET_AHU_DUST_COLLECTOR_FILTER_REQUESTS_STARTING = 'GET_AHU_DUST_COLLECTOR_FILTER_REQUESTS_STARTING';
export const GET_AHU_DUST_COLLECTOR_FILTER_REQUESTS_SUCCESS = 'GET_AHU_DUST_COLLECTOR_FILTER_REQUESTS_SUCCESS';
export const GET_AHU_DUST_COLLECTOR_FILTER_REQUESTS_ERROR = 'GET_AHU_DUST_COLLECTOR_FILTER_REQUESTS_ERROR';

export const ADD_AHU_DUST_COLLECTOR_FILTER_REQUEST_STARTING = 'ADD_AHU_DUST_COLLECTOR_FILTER_REQUEST_STARTING';
export const ADD_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS = 'ADD_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS';
export const ADD_AHU_DUST_COLLECTOR_FILTER_REQUEST_ERROR = 'ADD_AHU_DUST_COLLECTOR_FILTER_REQUEST_ERROR';

export const ACKNOWLEDGE_AHU_DUST_COLLECTOR_FILTER_REQUEST_STARTING = 'ACKNOWLEDGE_AHU_DUST_COLLECTOR_FILTER_REQUEST_STARTING';
export const ACKNOWLEDGE_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS = 'ACKNOWLEDGE_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS';
export const ACKNOWLEDGE_AHU_DUST_COLLECTOR_FILTER_REQUEST_ERROR = 'ACKNOWLEDGE_AHU_DUST_COLLECTOR_FILTER_REQUEST_ERROR';

export const CLOSE_AHU_DUST_COLLECTOR_FILTER_REQUEST_STARTING = 'CLOSE_AHU_DUST_COLLECTOR_FILTER_REQUEST_STARTING';
export const CLOSE_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS = 'CLOSE_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS';
export const CLOSE_AHU_DUST_COLLECTOR_FILTER_REQUEST_ERROR = 'CLOSE_AHU_DUST_COLLECTOR_FILTER_REQUEST_ERROR';

export const MARK_AS_DONE_AHU_DUST_COLLECTOR_FILTER_REQUEST_STARTING = 'MARK_AS_DONE_AHU_DUST_COLLECTOR_FILTER_REQUEST_STARTING';
export const MARK_AS_DONE_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS = 'MARK_AS_DONE_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS';
export const MARK_AS_DONE_AHU_DUST_COLLECTOR_FILTER_REQUEST_ERROR = 'MARK_AS_DONE_AHU_DUST_COLLECTOR_FILTER_REQUEST_ERROR';

export const SET_FILTER_DETAILS_AHU_DUST_COLLECTOR_FILTER_REQUEST_STARTING = 'SET_FILTER_DETAILS_AHU_DUST_COLLECTOR_FILTER_REQUEST_STARTING';
export const SET_FILTER_DETAILS_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS = 'SET_FILTER_DETAILS_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS';
export const SET_FILTER_DETAILS_AHU_DUST_COLLECTOR_FILTER_REQUEST_ERROR = 'SET_FILTER_DETAILS_AHU_DUST_COLLECTOR_FILTER_REQUEST_ERROR';

export const RETURN_AHU_DUST_COLLECTOR_FILTER_REQUEST_STARTING = 'RETURN_AHU_DUST_COLLECTOR_FILTER_REQUEST_STARTING';
export const RETURN_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS = 'RETURN_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS';
export const RETURN_AHU_DUST_COLLECTOR_FILTER_REQUEST_ERROR = 'RETURN_AHU_DUST_COLLECTOR_FILTER_REQUEST_ERROR';

export const UPDATE_AHU_DUST_COLLECTOR_FILTER_REQUEST_STARTING = 'UPDATE_AHU_DUST_COLLECTOR_FILTER_REQUEST_STARTING';
export const UPDATE_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS = 'UPDATE_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS';
export const UPDATE_AHU_DUST_COLLECTOR_FILTER_REQUEST_ERROR = 'UPDATE_AHU_DUST_COLLECTOR_FILTER_REQUEST_ERROR';

export const GET_DUCT_CLEANING_REQUESTS_STARTING = 'GET_DUCT_CLEANING_REQUESTS_STARTING';
export const GET_DUCT_CLEANING_REQUESTS_SUCCESS = 'GET_DUCT_CLEANING_REQUESTS_SUCCESS';
export const GET_DUCT_CLEANING_REQUESTS_ERROR = 'GET_DUCT_CLEANING_REQUESTS_ERROR';

export const ADD_DUCT_CLEANING_REQUEST_STARTING = 'ADD_DUCT_CLEANING_REQUEST_STARTING';
export const ADD_DUCT_CLEANING_REQUEST_SUCCESS = 'ADD_DUCT_CLEANING_REQUEST_SUCCESS';
export const ADD_DUCT_CLEANING_REQUEST_ERROR = 'ADD_DUCT_CLEANING_REQUEST_ERROR';

export const ACKNOWLEDGE_DUCT_CLEANING_REQUEST_STARTING = 'ACKNOWLEDGE_DUCT_CLEANING_REQUEST_STARTING';
export const ACKNOWLEDGE_DUCT_CLEANING_REQUEST_SUCCESS = 'ACKNOWLEDGE_DUCT_CLEANING_REQUEST_SUCCESS';
export const ACKNOWLEDGE_DUCT_CLEANING_REQUEST_ERROR = 'ACKNOWLEDGE_DUCT_CLEANING_REQUEST_ERROR';

export const CLOSE_DUCT_CLEANING_REQUEST_STARTING = 'CLOSE_DUCT_CLEANING_REQUEST_STARTING';
export const CLOSE_DUCT_CLEANING_REQUEST_SUCCESS = 'CLOSE_DUCT_CLEANING_REQUEST_SUCCESS';
export const CLOSE_DUCT_CLEANING_REQUEST_ERROR = 'CLOSE_DUCT_CLEANING_REQUEST_ERROR';

export const MARK_AS_DONE_DUCT_CLEANING_REQUEST_STARTING = 'MARK_AS_DONE_DUCT_CLEANING_REQUEST_STARTING';
export const MARK_AS_DONE_DUCT_CLEANING_REQUEST_SUCCESS = 'MARK_AS_DONE_DUCT_CLEANING_REQUEST_SUCCESS';
export const MARK_AS_DONE_DUCT_CLEANING_REQUEST_ERROR = 'MARK_AS_DONE_DUCT_CLEANING_REQUEST_ERROR';

export const GET_ATTENDANCE_RECORDS_STARTING = 'GET_ATTENDANCE_RECORDS_STARTING';
export const GET_ATTENDANCE_RECORDS_SUCCESS = 'GET_ATTENDANCE_RECORDS_SUCCESS';
export const GET_ATTENDANCE_RECORDS_ERROR = 'GET_ATTENDANCE_RECORDS_ERROR';

export const GET_EMPLOYEES_STARTING = 'GET_EMPLOYEES_STARTING';
export const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS';
export const GET_EMPLOYEES_ERROR = 'GET_EMPLOYEES_ERROR';

export const GET_AUDIT_LOG_DATA_STARTING = 'GET_AUDIT_LOG_DATA_STARTING';
export const GET_AUDIT_LOG_DATA_SUCCESS = 'GET_AUDIT_LOG_DATA_SUCCESS';
export const GET_AUDIT_LOG_DATA_ERROR = 'GET_AUDIT_LOG_DATA_ERROR';

export const GET_ALARM_DATA_STARTING = 'GET_ALARM_DATA_STARTING';
export const GET_ALARM_DATA_SUCCESS = 'GET_ALARAM_DATA_SUCCESS';
export const GET_ALARM_DATA_ERROR = 'GET_ALARM_DATA_ERROR';

export const GET_REQUEST_NOTIFICATIONS_STARTING = 'GET_REQUEST_NOTIFICATIONS_STARTING';
export const GET_REQUEST_NOTIFICATIONS_SUCCESS = 'GET_REQUEST_NOTIFICATIONS_SUCCESS';
export const GET_REQUEST_NOTIFICATIONS_ERROR = 'GET_REQUEST_NOTIFICATIONS_ERROR';

export const GET_LOCATION_ACK_MESSAGES_STARTING = 'GET_LOCATION_ACK_MESSAGES_STARTING';

export const GET_LOCATION_ACK_MESSAGES_SUCCESS = 'GET_LOCATION_ACK_MESSAGES_SUCCESS';

export const GET_LOCATION_ACK_MESSAGES_ERROR = 'GET_LOCATION_ACK_MESSAGES_ERROR';

export const ACTIVATE_ACK_MESSAGES_STARTING = 'ACTIVATE_ACK_MESSAGES_STARTING';

export const ACTIVATE_ACK_MESSAGES_SUCCESS = 'ACTIVATE_ACK_MESSAGES_SUCCESS';

export const ACTIVATE_ACK_MESSAGES_ERROR = 'ACTIVATE_ACK_MESSAGES_ERROR';

export const DEACTIVATE_ACK_MESSAGE_STARTING = 'DEACTIVATE_ACK_MESSAGE_STARTING';

export const DEACTIVATE_ACK_MESSAGE_SUCCESS = 'DEACTIVATE_ACK_MESSAGE_SUCCESS';

export const DEACTIVATE_ACK_MESSAGE_ERROR = 'DEACTIVATE_ACK_MESSAGE_ERROR';

export const GET_INACTIVE_NETWORKS_STARTING = 'GET_INACTIVE_NETWORKS_STARTING';

export const GET_INACTIVE_NETWORKS_SUCCESS = 'GET_INACTIVE_NETWORKS_SUCCESS';

export const GET_INACTIVE_NETWORKS_ERROR = 'GET_INACTIVE_NETWORKS_ERROR';

export const GET_INACTIVE_LOCATIONS_STARTING = 'GET_INACTIVE_LOCATIONS_STARTING';
export const GET_INACTIVE_LOCATIONS_SUCCESS = 'GET_INACTIVE_LOCATIONS_SUCCESS';
export const GET_INACTIVE_LOCATIONS_ERROR = 'GET_INACTIVE_LOCATIONS_ERROR';

export const GET_MOBILE_TOWER_BY_ID_STARTING = 'GET_MOBILE_TOWER_BY_ID_STARTING';
export const GET_MOBILE_TOWER_BY_ID_SUCCESS = 'GET_MOBILE_TOWER_BY_ID_SUCCESS';
export const GET_MOBILE_TOWER_BY_ID_ERROR = 'GET_MOBILE_TOWER_BY_ID_ERROR';

export const GET_MOBILE_TOWERS_STARTING = 'GET_MOBILE_TOWERS_STARTING';
export const GET_MOBILE_TOWERS_SUCCESS = 'GET_MOBILE_TOWERS_SUCCESS';
export const GET_MOBILE_TOWERS_ERROR = 'GET_MOBILE_TOWERS_ERROR';

export const GET_INACTIVE_MOBILE_TOWERS_STARTING = 'GET_INACTIVE_MOBILE_TOWERS_STARTING';
export const GET_INACTIVE_MOBILE_TOWERS_SUCCESS = 'GET_INACTIVE_MOBILE_TOWERS_SUCCESS';
export const GET_INACTIVE_MOBILE_TOWERS_ERROR = 'GET_INACTIVE_MOBILE_TOWERS_ERROR';

export const ADD_MOBILE_TOWER_STARTING = 'ADD_MOBILE_TOWER_STARTING';
export const ADD_MOBILE_TOWER_SUCCESS = 'ADD_MOBILE_TOWER_SUCCESS';
export const ADD_MOBILE_TOWER_ERROR = 'ADD_MOBILE_TOWER_ERROR';

export const UPDATE_MOBILE_TOWER_STARTING = 'UPDATE_MOBILE_TOWER_STARTING';
export const UPDATE_MOBILE_TOWER_SUCCESS = 'UPDATE_MOBILE_TOWER_SUCCESS';
export const UPDATE_MOBILE_TOWER_ERROR = 'UPDATE_MOBILE_TOWER_ERROR';

export const DEACTIVATE_MOBILE_TOWER_STARTING = 'DEACTIVATE_MOBILE_TOWER_STARTING';
export const DEACTIVATE_MOBILE_TOWER_SUCCESS = 'DEACTIVATE_MOBILE_TOWER_SUCCESS';
export const DEACTIVATE_MOBILE_TOWER_ERROR = 'DEACTIVATE_MOBILE_TOWER_ERROR';

export const ACTIVATE_MOBILE_TOWER_STARTING = 'ACTIVATE_MOBILE_TOWER_STARTING';
export const ACTIVATE_MOBILE_TOWER_SUCCESS = 'ACTIVATE_MOBILE_TOWER_SUCCESS';
export const ACTIVATE_MOBILE_TOWER_ERROR = 'ACTIVATE_MOBILE_TOWER_ERROR';

export const ADD_DATA_PARAMETERS = 'ADD_DATA_PARAMETERS';
export const REMOVE_DATA_PARAMETERS = 'REMOVE_DATA_PARAMETERS';
export const SET_DATA_PARAMETERS = 'SET_DATA_PARAMETERS';
export const GET_PARAMETER_DATA_ERROR = 'GET_PARAMETER_DATA_ERROR';
export const CLEAR_DATA = 'CLEAR_DATA';

export const GET_SEARCH_RESULT_STARTING = 'GET_SEARCH_RESULT_STARTING';
export const GET_SEARCH_RESULT_SUCCESS = 'GET_SEARCH_RESULT_SUCCESS';
export const GET_SEARCH_RESULT_ERROR = 'GET_SEARCH_RESULT_ERROR';

export const GET_SEARCH_SUGGESTIONS_STARTING = 'GET_SEARCH_SUGGESTIONS_STARTING';
export const GET_SEARCH_SUGGESTIONS_SUCCESS = 'GET_SEARCH_SUGGESTIONS_SUCCESS';
export const GET_SEARCH_SUGGESTIONS_ERROR = 'GET_SEARCH_SUGGESTIONS_ERROR';

export const SAVE_SEARCH_RESULT_STARTING = 'SAVE_SEARCH_RESULT_STARTING';
export const SAVE_SEARCH_RESULT_SUCCESS = 'SAVE_SEARCH_RESULT_SUCCESS';
export const SAVE_SEARCH_RESULT_ERROR = 'SAVE_SEARCH_RESULT_ERROR';

export const GET_DICT_WORDS_IN_PREFIX_STARTING = 'GET_DICT_WORDS_IN_PREFIX_STARTING';
export const GET_DICT_WORDS_IN_PREFIX_SUCCESS = 'GET_DICT_WORDS_IN_PREFIX_SUCCESS';
export const GET_DICT_WORDS_IN_PREFIX_ERROR = 'GET_DICT_WORDS_IN_PREFIX_ERROR';

export const GET_TEST_DETAILS_STARTING = 'GET_TEST_DETAILS_STARTING';
export const GET_TEST_DETAILS_SUCCESS = 'GET_TEST_DETAILS_SUCCESS';
export const GET_TEST_DETAILS_ERROR = 'GET_TEST_DETAILS_ERROR';

export const UPDATE_TEST_DETAILS_STARTING = 'UPDATE_TEST_DETAILS_STARTING';
export const UPDATE_TEST_DETAILS_SUCCESS = 'UPDATE_TEST_DETAILS_SUCCESS';
export const UPDATE_TEST_DETAILS_ERROR = 'UPDATE_TEST_DETAILS_ERROR';

export const GET_SAVED_REPORTS_STARTING = 'GET_SAVED_REPORTS_STARTING';
export const GET_SAVED_REPORTS_SUCCESS = 'GET_SAVED_REPORTS_SUCCESS';
export const GET_SAVED_REPORTS_ERROR = 'GET_SAVED_REPORTS_ERROR';

export const REVIEW_REPORT_STARTING = 'REVIEW_REPORT_STARTING';
export const REVIEW_REPORT_SUCCESS = 'REVIEW_REPORT_SUCCESS';
export const REVIEW_REPORT_ERROR = 'REVIEW_REPORT_ERROR';

export const APPROVE_REPORT_STARTING = 'APPROVE_REPORT_STARTING';
export const APPROVE_REPORT_SUCCESS = 'APPROVE_REPORT_SUCCESS';
export const APPROVE_REPORT_ERROR = 'APPROVE_REPORT_ERROR';

export const PRINT_REPORT_STARTING = 'PRINT_REPORT_STARTING';
export const PRINT_REPORT_SUCCESS = 'PRINT_REPORT_SUCCESS';
export const PRINT_REPORT_ERROR = 'PRINT_REPORT_ERROR';

export const SAVE_REPORT_COMMENTS_STARTING = 'SAVE_REPORT_COMMENTS_STARTING';
export const SAVE_REPORT_COMMENTS_SUCCESS = 'SAVE_REPORT_COMMENTS_SUCCESS';
export const SAVE_REPORT_COMMENTS_ERROR = 'SAVE_REPORT_COMMENTS_ERROR';

export const GET_DEVICE_DATA_REPORT_STARTING = 'GET_DEVICE_DATA_REPORT_STARTING';
export const GET_DEVICE_DATA_REPORT_SUCCESS = 'GET_DEVICE_DATA_REPORT_SUCCESS';
export const GET_DEVICE_DATA_REPORT_ERROR = 'GET_DEVICE_DATA_REPORT_ERROR';

export const GET_RUN_HOURS_STARTING = 'GET_RUN_HOURS_STARTING';
export const GET_RUN_HOURS_SUCCESS = 'GET_RUN_HOURS_SUCCESS';
export const GET_RUN_HOURS_ERROR = 'GET_RUN_HOURS_ERROR';

export const GET_JOBS_STARTING = 'GET_JOBS_STARTING';
export const GET_JOBS_SUCCESS = 'GET_JOBS_SUCCESS';
export const GET_JOBS_ERROR = 'GET_JOBS_ERROR';

export const GET_JOB_BY_ID_STARTING = 'GET_JOB_BY_ID_STARTING';
export const GET_JOB_BY_ID_SUCCESS = 'GET_JOB_BY_ID_SUCCESS';
export const GET_JOB_BY_ID_ERROR = 'GET_JOB_BY_ID_ERROR';

export const CREATE_JOB_APPLICATION_STARTING = 'CREATE_JOB_APPLICATION_STARTING';
export const CREATE_JOB_APPLICATION_SUCCESS = 'CREATE_JOB_APPLICATION_SUCCESS';
export const CREATE_JOB_APPLICATION_ERROR = 'CREATE_JOB_APPLICATION_ERROR';

export const GET_JOB_APPLICATION_STARTING = 'GET_JOB_APPLICATION_STARTING';
export const GET_JOB_APPLICATION_SUCCESS = 'GET_JOB_APPLICATION_SUCCESS';
export const GET_JOB_APPLICATION_ERROR = 'GET_JOB_APPLICATION_ERROR';

export const ADD_JOB_STARTING = 'ADD_JOB_STARTING';
export const ADD_JOB_SUCCESS = 'ADD_JOB_SUCCESS';
export const ADD_JOB_ERROR = 'ADD_JOB_ERROR';

export const UPDATE_JOB_APPLICATION_STARTING = 'UPDATE_JOB_APPLICATION_STARTING';
export const UPDATE_JOB_APPLICATION_SUCCESS = 'UPDATE_JOB_APPLICATION_SUCCESS';
export const UPDATE_JOB_APPLICATION_ERROR = 'UPDATE_JOB_APPLICATION_ERROR';

export const DELETE_JOB_APPLICATION_STARTING = 'DELETE_JOB_APPLICATION_STARTING';
export const DELETE_JOB_APPLICATION_SUCCESS = 'DELETE_JOB_APPLICATION_SUCCESS';
export const DELETE_JOB_APPLICATION_ERROR = 'DELETE_JOB_APPLICATION_ERROR';

export const GET_ALARM_NOTIFICATIONS_STARTING = 'GET_ALARM_NOTIFICATIONS_STARTING';
export const GET_ALARM_NOTIFICATIONS_SUCCESS = 'GET_ALARM_NOTIFICATIONS_SUCCESS';
export const GET_ALARM_NOTIFICATIONS_ERROR = 'GET_ALARM_NOTIFICATIONS_ERROR';

export const CREATE_JOB_STARTING = 'CREATE_JOB_STARTING';
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS';
export const CREATE_JOB_ERROR = 'CREATE_JOB_ERROR';

export const UPDATE_JOB_STARTING = 'UPDATE_JOB_STARTING';
export const UPDATE_JOB_SUCCESS = 'UPDATE_JOB_SUCCESS';
export const UPDATE_JOB_ERROR = 'UPDATE_JOB_ERROR';

export const ACTIVATE_JOB_STARTING = 'ACTIVATE_JOB_STARTING';
export const ACTIVATE_JOB_SUCCESS = 'ACTIVATE_JOB_SUCCESS';
export const ACTIVATE_JOB_ERROR = 'ACTIVATE_JOB_ERROR';

export const DEACTIVATE_JOB_STARTING = 'DEACTIVATE_JOB_STARTING';
export const DEACTIVATE_JOB_SUCCESS = 'DEACTIVATE_JOB_SUCCESS';
export const DEACTIVATE_JOB_ERROR = 'DEACTIVATE_JOB_ERROR';

export const CREATE_POLL_STARTING = 'CREATE_POLL_STARTING';
export const CREATE_POLL_SUCCESS = 'CREATE_POLL_SUCCESS';
export const CREATE_POLL_ERROR = 'CREATE_POLL_ERROR';

export const UPDATE_POLL_STARTING = 'UPDATE_POLL_STARTING';
export const UPDATE_POLL_SUCCESS = 'UPDATE_POLL_SUCCESS';
export const UPDATE_POLL_ERROR = 'UPDATE_POLL_ERROR';

export const ACTIVATE_POLL_STARTING = 'ACTIVATE_POLL_STARTING';
export const ACTIVATE_POLL_SUCCESS = 'ACTIVATE_POLL_SUCCESS';
export const ACTIVATE_POLL_ERROR = 'ACTIVATE_POLL_ERROR';

export const DEACTIVATE_POLL_STARTING = 'DEACTIVATE_POLL_STARTING';
export const DEACTIVATE_POLL_SUCCESS = 'DEACTIVATE_POLL_SUCCESS';
export const DEACTIVATE_POLL_ERROR = 'DEACTIVATE_POLL_ERROR';

export const SAVE_POLL_RESPONSE_STARTING = 'SAVE_POLL_RESPONSE_STARTING';
export const SAVE_POLL_RESPONSE_SUCCESS = 'SAVE_POLL_RESPONSE_SUCCESS';
export const SAVE_POLL_RESPONSE_ERROR = 'SAVE_POLL_RESPONSE_ERROR';

export const AI_CHAT_STARTING = 'AI_CHAT_STARTING';
export const AI_CHAT_ON_RESPONSE = 'AI_CHAT_ON_RESPONSE';
export const AI_CHAT_SUCCESS = 'AI_CHAT_SUCCESS';
export const AI_CHAT_ERROR = 'AI_CHAT_ERROR';

export const GET_MODULES_STARTING = 'GET_MODULES_STARTING';
export const GET_MODULES_SUCCESS = 'GET_MODULES_SUCCESS';
export const GET_MODULES_ERROR = 'GET_MODULES_ERROR';

export const GET_REFERENCE_DEVICES_STARTING = 'GET_REFERENCE_DEVICES_STARTING';
export const GET_REFERENCE_DEVICES_SUCCESS = 'GET_REFERENCE_DEVICES_SUCCESS';
export const GET_REFERENCE_DEVICES_ERROR = 'GET_REFERENCE_DEVICES_ERROR';

export const GET_REFERENCE_PARAMETERS_STARTING = 'GET_REFERENCE_PARAMETERS_STARTING';
export const GET_REFERENCE_PARAMETERS_SUCCESS = 'GET_REFERENCE_PARAMETERS_SUCCESS';
export const GET_REFERENCE_PARAMETERS_ERROR = 'GET_REFERENCE_PARAMETERS_ERROR';

export const CREATE_FROM_REFERENCE_DEVICE_STARTING = 'GET_REFERENCE_PARAMETERS_STARTING';
export const CREATE_FROM_REFERENCE_DEVICE_SUCCESS = 'CREATE_FROM_REFERENCE_DEVICE_SUCCESS';
export const CREATE_FROM_REFERENCE_DEVICE_ERROR = 'CREATE_FROM_REFERENCE_DEVICE_ERROR';

export const GET_APPLICATIONS_STARTING = 'GET_APPLICATIONS_STARTING';
export const GET_APPLICATIONS_SUCCESS = 'GET_APPLICATIONS_SUCCESS';
export const GET_APPLICATIONS_ERROR = 'GET_APPLICATIONS_ERROR';

export const GET_APPLICATION_LICENSES_STARTING = 'GET_APPLICATION_LICENSES_STARTING';
export const GET_APPLICATION_LICENSES_SUCCESS = 'GET_APPLICATION_LICENSES_SUCCESS';
export const GET_APPLICATION_LICENSES_ERROR = 'GET_APPLICATION_LICENSES_ERROR';

export const ADD_LICENSE_STARTING = 'ADD_LICENSE_STARTING';
export const ADD_LICENSE_SUCCESS = 'ADD_LICENSE_SUCCESS';
export const ADD_LICENSE_ERROR = 'ADD_LICENSE_ERROR';

export const UPDATE_LICENSE_STARTING = 'UPDATE_LICENSE_STARTING';
export const UPDATE_LICENSE_SUCCESS = 'UPDATE_LICENSE_SUCCESS';
export const UPDATE_LICENSE_ERROR = 'UPDATE_LICENSE_ERROR';

export const GET_USER_LOCATIONS_STARTING = 'GET_USER_LOCATIONS_STARTING';
export const GET_USER_LOCATIONS_SUCCESS = 'GET_USER_LOCATIONS_SUCCESS';
export const GET_USER_LOCATIONS_ERROR = 'GET_USER_LOCATIONS_ERROR';

export const ADD_DEFECT_STARTING = 'ADD_DEFECT_STARTING';
export const ADD_DEFECT_SUCCESS = 'ADD_DEFECT_SUCCESS';
export const ADD_DEFECT_ERROR = 'ADD_DEFECT_ERROR';

export const UPDATE_DEFECT_STARTING = 'UPDATE_DEFECT_STARTING';
export const UPDATE_DEFECT_SUCCESS = 'UPDATE_DEFECT_SUCCESS';
export const UPDATE_DEFECT_ERROR = 'UPDATE_DEFECT_ERROR';

export const ACTIVATE_DEFECT_STARTING = 'ACTIVATE_DEFECT_STARTING';
export const ACTIVATE_DEFECT_SUCCESS = 'ACTIVATE_DEFECT_SUCCESS';
export const ACTIVATE_DEFECT_ERROR = 'ACTIVATE_DEFECT_ERROR';

export const DEACTIVATE_DEFECT_STARTING = 'DEACTIVATE_DEFECT_STARTING';
export const DEACTIVATE_DEFECT_SUCCESS = 'DEACTIVATE_DEFECT_SUCCESS';
export const DEACTIVATE_DEFECT_ERROR = 'DEACTIVATE_DEFECT_ERROR';

export const ADD_TENANT_STARTING = 'ADD_TENANT_STARTING';
export const ADD_TENANT_SUCCESS = 'ADD_TENANT_SUCCESS';
export const ADD_TENANT_ERROR = 'ADD_TENANT_ERROR';

export const UPDATE_TENANT_STARTING = 'UPDATE_TENANT_STARTING';
export const UPDATE_TENANT_SUCCESS = 'UPDATE_TENANT_SUCCESS';
export const UPDATE_TENANT_ERROR = 'UPDATE_TENANT_ERROR';

export const ACTIVATE_TENANT_STARTING = 'ACTIVATE_TENANT_STARTING';
export const ACTIVATE_TENANT_SUCCESS = 'ACTIVATE_TENANT_SUCCESS';
export const ACTIVATE_TENANT_ERROR = 'ACTIVATE_TENANT_ERROR';

export const DEACTIVATE_TENANT_STARTING = 'DEACTIVATE_TENANT_STARTING';
export const DEACTIVATE_TENANT_SUCCESS = 'DEACTIVATE_TENANT_SUCCESS';
export const DEACTIVATE_TENANT_ERROR = 'DEACTIVATE_TENANT_ERROR';

export const GET_MODELS_STARTING = 'GET_MODELS_STARTING';
export const GET_MODELS_SUCCESS = 'GET_MODELS_SUCCESS';
export const GET_MODELS_ERROR = 'GET_MODELS_ERROR';

export const GET_WEATHER_DETAILS_STARTING = 'GET_WEATHER_DETAILS_STARTING';
export const GET_WEATHER_DETAILS_SUCCESS = 'GET_WEATHER_DETAILS_SUCCESS';
export const GET_WEATHER_DETAILS_ERROR = 'GET_WEATHER_DETAILS_ERROR';

export const GET_HYDRAULIC_TEST_BENCH_DATA_STARTING = 'GET_HYDRAULIC_TEST_BENCH_DATA_STARTING';
export const GET_HYDRAULIC_TEST_BENCH_DATA_SUCCESS = 'GET_HYDRAULIC_TEST_BENCH_DATA_SUCCESS';
export const GET_HYDRAULIC_TEST_BENCH_DATA_ERROR = 'GET_HYDRAULIC_TEST_BENCH_DATA_ERROR';

export const GET_GEAR_BOX_TEST_DATA_STARTING = 'GET_GEAR_BOX_TEST_DATA_STARTING';
export const GET_GEAR_BOX_TEST_DATA_SUCCESS = 'GET_GEAR_BOX_TEST_DATA_SUCCESS';
export const GET_GEAR_BOX_TEST_DATA_ERROR = 'GET_GEAR_BOX_TEST_DATA_ERROR';

export const SIGNUP_USER_STARTING = 'SIGNUP_USER_STARTING';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_ERROR = 'SIGNUP_USER_ERROR';

export const FORGOT_PASSWORD_STARTING = 'FORGOT_PASSWORD_STARTING';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

export const GET_PROPERTIES_STARTING = 'GET_PROPERTIES_STARTING';
export const GET_PROPERTIES_SUCCESS = 'GET_PROPERTIES_SUCCESS';
export const GET_PROPERTIES_ERROR = 'GET_PROPERTIES_ERROR';

export const GET_PROPERTY_BY_ID_STARTING = 'GET_PROPERTY_BY_ID_STARTING';
export const GET_PROPERTY_BY_ID_SUCCESS = 'GET_PROPERTY_BY_ID_SUCCESS';
export const GET_PROPERTY_BY_ID_ERROR = 'GET_PROPERTY_BY_ID_ERROR';

export const GET_PRODUCT_BY_ID_STARTING = 'GET_PRODUCT_BY_ID_STARTING';
export const GET_PRODUCT_BY_ID_SUCCESS = 'GET_PRODUCT_BY_ID_SUCCESS';
export const GET_PRODUCT_BY_ID_ERROR = 'GET_PRODUCT_BY_ID_ERROR';

export const GET_PRODUCTS_STARTING = 'GET_PRODUCTS_STARTING';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';

export const SEND_ENQUIRY_REQUEST_STARTING = 'SEND_ENQUIRY_REQUEST_STARTING';
export const SEND_ENQUIRY_REQUEST_SUCCESS = 'SEND_ENQUIRY_REQUEST_SUCCESS';
export const SEND_ENQUIRY_REQUEST_ERROR = 'SEND_ENQUIRY_REQUEST_ERROR';

export const SET_CART_QUANTITY_STARTING = 'SET_CART_QUANTITY_STARTING';
export const SET_CART_QUANTITY_SUCCESS = 'SET_CART_QUANTITY_SUCCESS';
export const SET_CART_QUANTITY_ERROR = 'SET_CART_QUANTITY_ERROR';

export const GET_SHOPPING_CART_STARTING = 'GET_SHOPPING_CART_STARTING';
export const GET_SHOPPING_CART_SUCCESS = 'GET_SHOPPING_CART_SUCCESS';
export const GET_SHOPPING_CART_ERROR = 'GET_SHOPPING_CART_ERROR';

export const GET_ADDRESSES_STARTING = 'GET_ADDRESSES_STARTING';
export const GET_ADDRESSES_SUCCESS = 'GET_ADDRESSES_SUCCESS';
export const GET_ADDRESSES_ERROR = 'GET_ADDRESSES_ERROR';

export const ADD_NEW_ADDRESS_STARTING = 'ADD_NEW_ADDRESS_STARTING';
export const ADD_NEW_ADDRESS_SUCCESS = 'ADD_NEW_ADDRESS_SUCCESS';
export const ADD_NEW_ADDRESS_ERROR = 'ADD_NEW_ADDRESS_ERROR';

export const GET_POSTS_STARTING = 'GET_POSTS_STARTING';
export const GET_POSTS_SUCCESS = 'GET_POSTS_SUCCESS';
export const GET_POSTS_ERROR = 'GET_POSTS_ERROR';

export const GET_INACTIVE_POSTS_STARTING = 'GET_INACTIVE_POSTS_STARTING';
export const GET_INACTIVE_POSTS_SUCCESS = 'GET_INACTIVE_POSTS_SUCCESS';
export const GET_INACTIVE_POSTS_ERROR = 'GET_INACTIVE_POSTS_ERROR';

export const GET_CATEGORY_POSTS_STARTING = 'GET_CATEGORY_POSTS_STARTING';
export const GET_CATEGORY_POSTS_SUCCESS = 'GET_CATEGORY_POSTS_SUCCESS';
export const GET_CATEGORY_POSTS_ERROR = 'GET_CATEGORY_POSTS_ERROR';

export const GET_POST_BY_ID_STARTING = 'GET_POST_BY_ID_STARTING';
export const GET_POST_BY_ID_SUCCESS = 'GET_POST_BY_ID_SUCCESS';
export const GET_POST_BY_ID_ERROR = 'GET_POST_BY_ID_ERROR';

export const ADD_POST_STARTING = 'ADD_POST_STARTING';
export const ADD_POST_SUCCESS = 'ADD_POST_SUCCESS';
export const ADD_POST_ERROR = 'ADD_POST_ERROR';

export const UPDATE_POST_STARTING = 'UPDATE_POST_STARTING';
export const UPDATE_POST_SUCCESS = 'UPDATE_POST_SUCCESS';
export const UPDATE_POST_ERROR = 'UPDATE_POST_ERROR';

export const ACTIVATE_POST_STARTING = 'ACTIVATE_POST_STARTING';
export const ACTIVATE_POST_SUCCESS = 'ACTIVATE_POST_SUCCESS';
export const ACTIVATE_POST_ERROR = 'ACTIVATE_POST_ERROR';

export const DEACTIVATE_POST_STARTING = 'DEACTIVATE_POST_STARTING';
export const DEACTIVATE_POST_SUCCESS = 'DEACTIVATE_POST_SUCCESS';
export const DEACTIVATE_POST_ERROR = 'DEACTIVATE_POST_ERROR';

export const GET_UNIVERSITIES_STARTING = 'GET_UNIVERSITIES_STARTING';
export const GET_UNIVERSITIES_SUCCESS = 'GET_UNIVERSITIES_SUCCESS';
export const GET_UNIVERSITIES_ERROR = 'GET_UNIVERSITIES_ERROR';

export const GET_MATERIALS_STARTING = 'GET_MATERIALS_STARTING';
export const GET_MATERIALS_SUCCESS = 'GET_MATERIALS_SUCCESS';
export const GET_MATERIALS_ERROR = 'GET_MATERIALS_ERROR';

export const GET_DEFECTS_STARTING = 'GET_DEFECTS_STARTING';
export const GET_DEFECTS_SUCCESS = 'GET_DEFECTS_SUCCESS';
export const GET_DEFECTS_ERROR = 'GET_DEFECTS_ERROR';

export const GET_TENANTS_STARTING = 'GET_TENANTS_STARTING';
export const GET_TENANTS_SUCCESS = 'GET_TENANTS_SUCCESS';
export const GET_TENANTS_ERROR = 'GET_TENANTS_ERROR';

export const GET_REGIONS_STARTING = 'GET_REGIONS_STARTING';
export const GET_REGIONS_SUCCESS = 'GET_REGIONS_SUCCESS';
export const GET_REGIONS_ERROR = 'GET_REGIONS_ERROR';

export const ADD_REGIONS_STARTING = 'ADD_REGIONS_STARTING';
export const ADD_REGIONS_SUCCESS = 'ADD_REGIONS_SUCCESS';
export const ADD_REGIONS_ERROR = 'ADD_REGIONS_ERROR';

export const GET_TOWERS_SITENAME_STARTING = 'GET_TOWERS_SITENAME_STARTING';
export const GET_TOWERS_SITENAME_SUCCESS = 'GET_TOWERS_SITENAME_SUCCESS';
export const GET_TOWERS_SITENAME_ERROR = 'GET_TOWERS_SITENAME_ERROR';

export const ADD_TOWER_SITENAME_STARTING = 'ADD_TOWER_SITENAME_STARTING';
export const ADD_TOWER_SITENAME_SUCCESS = 'ADD_TOWER_SITENAME_SUCCESS';
export const ADD_TOWER_SITENAME_ERROR = 'ADD_TOWER_SITENAME_ERROR';

//places
export const GET_PLACES_STARTING = 'GET_PLACES_STARTING';
export const GET_PLACES_SUCCESS = 'GET_PLACES_SUCCESS';
export const GET_PLACES_ERROR = 'GET_PLACES_ERROR';

export const GET_INACTIVE_PLACES_STARTING = 'GET_INACTIVE_PLACES_STARTING';
export const GET_INACTIVE_PLACES_SUCCESS = 'GET_INACTIVE_PLACES_SUCCESS';
export const GET_INACTIVE_PLACES_ERROR = 'GET_INACTIVE_PLACES_ERROR';

export const GET_CATEGORY_PLACES_STARTING = 'GET_CATEGORY_PLACES_STARTING';
export const GET_CATEGORY_PLACES_SUCCESS = 'GET_CATEGORY_PLACES_SUCCESS';
export const GET_CATEGORY_PLACES_ERROR = 'GET_CATEGORY_PLACES_ERROR';

export const GET_PLACE_BY_ID_STARTING = 'GET_PLACE_BY_ID_STARTING';
export const GET_PLACE_BY_ID_SUCCESS = 'GET_PLACE_BY_ID_SUCCESS';
export const GET_PLACE_BY_ID_ERROR = 'GET_PLACE_BY_ID_ERROR';

export const ADD_PLACE_STARTING = 'ADD_PLACE_STARTING';
export const ADD_PLACE_SUCCESS = 'ADD_PLACE_SUCCESS';
export const ADD_PLACE_ERROR = 'ADD_PLACE_ERROR';

export const UPDATE_PLACE_STARTING = 'UPDATE_PLACE_STARTING';
export const UPDATE_PLACE_SUCCESS = 'UPDATE_PLACE_SUCCESS';
export const UPDATE_PLACE_ERROR = 'UPDATE_PLACE_ERROR';

export const ACTIVATE_PLACE_STARTING = 'ACTIVATE_PLACE_STARTING';
export const ACTIVATE_PLACE_SUCCESS = 'ACTIVATE_PLACE_SUCCESS';
export const ACTIVATE_PLACE_ERROR = 'ACTIVATE_PLACE_ERROR';

export const DEACTIVATE_PLACE_STARTING = 'DEACTIVATE_PLACE_STARTING';
export const DEACTIVATE_PLACE_SUCCESS = 'DEACTIVATE_PLACE_SUCCESS';
export const DEACTIVATE_PLACE_ERROR = 'DEACTIVATE_PLACE_ERROR';

export const GET_RECOMMENDATIONS_STARTING = 'GET_RECOMMENDATIONS_STARTING';
export const GET_RECOMMENDATIONS_SUCCESS = 'GET_RECOMMENDATIONS_SUCCESS';
export const GET_RECOMMENDATIONS_ERROR = 'GET_RECOMMENDATIONS_ERROR';

export const ADD_RECOMMENDATION_STARTING = 'ADD_RECOMMENDATION_STARTING';
export const ADD_RECOMMENDATION_SUCCESS = 'ADD_RECOMMENDATION_SUCCESS';
export const ADD_RECOMMENDATION_ERROR = 'ADD_RECOMMENDATION_ERROR';

export const UPDATE_RECOMMENDATION_STARTING = 'UPDATE_RECOMMENDATION_STARTING';
export const UPDATE_RECOMMENDATION_SUCCESS = 'UPDATE_RECOMMENDATION_SUCCESS';
export const UPDATE_RECOMMENDATION_ERROR = 'UPDATE_RECOMMENDATION_ERROR';

export const ADD_MOBILE_TOWER_IMAGE_STARTING = 'ADD_MOBILE_TOWER_IMAGE_STARTING';
export const ADD_MOBILE_TOWER_IMAGE_SUCCESS = 'ADD_MOBILE_TOWER_IMAGE_SUCCESS';
export const ADD_MOBILE_TOWER_IMAGE_ERROR = 'ADD_MOBILE_TOWER_IMAGE_ERROR';

export const DELETE_MOBILE_TOWER_IMAGE_STARTING = 'DELETE_MOBILE_TOWER_IMAGE_STARTING';
export const DELETE_MOBILE_TOWER_IMAGE_SUCCESS = 'DELETE_MOBILE_TOWER_IMAGE_SUCCESS';
export const DELETE_MOBILE_TOWER_IMAGE_ERROR = 'DELETE_MOBILE_TOWER_IMAGE_ERROR';

export const GET_INACTIVE_RECOMMENDATIONS_STARTING = 'GET_INACTIVE_RECOMMENDATIONS_STARTING';
export const GET_INACTIVE_RECOMMENDATIONS_SUCCESS = 'GET_INACTIVE_RECOMMENDATIONS_SUCCESS';
export const GET_INACTIVE_RECOMMENDATIONS_ERROR = 'GET_INACTIVE_RECOMMENDATIONS_ERROR';

export const ACTIVATE_RECOMMENDATION_STARTING = 'ACTIVATE_RECOMMENDATION_STARTING';
export const ACTIVATE_RECOMMENDATION_SUCCESS = 'ACTIVATE_RECOMMENDATION_SUCCESS';
export const ACTIVATE_RECOMMENDATION_ERROR = 'ACTIVATE_RECOMMENDATION_ERROR';

export const DEACTIVATE_RECOMMENDATION_STARTING = 'DEACTIVATE_RECOMMENDATION_STARTING';
export const DEACTIVATE_RECOMMENDATION_SUCCESS = 'DEACTIVATE_RECOMMENDATION_SUCCESS';
export const DEACTIVATE_RECOMMENDATION_ERROR = 'DEACTIVATE_RECOMMENDATION_ERROR';


export const ADD_IMAGE_LABEL_STARTING = 'ADD_IMAGE_LABEL_STARTING';
export const ADD_IMAGE_LABEL_SUCCESS = 'ADD_IMAGE_LABEL_SUCCESS';
export const ADD_IMAGE_LABEL_ERROR = 'ADD_IMAGE_LABEL_ERROR';

export const ADD_TOWER_CHECKLIST_STARTING = 'ADD_TOWER_CHECKLIST_STARTING';
export const ADD_TOWER_CHECKLIST_SUCCESS = 'ADD_TOWER_CHECKLIST_SUCCESS';
export const ADD_TOWER_CHECKLIST_ERROR = 'ADD_TOWER_CHECKLIST_ERROR';
export const GET_MEMBERS_STARTING = 'GET_MEMBERS_STARTING';
export const GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS';
export const GET_MEMBERS_ERROR = 'GET_MEMBERS_ERROR';

export const ADD_MEMBER_STARTING = 'ADD_MEMBER_STARTING';
export const ADD_MEMBER_SUCCESS = 'ADD_MEMBER_SUCCESS';
export const ADD_MEMBER_ERROR = 'ADD_MEMBER_ERROR';

export const UPDATE_MEMBER_STARTING = 'UPDATE_MEMBER_STARTING';
export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS';
export const UPDATE_MEMBER_ERROR = 'UPDATE_MEMBER_ERROR';

export const ACTIVATE_MEMBER_STARTING = 'ACTIVATE_MEMBER_STARTING';
export const ACTIVATE_MEMBER_SUCCESS = 'ACTIVATE_MEMBER_SUCCESS';
export const ACTIVATE_MEMBER_ERROR = 'ACTIVATE_MEMBER_ERROR';

export const DEACTIVATE_MEMBER_STARTING = 'DEACTIVATE_MEMBER_STARTING';
export const DEACTIVATE_MEMBER_SUCCESS = 'DEACTIVATE_MEMBER_SUCCESS';
export const DEACTIVATE_MEMBER_ERROR = 'DEACTIVATE_MEMBER_ERROR';
