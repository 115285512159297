import { cloneDeep } from 'lodash';
import {
    GET_RECOMMENDATIONS_STARTING,
    GET_RECOMMENDATIONS_SUCCESS,
    GET_RECOMMENDATIONS_ERROR,
    ADD_RECOMMENDATION_SUCCESS,
    UPDATE_RECOMMENDATION_SUCCESS,
} from '../../../constants/actionTypes';
import initialState from '../../../store/initialState';

export default function recommendationsReducer(state = initialState.recommendations, action) {
    switch (action.type) {
        case GET_RECOMMENDATIONS_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_RECOMMENDATIONS_SUCCESS: {
            const newState = cloneDeep(state);
            const ids = [];
            const map = {};
            console.log("ac",action.data)
            action.data.recommendation.forEach((recommendation) => {
                console.log("recom",recommendation)
                const recommendationId = recommendation.id;
                ids.push(recommendationId);
                map[recommendationId] = recommendation;
            });

            newState.ids = ids;
            newState.map = map;


            newState.loading = false;
            newState.loaded = true;

            return newState;
        }

        case GET_RECOMMENDATIONS_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to  retrieve recommendations.';
            return newState;
        }

        case ADD_RECOMMENDATION_SUCCESS: {
            const newState = cloneDeep(state);
            const { recommendation } = action.data;
            const recommendationId = recommendation.id;


            newState.map[recommendationId] = recommendation;
            newState.ids.push(recommendationId);

            newState.ids.sort((rec1Id, rec2Id) => {
                const rec1 = newState.map[rec1Id];
                const rec2 = newState.map[rec2Id];
                return rec1.id - rec2.id;
            });

            return newState;
        }

        case UPDATE_RECOMMENDATION_SUCCESS: {
            const newState = cloneDeep(state);
            const { recommendation } = action.data;
            const recommendationId = recommendation.id;
            newState.map[recommendationId] = recommendation;
            return newState;
        }

        default: {
            return state;
        }
    }
}