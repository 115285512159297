import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  GET_NETWORKS_ERROR,
  GET_NETWORKS_STARTING,
  GET_NETWORKS_SUCCESS,

  ADD_NETWORK_SUCCESS,
  UPDATE_NETWORK_SUCCESS,
  GET_INACTIVE_NETWORKS_SUCCESS,
  DEACTIVATE_NETWORK_SUCCESS,
  ACTIVATE_NETWORK_SUCCESS,
} from '../../constants/actionTypes';

const sortByOrder = (newState) => {
  newState.ids.sort((net1Id, net2Id) => {
    const net1 = newState.map[net1Id];
    const net2 = newState.map[net2Id];
    return net1.networkName - net2.networkName;
  });

  newState.inactiveIds.sort((net1Id, net2Id) => {
    const net1 = newState.map[net1Id];
    const net2 = newState.map[net2Id];
    return net1.networkName - net2.networkName;
  });
}

export default function networksReducer(state = initialState.networks, action) {
  switch (action.type) {
    case GET_NETWORKS_STARTING: {
      const newState = cloneDeep(state);
      newState.loading = true;
      newState.loaded = false;
      return newState;
    }

    case GET_NETWORKS_SUCCESS: {
      const newState = cloneDeep(state);
      const ids = [];

      action.data.networks.forEach((network) => {
        const networkId = network.id;
        ids.push(networkId);
        newState.map[networkId] = network;
      });

      newState.ids = ids;
      //newState.page = action.data.page;
      //newState.pages = action.data.pages;
      //newState.limit = action.data.limit;
      //newState.total = action.data.total;
      //newState.isLastPage = newState.page >= newState.pages;
      newState.loading = false;
      newState.loaded = true;

      sortByOrder(newState);

      return newState;
    }

  case GET_INACTIVE_NETWORKS_SUCCESS: {
    const newState = cloneDeep(state);
    const inactiveIds = [];

    action.data.networks.forEach((network) => {
      const networkId = network.id;
      inactiveIds.push(networkId);
      newState.map[networkId] = network;
    });

    newState.inactiveIds = inactiveIds;

    sortByOrder(newState);
    return newState;
  }

    case GET_NETWORKS_ERROR: {
      const newState = cloneDeep(state);
      newState.loading = false;
      newState.loaded = false;
      newState.error = action.err ? action.err : 'Failed to retrieve networks.';
      return newState;
    }

    case ADD_NETWORK_SUCCESS: {
      const newState = cloneDeep(state);
      const { network } = action.data;
      const networkId = network.id;

      // Push new location data
      newState.map[networkId] = network;
      newState.ids.push(networkId);

      // Sort by display order
      sortByOrder(newState);
      return newState;
    }

    case UPDATE_NETWORK_SUCCESS: {
      const newState = cloneDeep(state);
      const { network } = action.data;
      const networkId = network.id;
      newState.map[networkId] = network;

      sortByOrder(newState);
      return newState;
    }

  case ACTIVATE_NETWORK_SUCCESS:{
    const newState = cloneDeep(state);
    const { networkId } = action;
    newState.ids.push(networkId);

    newState.inactiveIds = newState.inactiveIds.filter((nId) => {
      return networkId !== nId;
    });

    if (newState.map[networkId]) {
      newState.map[networkId].isActive =1;
    }

    sortByOrder(newState);
    return newState;
  }

  case DEACTIVATE_NETWORK_SUCCESS: {
    const newState = cloneDeep(state);
    const {networkId} = action;
    newState.inactiveIds.push(networkId);

    newState.ids = newState.ids.filter((nId) => {
      return networkId !== nId;
    });

    if (newState.map[networkId]) {
      newState.map[networkId].isActive =0;
    }

    sortByOrder(newState);
    return newState;
  }

    default: {
      return state;
    }
  }
}
