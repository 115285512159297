import { cloneDeep } from 'lodash';
import {
    AI_CHAT_ERROR, AI_CHAT_ON_RESPONSE,
    AI_CHAT_STARTING, AI_CHAT_SUCCESS
} from '../constants/actionTypes';
import initialState from '../store/initialState';

export default function chatReducer(state = initialState.chat, action) {
    switch (action.type) {
        case AI_CHAT_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            newState.messages = [];
            newState.error = null;
            newState.messageStr = '';
            return newState;
        }

        case AI_CHAT_SUCCESS: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = true;
            newState.error = null;
            return newState;
        }

        case AI_CHAT_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to start chat';
            return newState;
        }

        case AI_CHAT_ON_RESPONSE: {
            if (!action.data) {
                return state;
            }

            const newState = cloneDeep(state);
            let response;

            try {
                const { message } = JSON.parse(action.data);
                if (message.role === 'assistant') {
                    response = message.content;
                }

                // newState.messages.push(response);
                newState.messageStr = newState.messageStr + response;
                // newState.messageStr = newState.messages.join('\n');

                console.log(action.data);
                console.log('----- On new message from ai ----');
            } catch (err) {
                console.log('------ This data gave error ------');
                console.log(action.data);
                console.log(err);
            }

            return newState;
        }

        default: {
            return state;
        }
    }
}
