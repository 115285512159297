import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  UPDATE_AHU_DUST_COLLECTOR_FILTER_REQUEST_STARTING,
  UPDATE_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS,
  UPDATE_AHU_DUST_COLLECTOR_FILTER_REQUEST_ERROR,

  SET_FILTER_DETAILS_AHU_DUST_COLLECTOR_FILTER_REQUEST_STARTING,
  SET_FILTER_DETAILS_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS,
  SET_FILTER_DETAILS_AHU_DUST_COLLECTOR_FILTER_REQUEST_ERROR,

  RETURN_AHU_DUST_COLLECTOR_FILTER_REQUEST_STARTING,
  RETURN_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS,
  RETURN_AHU_DUST_COLLECTOR_FILTER_REQUEST_ERROR,
} from '../../constants/actionTypes';

export default function updateAhuDustCollectorFilterRequestReducer(state = initialState.updateAhuDustCollectorFilterRequest, action) {
  switch (action.type) {
    case UPDATE_AHU_DUST_COLLECTOR_FILTER_REQUEST_STARTING:
    case SET_FILTER_DETAILS_AHU_DUST_COLLECTOR_FILTER_REQUEST_STARTING:
    case RETURN_AHU_DUST_COLLECTOR_FILTER_REQUEST_STARTING: {
      const {requestId} = action;
      const newState = clone(state);

      if (!newState[requestId]) {
        newState[requestId] = clone(initialState.updateAhuDustCollectorFilterRequest.requestId);
      }

      const requestData = newState[requestId];
      requestData.saving = true;
      requestData.saved = false;
      requestData.error = null;

      return newState;
    }

    case UPDATE_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS:
    case SET_FILTER_DETAILS_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS:
    case RETURN_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS: {
      const {requestId} = action;
      const newState = clone(state);

      if (!newState[requestId]) {
        newState[requestId] = clone(initialState.updateAhuDustCollectorFilterRequest.requestId);
      }

      const requestData = newState[requestId];
      requestData.saving = false;
      requestData.saved = true;
      requestData.error = null;
      return newState;
    }

    case UPDATE_AHU_DUST_COLLECTOR_FILTER_REQUEST_ERROR:
    case SET_FILTER_DETAILS_AHU_DUST_COLLECTOR_FILTER_REQUEST_ERROR:
    case RETURN_AHU_DUST_COLLECTOR_FILTER_REQUEST_ERROR: {
      const {requestId} = action;
      const newState = clone(state);

      if (!newState[requestId]) {
        newState[requestId] = clone(initialState.updateAhuDustCollectorFilterRequest.requestId);
      }

      const requestData = newState[requestId];
      requestData.saving = false;
      requestData.saved = false;
      requestData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
