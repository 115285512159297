import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    ADD_POST_STARTING,
    ADD_POST_SUCCESS,
    ADD_POST_ERROR,
} from '../../constants/actionTypes';

export default function addPostReducer(state = initialState.addPost, action) {
    switch (action.type) {
        case ADD_POST_STARTING: {
            const newState = clone(state);
            newState.saving = true;
            newState.saved = false;
            return newState;
        }

        case ADD_POST_SUCCESS: {
            const { data } = action;

            const newState = clone(state);
            newState.saving = false;
            newState.saved = true;
            newState.error = null;

            newState.newPost = data.post;
            return newState;
        }

        case ADD_POST_ERROR: {
            const newState = clone(state);
            newState.saving = false;
            newState.saved = false;
            newState.error = action.err;
            return newState;
        }

        default: {
            return state;
        }
    }
}
