import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  GET_DUCT_CLEANING_REQUESTS_ERROR,
  GET_DUCT_CLEANING_REQUESTS_STARTING,
  GET_DUCT_CLEANING_REQUESTS_SUCCESS,

  ADD_DUCT_CLEANING_REQUEST_SUCCESS,
  ACKNOWLEDGE_DUCT_CLEANING_REQUEST_SUCCESS,
  CLOSE_DUCT_CLEANING_REQUEST_SUCCESS,
  MARK_AS_DONE_DUCT_CLEANING_REQUEST_SUCCESS,
} from '../../constants/actionTypes';

export default function ductCleaningRequestsReducer(state = initialState.ductCleaningRequests, action) {
  switch (action.type) {
    case GET_DUCT_CLEANING_REQUESTS_STARTING: {
      const newState = cloneDeep(state);
      newState.loading = true;
      newState.loaded = false;
      newState.filterKey = action.filterKey;
      return newState;
    }

    case GET_DUCT_CLEANING_REQUESTS_SUCCESS: {
      if (state.filterKey !== action.filterKey) {
        return state;
      }

      const newState = cloneDeep(state);
      const ids = [];
      const map = {};

      action.data.ductCleaningRequests.forEach((ductCleaningRequest) => {
        const ductCleaningRequestId = ductCleaningRequest.id;
        ids.push(ductCleaningRequestId);
        map[ductCleaningRequestId] = ductCleaningRequest;
      });

      newState.ids = ids;
      newState.map = map;
      //newState.page = action.data.page;
      //newState.pages = action.data.pages;
      //newState.limit = action.data.limit;
      //newState.total = action.data.total;
      //newState.isLastPage = newState.page >= newState.pages;
      newState.loading = false;
      newState.loaded = true;

      return newState;
    }

    case GET_DUCT_CLEANING_REQUESTS_ERROR: {
      if (state.filterKey !== action.filterKey) {
        return state;
      }

      const newState = cloneDeep(state);
      newState.loading = false;
      newState.loaded = false;
      newState.error = action.err ? action.err : 'Failed to retrieve ahu filter cleaning requests.';
      return newState;
    }

    case ADD_DUCT_CLEANING_REQUEST_SUCCESS: {
      const newState = cloneDeep(state);
      const { ductCleaningRequest } = action.data;
      const ductCleaningRequestId = ductCleaningRequest.id;

      // Push new data
      newState.map[ductCleaningRequestId] = ductCleaningRequest;
      newState.ids = [ductCleaningRequestId].concat(newState.ids);
      // newState.ids.push(ductCleaningRequestId);

      return newState;
    }

    case ACKNOWLEDGE_DUCT_CLEANING_REQUEST_SUCCESS:
    case MARK_AS_DONE_DUCT_CLEANING_REQUEST_SUCCESS: {
      const newState = cloneDeep(state);
      const { ductCleaningRequest } = action.data;
      const ductCleaningRequestId = ductCleaningRequest.id;

      // Push new data
      newState.map[ductCleaningRequestId] = ductCleaningRequest;
      return newState;
    }

    case CLOSE_DUCT_CLEANING_REQUEST_SUCCESS: {
      const newState = cloneDeep(state);
      const { ductCleaningRequest } = action.data;
      const ductCleaningRequestId = ductCleaningRequest.id;

      newState.ids = newState.ids.filter((id) => id !== ductCleaningRequestId);
      return newState;
    }

    default: {
      return state;
    }
  }
}
