import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    GET_LOCATION_AREAS_ERROR,
    GET_LOCATION_AREAS_STARTING,
    GET_LOCATION_AREAS_SUCCESS,

    ADD_AREA_SUCCESS,
    UPDATE_AREA_SUCCESS,
} from '../../constants/actionTypes';

 export default function areasReducer(state = initialState.areas, action) {
    switch (action.type) {
        case GET_LOCATION_AREAS_STARTING: {
            const { locationId } = action;
            const newState = cloneDeep(state);

            if (!newState[locationId]) {
                newState[locationId] = cloneDeep(initialState.areas.locationId);
            }

            const areasData = newState[locationId];
            areasData.ids = [];
            areasData.map = {};
            areasData.loading = true;
            areasData.loaded = false;
            areasData.error = null;

            return newState;
        }

    case GET_LOCATION_AREAS_SUCCESS: {
        const { data, locationId } = action;
        const newState = cloneDeep(state);

        if (!newState[locationId]) {
            newState[locationId] = cloneDeep(initialState.areas.locationId);
        }

        const areasData = newState[locationId];
        const ids = [];
        const map = {};

        data.areas.forEach((area) => {
            ids.push(area.id);
            map[area.id] = area;
        });

        areasData.ids = ids;
        areasData.map = map;
        areasData.loading = false;
        areasData.loaded = true;

        //areasData.page = action.data.page;
        //areasData.pages = action.data.pages;
        //areasData.limit = action.data.limit;
        //areasData.total = action.data.total;
        //areasData.isLastPage = areasData.page >= areasData.pages;

        return newState;
    }

    case GET_LOCATION_AREAS_ERROR: {
        const { locationId } = action;
        const newState = cloneDeep(state);

        if (!newState[locationId]) {
            newState[locationId] = cloneDeep(initialState.areas.locationId);
        }

        const areasData = newState[locationId];
        areasData.loading = false;
        areasData.loaded = false;
        areasData.error = action.err;
        return newState;
    }

    case ADD_AREA_SUCCESS: {
        const newState = cloneDeep(state);
        const { area } = action.data;
        const { id: areaId, locationId } = area;

        if (!newState[locationId]) {
            newState[locationId] = cloneDeep(initialState.areas.locationId);
        }

        const areasData = newState[locationId];
        areasData.ids.push(areaId);
        areasData.map[areaId] = area;

        // TODO Sort new ids
        return newState;
    }

    case UPDATE_AREA_SUCCESS: {
        const newState = cloneDeep(state);
        const { area } = action.data;
        const { id: areaId, locationId } = area;

        if (!newState[locationId]) {
            newState[locationId] = cloneDeep(initialState.areas.locationId);
        }

        const areasData = newState[locationId];
        areasData.map[areaId] = area;
        return newState;
    }

    default: {
        return state;
    }
    }
}