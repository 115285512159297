import { combineReducers } from 'redux';
import activateCategory from './categories/activateCategoryReducer';
import activateNetwork from './networks/activateNetworkReducer';
import activateLocation from './locations/activateLocationReducer';
import activateRoom from './rooms/activateRoomReducer';
import activateArea from './areas/activateAreaReducer';
import activateDevice from './devices/activateDeviceReducer';
import activateParameter from './parameters/activateParameterReducer';
import activateRole from './roles/activateRoleReducer';
import activateAckMessage from './ackMessages/activateAckMessageReducer';
import activateMobileTower from './mobileTowers/activateMobileTowerReducer';
import addCategory from './categories/addCategoryReducer';
import addDevice from './devices/addDeviceReducer';
import addAHUDevice from './devices/addAHUDeviceReducer';
import addLocation from './locations/addLocationReducer';
import addNetwork from './networks/addNetworkReducer';
import addParameter from './parameters/addParameterReducer';
import addRole from './roles/addRoleReducer';
import addRoom from './rooms/addRoomReducer';
import addArea from './areas/addAreaReducer';
import addUser from './users/addUserReducer';
import addAckMessage from './ackMessages/addAckMessageReducer';
import addMobileTower from './mobileTowers/addMobileTowerReducer';
import addTenant from './mobileTowers/tenants/addTenantReducer';
import attendanceAreas from './attendanceRecords/attendanceAreasReducer';
import areaRooms from "./areas/areaRoomsReducer";
import attendanceRecords from './attendanceRecords/attendanceRecordsReducer';
import auditLogs from './auditLogs/auditLogsReducer';
import categories from './categories/categoriesReducer';
import chat from "./chatReducer";
import clientInfo from './clientInfoReducer';
import currentUser from './currentUserReducer';
import deactivateCategory from './categories/deactivateCategoryReducer';
import deactivateNetwork from './networks/deactivateNetworkReducer';
import deactivateLocation from './locations/deactivateLocationReducer';
import deactivateRoom from './rooms/deactivateRoomReducer';
import deactivateArea from './areas/deactivateAreaReducer';
import deactivateDevice from './devices/deactivateDeviceReducer';
import deactivateParameter from './parameters/deactivateParameterReducer';
import deactivateRole from './roles/deactivateRoleReducer';
import deactivateAckMessage from './ackMessages/deactivateAckMessageReducer';
import deactivateMobileTower from './mobileTowers/deactivateMobileTowerReducer';
import dictionaryWordsInPrefix from './dictionaryWordsInPrefixReducer';
import employees from './attendanceRecords/employeesReducer';
import files from './filesReducer';
import fileUploadStatus from "./fileUploadStatusReducer";
import fullScreenStatus from './fullScreenStatusReducer';
import locations from './locations/locationsReducer';
import locationAreas from './locations/locationAreasReducer';
import locationDevices from './locationDevicesReducer';
import modules from "./roles/modulesReducer";
import networks from './networks/networksReducer';
import parameterData from './parameterDataReducer';
import parameters from './parameters/parametersReducer';
import alarmNotifications from './notifications/alarmNotificationsRducer';
import referenceDevices from "./devices/referenceDevicesReducer";
import regions from './mobileTowers/regionsReducer';
import reportTemplates from "./reportTemplates/reportTemplatesReducer";
import requestNotifications from './notifications/requestNotificationsReducer';
import roles from './roles/rolesReducer';
import rooms from './rooms/roomsReducer';
import areas from './areas/areasReducer';
import roomWiseDevices from './rooms/roomWiseDevicesReducer';
import socket from './socketReducer';
import ackMessages from './ackMessages/ackMessageReducer';
import terminals from './attendanceRecords/terminalsReducer';
import testBenchStatus from './testBenchStatusReducer';
import toastNotification from './notifications/toastNotificationReducer';
import updateCategory from './categories/updateCategoryReducer';
import updateClientInfo from './updateClientInfoReducer';
import updateDevice from './devices/updateDeviceReducer';
import updateLocation from './locations/updateLocationReducer';
import updateNetwork from './networks/updateNetworkReducer';
import updateParameter from './parameters/updateParameterReducer';
import updateParamLimits from './parameters/updateParamLimitsReducer';
import updateRole from './roles/updateRoleReducer';
import updateRolePermissions from './roles/updateRolePermissionsReducer';
import updateRoom from './rooms/updateRoomReducer';
import updateAckMessage from './ackMessages/updateAckMessageReducer';
import updateMobileTower from './mobileTowers/updateMobileTowerReducer';
import updateArea from './areas/updateAreaReducer';
import updateUser from './users/updateUserReducer';
import updateTenant from './mobileTowers/tenants/updateTenantReducer';
import userData from './users/userDataReducer';
import users from './users/usersReducer';

import ahuFilterCleaningRequests from './ahuFilterCleaningRequests/ahuFilterCleaningRequestsReducer';
import addAhuFilterCleaningRequest from './ahuFilterCleaningRequests/addAhuFilterCleaningRequestReducer';
import acknowledgeAhuFilterCleaningRequest from './ahuFilterCleaningRequests/acknowledgeAhuFilterCleaningRequestReducer';
import closeAhuFilterCleaningRequest from './ahuFilterCleaningRequests/closeAhuFilterCleaningRequestReducer';
import setAhuFilterCleaningRequestOperationStatus from './ahuFilterCleaningRequests/setAhuFilterCleaningRequestOperationStatusReducer';

import ahuOnOffRequests from './ahuOnOffRequests/ahuOnOffRequestsReducer';
import addAhuOnOffRequest from './ahuOnOffRequests/addAhuOnOffRequestReducer';
import acknowledgeAhuOnOffRequest from './ahuOnOffRequests/acknowledgeAhuOnOffRequestReducer';
import closeAhuOnOffRequest from './ahuOnOffRequests/closeAhuOnOffRequestReducer';
import setAhuOnOffRequestOperationStatus from './ahuOnOffRequests/setAhuOnOffRequestOperationStatusReducer';

import ahuDustCollectorFilterRequests from './ahuDustCollectorFilterRequests/ahuDustCollectorFilterRequestsReducer';
import addAhuDustCollectorFilterRequest from './ahuDustCollectorFilterRequests/addAhuDustCollectorFilterRequestReducer';
import acknowledgeAhuDustCollectorFilterRequest from './ahuDustCollectorFilterRequests/acknowledgeAhuDustCollectorFilterRequestReducer';
import closeAhuDustCollectorFilterRequest from './ahuDustCollectorFilterRequests/closeAhuDustCollectorFilterRequestReducer';
import setAhuDustCollectorFilterRequestOperationStatus from './ahuDustCollectorFilterRequests/setAhuDustCollectorFilterRequestOperationStatusReducer';
import updateAhuDustCollectorFilterRequest from './ahuDustCollectorFilterRequests/updateAhuDustCollectorFilterRequestReducer';

import ductCleaningRequests from './ductCleaningRequests/ductCleaningRequestsReducer';
import addDuctCleaningRequest from './ductCleaningRequests/addDuctCleaningRequestReducer';
import acknowledgeDuctCleaningRequest from './ductCleaningRequests/acknowledgeDuctCleaningRequestReducer';
import closeDuctCleaningRequest from './ductCleaningRequests/closeDuctCleaningRequestReducer';
import setDuctCleaningRequestOperationStatus from './ductCleaningRequests/setDuctCleaningRequestOperationStatusReducer';

import mobileTowers from "./mobileTowers/mobileTowersReducer";
import searchResults from './searchResults/searchResultsReducer';
import searchSuggestions from './searchResults/suggestReducer';
import saveSearchResult from "./searchResults/saveSearchResultReducer";

import licensingApplications from './licenses/applicationsReducer';
import licenses from './licenses/licensesReducer';
import addLicense from './licenses/addLicenseReducer';
import updateLicense from './licenses/updateLicenseReducer';
import jobs from './jobs/jobsReducer';
import createJobApplication from './jobs/createJobApplicationReducer';
import alarms from './alarms/alarmsReducer';
import hydraulicTestBench from './hydraulicTestBench/hydraulicTestBenchReducer';
import gearBoxTest from './gearBoxTest/gearBoxTestReducer';
import signup from './signupUserReducer';
import forgotPassword from './forgotPasswordReducer';
import resetPassword from './resetPasswordReducer';
import properties from './propertiesReducer';
import enquiryRequest from './enquiryReducer';
import products from './commerce/productsReducer';
import setCartQuantity from './commerce/setCartQuantityReducer';
import cart from './commerce/cartReducer';
import addAddress from './commerce/addAddressReducer';
import addresses from './commerce/addressesReducer';
import posts from './news/postsReducer';
import categoryPosts from './news/categoryPostsReducer';
import addPost from './news/addPostReducer';
import updatePost from './news/updatePostReducer';
import activatePost from './news/activatePostReducer';
import deactivatePost from './news/deactivatePostReducer';

import universities from './universitiesReducer';
import materials from './materialsReducer';
import tenants from './mobileTowers/tenants/tenantsReducer';

import places from './places/placesReducer';
import addPlace from './places/addPlaceReducer';
import updatePlace from './places/updatePlaceReducer';
import deactivatePlace from './places/deactivatePlaceReducer';
import activatePlace from './places/activatePlaceReducer';

import defects from './mobileTowers/defects/defectsReducer';

import recommendations from './mobileTowers/recommendations/recommendationsReducer';
import addRecommendation from './mobileTowers/recommendations/addRecommendationReducer';
import updateRecommendation from './mobileTowers/recommendations/updateRecommendation';
import activateRecommendation from './mobileTowers/recommendations/activateRecommendationReducer';
import deactivateRecommendation from './mobileTowers/recommendations/deactivateRecommendationReducer';
import addImageLabel from './mobileTowers/images/addImageLabelReducer';
import members from './mobileTowers/members/membersReducer'
import addMember from './mobileTowers/members/addMemberReducer';
import updateMember from './mobileTowers/members/addMemberReducer';
import addTowerChecklist from './mobileTowers/images/addTowerChecklistReducer';

const rootReducer = history => combineReducers({
  activateCategory,
  activateNetwork,
  activateLocation,
  activateRoom,
  activateArea,
  activateDevice,
  activateParameter,
  activateRole,
  activateAckMessage,
  activateMobileTower,

  ahuFilterCleaningRequests,
  addAhuFilterCleaningRequest,
  acknowledgeAhuFilterCleaningRequest,
  closeAhuFilterCleaningRequest,
  setAhuFilterCleaningRequestOperationStatus,

  ahuOnOffRequests,
  addAhuOnOffRequest,
  acknowledgeAhuOnOffRequest,
  closeAhuOnOffRequest,
  setAhuOnOffRequestOperationStatus,

  ahuDustCollectorFilterRequests,
  addAhuDustCollectorFilterRequest,
  acknowledgeAhuDustCollectorFilterRequest,
  closeAhuDustCollectorFilterRequest,
  setAhuDustCollectorFilterRequestOperationStatus,
  updateAhuDustCollectorFilterRequest,

  ductCleaningRequests,
  addDuctCleaningRequest,
  acknowledgeDuctCleaningRequest,
  closeDuctCleaningRequest,
  setDuctCleaningRequestOperationStatus,

  attendanceRecords,
  employees,
  attendanceAreas,
  terminals,

  chat,

  addCategory,
  addDevice,
  addAHUDevice,
  addLocation,
  addNetwork,
  addParameter,
  addRole,
  addRoom,
  addArea,
  addUser,
  addAckMessage,
  addMobileTower,
  addTenant,

  categories,
  clientInfo,
  currentUser,

  deactivateCategory,
  deactivateNetwork,
  deactivateLocation,
  deactivateRoom,
  deactivateArea,
  deactivateDevice,
  deactivateParameter,
  deactivateRole,
  deactivateAckMessage,
  deactivateMobileTower,

  files,
  fileUploadStatus,
  fullScreenStatus,

  locations,
  locationAreas,
  locationDevices,

  auditLogs,
  alarms,
  modules,
  networks,
  parameterData,
  parameters,
  alarmNotifications,
  referenceDevices,
  requestNotifications,
  roles,
  rooms,
  areas,
  ackMessages,
  roomWiseDevices,
  areaRooms,
  toastNotification,
  users,

  socket,

  updateCategory,
  updateClientInfo,
  updateDevice,
  updateLocation,
  updateNetwork,
  updateParameter,
  updateParamLimits,
  updateRole,
  updateRolePermissions,
  updateRoom,
  updateAckMessage,
  updateArea,
  updateUser,
  updateMobileTower,
  updateTenant,

  userData,

  jobs,
  createJobApplication,

  mobileTowers,
  regions,

  searchResults,
  searchSuggestions,
  saveSearchResult,
  dictionaryWordsInPrefix,

  testBenchStatus,

  licensingApplications,
  licenses,
  updateLicense,
  addLicense,

  hydraulicTestBench,
  gearBoxTest,

  reportTemplates,
  signup,
  forgotPassword,
  resetPassword,

  properties,
  products,

  enquiryRequest,
  setCartQuantity,
  cart,

  addAddress,
  addresses,

  posts,
  categoryPosts,
  addPost,
  updatePost,
  activatePost,
  deactivatePost,

  universities,
  materials,

  tenants,

  places,
  addPlace,
  updatePlace,
  activatePlace,
  deactivatePlace,

  defects,

  recommendations,
  addRecommendation,
  updateRecommendation,
  activateRecommendation,
  deactivateRecommendation,

  addImageLabel,
  addTowerChecklist,

  members,
  addMember,
  updateMember,


});

export default rootReducer;
