import { cloneDeep } from 'lodash';
import {
    GET_DEFECTS_STARTING,
    GET_DEFECTS_SUCCESS,
    GET_DEFECTS_ERROR,
} from '../../../constants/actionTypes';
import initialState from '../../../store/initialState';

export default function defectsReducer(state = initialState.defects, action) {
    switch (action.type) {
        case GET_DEFECTS_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_DEFECTS_SUCCESS: {
            const newState = cloneDeep(state);
            const ids = [];
            const map = {};

            action.data.defects.forEach((defect) => {
                const defectId = defect.id;
                ids.push(defectId);
                map[defectId] = defect;
            });

            newState.ids = ids;
            newState.map = map;

            newState.loading = false;
            newState.loaded = true;
            return newState;
        }

        case GET_DEFECTS_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to  retrieve defects.';
            return newState;
        }

        default: {
            return state;
        }
    }
}