import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    GET_CATEGORIES_ERROR,
    GET_CATEGORIES_STARTING,
    GET_CATEGORIES_SUCCESS,
    ADD_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_SUCCESS,
    GET_INACTIVE_CATEGORIES_SUCCESS,
    DEACTIVATE_CATEGORY_SUCCESS,
    ACTIVATE_CATEGORY_SUCCESS,
} from '../../constants/actionTypes';

const sortByOrder = (newState) => {
  newState.ids.sort((cat1Id, cat2Id) => {
    const cat1 = newState.map[cat1Id];
    const cat2 = newState.map[cat2Id];
    return cat1.dashboardOrder - cat2.dashboardOrder;
  });

  newState.inactiveIds.sort((cat1Id, cat2Id) => {
    const cat1 = newState.map[cat1Id];
    const cat2 = newState.map[cat2Id];
    return cat1.dashboardOrder - cat2.dashboardOrder;
  });
}

export default function categoriesReducer(state = initialState.categories, action) {
  switch (action.type) {
    case GET_CATEGORIES_STARTING: {
      const newState = cloneDeep(state);
      newState.loading = true;
      newState.loaded = false;
      return newState;
    }

    case GET_CATEGORIES_SUCCESS: {
      const newState = cloneDeep(state);
      const ids = [];

      action.data.categories.forEach((category) => {
        const categoryId = category.id;
        ids.push(categoryId);
        newState.map[categoryId] = category;
      });

      newState.ids = ids;

      //newState.page = action.data.page;
      //newState.pages = action.data.pages;
      //newState.limit = action.data.limit;
      //newState.total = action.data.total;
      //newState.isLastPage = newState.page >= newState.pages;
      newState.loading = false;
      newState.loaded = true;

      sortByOrder(newState);

      return newState;
    }

  case GET_INACTIVE_CATEGORIES_SUCCESS: {
    const newState = cloneDeep(state);
    const inactiveIds = [];

    action.data.categories.forEach((category) => {
      const categoryId = category.id;
      inactiveIds.push(categoryId);
      newState.map[categoryId] = category;
    });

    newState.inactiveIds = inactiveIds;

    sortByOrder(newState);
    return newState;
  }

    case GET_CATEGORIES_ERROR: {
      const newState = cloneDeep(state);
      newState.loading = false;
      newState.loaded = false;
      newState.error = action.err ? action.err : 'Failed to retrieve categories.';
      return newState;
    }

    case ADD_CATEGORY_SUCCESS: {
      const newState = cloneDeep(state);
      const { category } = action.data;
      const categoryId = category.id;

      // Push new location data
      newState.map[categoryId] = category;
      newState.ids.push(categoryId);

      // Sort by display order
      sortByOrder(newState);

      return newState;
    }

    case UPDATE_CATEGORY_SUCCESS: {
      const newState = cloneDeep(state);
      const { category } = action.data;
      const categoryId = category.id;
      newState.map[categoryId] = category;

      sortByOrder(newState);
      return newState;
    }

    case ACTIVATE_CATEGORY_SUCCESS: {
      const newState = cloneDeep(state);
      const { categoryId } = action;
      newState.ids.push(categoryId);

      newState.inactiveIds = newState.inactiveIds.filter((cId) => {
        return categoryId !== cId;
      });

      if (newState.map[categoryId]) {
        newState.map[categoryId].isActive = 1;
      }

      sortByOrder(newState);
      return newState;
    }

    case DEACTIVATE_CATEGORY_SUCCESS: {
      const newState = cloneDeep(state);
      const { categoryId } = action;
      newState.inactiveIds.push(categoryId);

      newState.ids = newState.ids.filter((cId) => {
        return categoryId !== cId;
      });

      if (newState.map[categoryId]) {
        newState.map[categoryId].isActive = 0;
      }

      sortByOrder(newState);
      return newState;
    }

    default: {
      return state;
    }
  }
}
