import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  ACTIVATE_MOBILE_TOWER_STARTING,
  ACTIVATE_MOBILE_TOWER_SUCCESS,
  ACTIVATE_MOBILE_TOWER_ERROR,
} from '../../constants/actionTypes';

export default function activateMobileTowerReducer(state = initialState.activateMobileTower, action) {
  switch (action.type) {
    case ACTIVATE_MOBILE_TOWER_STARTING: {
      const {mobileTowerId} = action;
      const newState = clone(state);

      if (!newState[mobileTowerId]) {
        newState[mobileTowerId] = clone(initialState.activateMobileTower.mobileTowerId);
      }

      const mobileTowerData = newState[mobileTowerId];
      mobileTowerData.saving = true;
      mobileTowerData.saved = false;
      mobileTowerData.error = null;

      return newState;
    }

    case ACTIVATE_MOBILE_TOWER_SUCCESS: {
      const {mobileTowerId} = action;
      const newState = clone(state);

      if (!newState[mobileTowerId]) {
        newState[mobileTowerId] = clone(initialState.activateMobileTower.mobileTowerId);
      }

      const mobileTowerData = newState[mobileTowerId];
      mobileTowerData.saving = false;
      mobileTowerData.saved = true;
      mobileTowerData.error = null;
      return newState;
    }

    case ACTIVATE_MOBILE_TOWER_ERROR: {
      const {mobileTowerId} = action;
      const newState = clone(state);

      if (!newState[mobileTowerId]) {
        newState[mobileTowerId] = clone(initialState.activateMobileTower.mobileTowerId);
      }

      const mobileTowerData = newState[mobileTowerId];
      mobileTowerData.saving = false;
      mobileTowerData.saved = false;
      mobileTowerData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
