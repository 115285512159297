import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    GET_REGIONS_ERROR,
    GET_REGIONS_STARTING,
    GET_REGIONS_SUCCESS,
} from '../../constants/actionTypes';

const sortByOrder = (newState) => {
    newState.ids.sort((place1Id, place2Id) => {
        const place1 = newState.map[place1Id];
        const place2 = newState.map[place2Id];
        return place1.score - place2.displayOrder;
    });

    newState.inactiveIds.sort((place1Id, place2Id) => {
        const place1 = newState.map[place1Id];
        const place2 = newState.map[place2Id];
        return place1.score - place2.score;
    });
}

export default function regionsReducer(state = initialState.regions, action) {
    switch (action.type) {
        case GET_REGIONS_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_REGIONS_SUCCESS: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = true;

            newState.ids = [];
            newState.map = action.data.places;
            newState.userAddress = action.data.userAddress;
            newState.hierarchy = action.data.mapping;
            newState.towersMap = action.data.placeTowersMap;

            // TODO Set showInternational flag
            // newState.showInternational = (newState.userAddress && )

            sortByOrder(newState);

            return newState;
        }

        case GET_REGIONS_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to retrieve regions.';
            return newState;
        }

        default: {
            return state;
        }
    }
}
