import { cloneDeep } from 'lodash';
import {
    ADD_NEW_ADDRESS_STARTING,
    ADD_NEW_ADDRESS_SUCCESS,
    ADD_NEW_ADDRESS_ERROR,
} from '../../constants/actionTypes';
import initialState from '../../store/initialState';

export default function addAddressReducer(state = initialState.addAddress, action) {
    switch (action.type) {
        case ADD_NEW_ADDRESS_STARTING: {
            const newState = cloneDeep(state);
            newState.saving = true;
            newState.saved = false;
            return newState;
        }

        case ADD_NEW_ADDRESS_SUCCESS: {
            const newState = cloneDeep(state);
            newState.saving = false;
            newState.saved = true;
            const { data } = action;
            newState.error = null;

            newState.newAddress = data.address
            return newState;
        }

        case ADD_NEW_ADDRESS_ERROR: {
            const newState = cloneDeep(state);
            newState.saving = false;
            newState.saved = false;
            newState.error = action.err;
            return newState;
        }

        default: {
            return state;
        }
    }
}