import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    UPDATE_POST_STARTING,
    UPDATE_POST_SUCCESS,
    UPDATE_POST_ERROR,
} from '../../constants/actionTypes';

export default function updatePostReducer(state = initialState.updatePost, action) {
    switch (action.type) {
        case UPDATE_POST_STARTING: {
            const { postId } = action;
            const newState = clone(state);

            if (!newState[postId]) {
                newState[postId] = clone(initialState.updatePost.postId);
            }

            const updateData = newState[postId];
            updateData.saving = true;
            updateData.saved = false;
            updateData.error = null;

            return newState;
        }

        case UPDATE_POST_SUCCESS: {
            const { postId } = action;
            const newState = clone(state);

            if (!newState[postId]) {
                newState[postId] = clone(initialState.updatePost.postId);
            }

            const updateData = newState[postId];
            updateData.saving = false;
            updateData.saved = true;
            updateData.error = null;
            return newState;
        }

        case UPDATE_POST_ERROR: {
            const { postId } = action;
            const newState = clone(state);

            if (!newState[postId]) {
                newState[postId] = clone(initialState.updatePost.postId);
            }

            const updateData = newState[postId];
            updateData.saving = false;
            updateData.saved = false;
            updateData.error = action.err;
            return newState;
        }

        default: {
            return state;
        }
    }
}
