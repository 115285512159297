import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as notificationsActions from '../../actions/notificationsActions';
import {toast} from "react-hot-toast";
import {getRequestNotificationIcon, getRequestNotificationTitle} from "../../utils/notifications";

class NotificationToaster extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showAlarms: false,
            notificationsTimer: 0,
        };
    }

    componentDidMount() {
        this.startGetAlarmsAjax();
        this.handleToastNotification(null);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.startGetAlarmsAjax();
        this.handleToastNotification(prevProps);
    }

    componentWillUnmount() {
        this.clearGetAlarmsAjax();
    }

    startGetAlarmsAjax() {
        const {currentUser, actions} = this.props;
        const {notificationsTimer} = this.state;

        if (!currentUser.loggedIn) {
            // this.clearGetAlarmsAjax();
            return;
        }

        if (notificationsTimer) {
            // Do nothing
            return;
        }

        // Get notifications now
        // actions.getRequestNotifications();
        // actions.getAlarmNotifications();

        const timer = setInterval(() => {
            // actions.getRequestNotifications();
            // actions.getAlarmNotifications();
        }, 10 * 1000);

        console.log('Setting notifications timer');
        this.setState({
            notificationsTimer: timer,
        });
    }

    clearGetAlarmsAjax() {
        const {notificationsTimer} = this.state;
        if (notificationsTimer) {
            console.log('Clearing notifications timer');
            clearInterval(notificationsTimer);
        }
    }

    handleToastNotification(prevProps) {
        const {toastNotification} = this.props;
        if (!toastNotification.id || !toastNotification.data) {
            return;
        }

        if (prevProps && prevProps.toastNotification.id === toastNotification.id) {
            return;
        }

        const notificationTitle = getRequestNotificationTitle(toastNotification.data);
        const notificationIcon = getRequestNotificationIcon(toastNotification.data);

        toast.dismiss();
        toast(
            (t) => (
                <div className="flex items-center justify-between flex-column w-full">
                    <div className="grow text-lg font-normal">
                        {notificationTitle}
                    </div>
                    <div className="flex-none text-end text-xs text-gray-500">
                        <button onClick={() => toast.dismiss(t.id)}>
                            ❌
                        </button>
                    </div>
                </div>
            ),
            {
                style: {
                    width: '80%',
                    minWidth: '840px',
                    background: '#559ae9',
                    color: '#ffffff',
                },
                className: 'bg-primary',
                icon: notificationIcon,
                position: 'bottom-center',
                duration: 8 * 1000,
            }
        );
    }

    render() {
        return (
            <div className="alarms-toaster">
            </div>
        );
    }
}

NotificationToaster.defaultProps = {
    actions: {},
    currentUser: {},
    toastNotification: {},
};

NotificationToaster.propTypes = {
    actions: PropTypes.object,
    currentUser: PropTypes.object,
    toastNotification: PropTypes.object,
};

const mapStateToProps = state => ({
    currentUser: state.currentUser,
    toastNotification: state.toastNotification,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}, notificationsActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationToaster);
