import cloneDeep from 'lodash/cloneDeep';
import { GET_UNIVERSITIES_ERROR, GET_UNIVERSITIES_STARTING, GET_UNIVERSITIES_SUCCESS } from "../constants/actionTypes";
import initialState from "../store/initialState";

export default function universitiesReducer(state = initialState.universities, action) {
    switch (action.type) {
        case GET_UNIVERSITIES_STARTING: {
            console.log('Get universities starting in reducer');
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_UNIVERSITIES_SUCCESS: {
            console.log('Get universities success in reducer');
            console.log(action);
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = true;
            newState.data = action.data.universities;
            return newState;
        }

        case GET_UNIVERSITIES_ERROR: {
            console.log('Get universities error in reducer');
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            return newState;
        }

        default: {
            return state;
        }
    }
}
