import {
    GET_ALARMS_STARTING,
    GET_ALARMS_SUCCESS,
    GET_ALARMS_ERROR,

    GET_ALERTS_STARTING,
    GET_ALERTS_SUCCESS,
    GET_ALERTS_ERROR,

    GET_REQUEST_NOTIFICATIONS_STARTING,
    GET_REQUEST_NOTIFICATIONS_SUCCESS,
    GET_REQUEST_NOTIFICATIONS_ERROR,

    GET_ALARM_NOTIFICATIONS_STARTING,
    GET_ALARM_NOTIFICATIONS_SUCCESS,
    GET_ALARM_NOTIFICATIONS_ERROR,

    ACKNOWLEDGE_ALARM_STARTING,
    ACKNOWLEDGE_ALARM_SUCCESS,
    ACKNOWLEDGE_ALARM_ERROR,

    DELETE_ALL_ALARMS_STARTING,
    DELETE_ALL_ALARMS_SUCCESS,
    DELETE_ALL_ALARMS_ERROR,
} from '../constants/actionTypes';
import axios from '../axios';
import {
    GET_ALARMS,
    GET_ALERTS,
    GET_REQUEST_NOTIFICATIONS,
    GET_ALARM_NOTIFICATIONS,
    ACKNOWLEDGE_ALARM,
    DELETE_ALL_ALARMS,
} from '../constants/apiUrls';

export function getAlarmsStarting() {
    return {type: GET_ALARMS_STARTING};
}

export function getAlarmsSuccess(data) {
    return {type: GET_ALARMS_SUCCESS, data};
}

export function getAlarmsError(err) {
    return {type: GET_ALARMS_ERROR, err};
}

export function getAlarms() {
    return (dispatch) => {
        dispatch(getAlarmsStarting());

        axios.get(GET_ALARMS).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(getAlarmsSuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to get alarms';
            dispatch(getAlarmsError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(getAlarmsError(error));
        });
    };
}

export function getAlertsStarting() {
    return {type: GET_ALERTS_STARTING};
}

export function getAlertsSuccess(data) {
    return {type: GET_ALERTS_SUCCESS, data};
}

export function getAlertsError(err) {
    return {type: GET_ALERTS_ERROR, err};
}

export function getAlerts() {
    return (dispatch) => {
        dispatch(getAlertsStarting());

        axios.get(GET_ALERTS).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(getAlertsSuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to get alerts';
            dispatch(getAlertsError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(getAlertsError(error));
        });
    };
}

export function acknowledgeAlarmStarting() {
    return {type: ACKNOWLEDGE_ALARM_STARTING};
}

export function acknowledgeAlarmSuccess(data) {
    return {type: ACKNOWLEDGE_ALARM_SUCCESS, data};
}

export function acknowledgeAlarmError(err) {
    return {type: ACKNOWLEDGE_ALARM_ERROR, err};
}

export function acknowledgeAlarm(alarmId, postData) {
    return (dispatch) => {
        dispatch(acknowledgeAlarmStarting());

        const url = ACKNOWLEDGE_ALARM.replace('{alarmId}', alarmId);
        axios.post(url, postData).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(acknowledgeAlarmSuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to acknowledge alarm';
            dispatch(acknowledgeAlarmError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(acknowledgeAlarmError(error));
        });
    };
}

export function deleteAllAlarmsStarting() {
    return {type: DELETE_ALL_ALARMS_STARTING};
}

export function deleteAllAlarmsSuccess(data) {
    return {type: DELETE_ALL_ALARMS_SUCCESS, data};
}

export function deleteAllAlarmsError(err) {
    return {type: DELETE_ALL_ALARMS_ERROR, err};
}

export function deleteAllAlarms() {
    return (dispatch) => {
        dispatch(deleteAllAlarmsStarting());

        axios.get(DELETE_ALL_ALARMS).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(deleteAllAlarmsSuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to delete all alarms';
            dispatch(deleteAllAlarmsError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(deleteAllAlarmsError(error));
        });
    };
}

export function getRequestNotificationsStarting() {
    return {type: GET_REQUEST_NOTIFICATIONS_STARTING};
}

export function getRequestNotificationsSuccess(data) {
    return {type: GET_REQUEST_NOTIFICATIONS_SUCCESS, data};
}

export function getRequestNotificationsError(err) {
    return {type: GET_REQUEST_NOTIFICATIONS_ERROR, err};
}

export function getRequestNotifications(searchQuery, page, limit) {
    return (dispatch) => {
        dispatch(getRequestNotificationsStarting());

        axios.post(GET_REQUEST_NOTIFICATIONS, {
            searchQuery,
            page,
            limit,
        }).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(getRequestNotificationsSuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to get request notifications.js';
            dispatch(getRequestNotificationsError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(getRequestNotificationsError(error));
        });
    };
}

export function getAlarmNotificationsStarting() {
    return {type: GET_ALARM_NOTIFICATIONS_STARTING};
}

export function getAlarmNotificationsSuccess(data) {
    return {type: GET_ALARM_NOTIFICATIONS_SUCCESS, data};
}

export function getAlarmNotificationsError(err) {
    return {type: GET_ALARM_NOTIFICATIONS_ERROR, err};
}

export function getAlarmNotifications(searchQuery, page, limit) {
    return (dispatch) => {
        dispatch(getAlarmNotificationsStarting());
        axios.post(GET_ALARM_NOTIFICATIONS, {
            searchQuery,
            page,
            limit,
        }).then((response) => {
            if(response.status === 200 && response.data.success) {
                dispatch(getAlarmNotificationsSuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.err) ?
            response.data.error:'Failed to get get alarm notifications.js';
            dispatch(getAlarmNotificationsError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
            err.response.data.error: err.message;
            dispatch(getAlarmNotificationsError(error));
        });
    };
}