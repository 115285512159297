import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  GET_JOB_BY_ID_STARTING,
  GET_JOB_BY_ID_SUCCESS,
  GET_JOB_BY_ID_ERROR,
  GET_JOBS_ERROR,
  GET_JOBS_STARTING,
  GET_JOBS_SUCCESS,
  ADD_JOB_SUCCESS,  
  UPDATE_JOB_SUCCESS,
  DEACTIVATE_JOB_SUCCESS,
  ACTIVATE_JOB_SUCCESS,
  CREATE_JOB_APPLICATION_SUCCESS,
} from '../../constants/actionTypes';

const sortByOrder = (newState) => {
  newState.ids.sort((job1Id, job2Id) => {
    const job1 = newState.map[job1Id];
    const job2 = newState.map[job2Id];
    return job1.displayOrder - job2.displayOrder;
  });

  newState.inactiveIds.sort((job1Id, job2Id) => {
    const job1 = newState.map[job1Id];
    const job2 = newState.map[job2Id];
    return job1.displayOrder - job2.displayOrder;
  });
}

export default function jobsReducer(state = initialState.jobs, action) {
  switch (action.type) {
    case GET_JOB_BY_ID_STARTING: {
      const newState = cloneDeep(state);
      newState.loading = true;
      newState.loaded = false;
      return newState;
    }

    case GET_JOB_BY_ID_SUCCESS: {
      const newState = cloneDeep(state);
      const { jobId, data } = action;
      newState.map[jobId] = data.job;
      newState.applications[jobId] = data.application;
      newState.loading = false;
      newState.loaded = true;
      return newState;
    }

    case GET_JOB_BY_ID_ERROR: {
      const newState = cloneDeep(state);
      newState.loading = false;
      newState.loaded = false;
      newState.error = action.err ? action.err : 'Failed to retrieve job details by ID.';
      return newState;
    }

    case GET_JOBS_STARTING: {
      const newState = cloneDeep(state);
      newState.loading = true;
      newState.loaded = false;
      return newState;
    }

    case GET_JOBS_SUCCESS: {
      const newState = cloneDeep(state);
      const ids = [];

      action.data.jobs.forEach((job) => {
        const jobId = job.id;
        ids.push(jobId);
        newState.map[jobId] = job;
      });

      newState.ids = ids;

      //newState.page = action.data.page;
      //newState.pages = action.data.pages;
      //newState.limit = action.data.limit;
      //newState.total = action.data.total;
      //newState.isLastPage = newState.page >= newState.pages;
      newState.loading = false;
      newState.loaded = true;

      sortByOrder(newState);

      return newState;
    }

    case GET_JOBS_ERROR: {
      const newState = cloneDeep(state);
      newState.loading = false;
      newState.loaded = false;
      newState.error = action.err ? action.err : 'Failed to retrieve jobs.';
      return newState;
    }

    case ADD_JOB_SUCCESS: {
      const newState = cloneDeep(state);
      const { job } = action.data;
      const jobId = job.id;

      // Push new job data
      newState.map[jobId] = job;
      newState.ids.push(jobId);

      // Sort by display order
      sortByOrder(newState);

      return newState;
    }

    case UPDATE_JOB_SUCCESS: {
      const newState = cloneDeep(state);
      const { job } = action.data;
      const jobId = job.id;
      newState.map[jobId] = job;

      sortByOrder(newState);
      return newState;
    }

    case ACTIVATE_JOB_SUCCESS: {
      const newState = cloneDeep(state);
      const { jobId } = action;
      newState.ids.push(jobId);

      newState.inactiveIds = newState.inactiveIds.filter((jId) => {
        return jobId !== jId;
      });

      if (newState.map[jobId]) {
        newState.map[jobId].isActive = 1;
      }
      
      sortByOrder(newState);
      return newState;
    }

    case DEACTIVATE_JOB_SUCCESS: {
      const newState = cloneDeep(state);
      const { jobId } = action;
      newState.inactiveIds.push(jobId);

      newState.ids = newState.ids.filter((jId) => {
        return jobId !== jId;
      });

      if (newState.map[jobId]) {
        newState.map[jobId].isActive = 0;
      }

      sortByOrder(newState);
      return newState;
    }

    case CREATE_JOB_APPLICATION_SUCCESS: {
      const newState = cloneDeep(state);
      const { jobId, data } = action;
      newState.applications[jobId] = data.application;
      return newState;
    }

    default: {
      return state;
    }
  }
}
