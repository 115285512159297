import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    DEACTIVATE_AREA_STARTING,
    DEACTIVATE_AREA_SUCCESS,
    DEACTIVATE_AREA_ERROR,
} from '../../constants/actionTypes';

export default function deactivateAreaReducer(state = initialState.deactivateArea, action) {
    switch (action.type) {
        case DEACTIVATE_AREA_STARTING: {
            const {areaId} = action;
            const newState = clone(state);

            if (!newState[areaId]) {
                newState[areaId] = clone(initialState.deactivateArea.areaId);
            }

            const locationData = newState[areaId];
            locationData.saving = true;
            locationData.saved = false;
            locationData.error = null;

            return newState;
        }

    case DEACTIVATE_AREA_SUCCESS: {
        const {areaId} = action;
        const newState = clone(state);

        if (!newState[areaId]) {
            newState[areaId] = clone(initialState.deactivateArea.areaId);
        }

        const locationData = newState[areaId];
        locationData.saving = false;
        locationData.saved = true;
        locationData.error = null;
        return newState;
    }

    case DEACTIVATE_AREA_ERROR: {
        const {areaId} = action;
        const newState = clone(state);

        if (!newState[areaId]) {
            newState[areaId] = clone(initialState.deactivateArea.areaId);
        }

        const locationData = newState[areaId];
        locationData.saving = false;
        locationData.saved = false;
        locationData.error = action.err;
        return newState;
    }

    default: {
        return state;
    }
    }
}