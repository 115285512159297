import { cloneDeep } from 'lodash';
import {
    GET_SHOPPING_CART_STARTING,
    GET_SHOPPING_CART_SUCCESS,
    GET_SHOPPING_CART_ERROR,

    SET_CART_QUANTITY_SUCCESS,
} from '../../constants/actionTypes';
import initialState from '../../store/initialState';

export default function cartReducer(state = initialState.cart, action) {
    switch (action.type) {

        case GET_SHOPPING_CART_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_SHOPPING_CART_SUCCESS:
        case SET_CART_QUANTITY_SUCCESS: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = true;
            newState.cartData = action.data.cart;
            return newState;
        }

        case GET_SHOPPING_CART_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to retrive shopping cart';

            return newState;
        }

        default: {
            return state;
        }
    }
}