import React from 'react';
import PropTypes from 'prop-types';
import LoadingIcon from "./Utilities/LoadingIcon";

const EmptyLoader = ({ color, className }) => (
  <div className={className}>
    <div className="pt-12">
      <div className={`flex flex-col justify-center align-center text-center text-${color}`}>
        <div className="">
          <LoadingIcon color={color} />
        </div>

        <p className="mt-5 animate-pulse">Please wait</p>
      </div>
    </div>
  </div>
);

EmptyLoader.defaultProps = {
  className: '',
  color: 'neutral',
};

EmptyLoader.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

export default EmptyLoader;
