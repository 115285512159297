import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  DEACTIVATE_ROOM_STARTING,
  DEACTIVATE_ROOM_SUCCESS,
  DEACTIVATE_ROOM_ERROR,
} from '../../constants/actionTypes';

export default function deactivateRoomReducer(state = initialState.deactivateRoom, action) {
  switch (action.type) {
    case DEACTIVATE_ROOM_STARTING: {
      const {roomId} = action;
      const newState = clone(state);

      if (!newState[roomId]) {
        newState[roomId] = clone(initialState.deactivateRoom.roomId);
      }

      const locationData = newState[roomId];
      locationData.saving = true;
      locationData.saved = false;
      locationData.error = null;

      return newState;
    }

    case DEACTIVATE_ROOM_SUCCESS: {
      const {roomId} = action;
      const newState = clone(state);

      if (!newState[roomId]) {
        newState[roomId] = clone(initialState.deactivateRoom.roomId);
      }

      const locationData = newState[roomId];
      locationData.saving = false;
      locationData.saved = true;
      locationData.error = null;
      return newState;
    }

    case DEACTIVATE_ROOM_ERROR: {
      const {roomId} = action;
      const newState = clone(state);

      if (!newState[roomId]) {
        newState[roomId] = clone(initialState.deactivateRoom.roomId);
      }

      const locationData = newState[roomId];
      locationData.saving = false;
      locationData.saved = false;
      locationData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
