import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  GET_LOCATION_AREAS_ERROR,
  GET_LOCATION_AREAS_STARTING,
  GET_LOCATION_AREAS_SUCCESS,
} from '../../constants/actionTypes';

export default function locationAreasReducer(state = initialState.locationAreas, action) {
  switch (action.type) {
    case GET_LOCATION_AREAS_STARTING: {
      const { locationId } = action;
      const newState = cloneDeep(state);

      if (!newState[locationId]) {
        newState[locationId] = cloneDeep(initialState.locationAreas.locationId);
      }

      const areasData = newState[locationId];
      areasData.ids = [];
      areasData.map = {};
      areasData.loading = true;
      areasData.loaded = false;
      areasData.error = null;

      return newState;
    }

    case GET_LOCATION_AREAS_SUCCESS: {
      const { data, locationId } = action;
      const newState = cloneDeep(state);

      if (!newState[locationId]) {
        newState[locationId] = cloneDeep(initialState.locationAreas.locationId);
      }

      const areasData = newState[locationId];
      const ids = [];
      const map = {};

      data.areas.forEach((area) => {
        ids.push(area.id);
        map[area.id] = area;
      });

      areasData.ids = ids;
      areasData.map = map;
      areasData.loading = false;
      areasData.loaded = true;

      //areasData.page = action.data.page;
      //areasData.pages = action.data.pages;
      //areasData.limit = action.data.limit;
      //areasData.total = action.data.total;
      //areasData.isLastPage = areasData.page >= areasData.pages;

      return newState;
    }

    case GET_LOCATION_AREAS_ERROR: {
      const { locationId } = action;
      const newState = cloneDeep(state);

      if (!newState[locationId]) {
        newState[locationId] = cloneDeep(initialState.locationAreas.locationId);
      }

      const areasData = newState[locationId];
      areasData.loading = false;
      areasData.loaded = false;
      areasData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
