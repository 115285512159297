import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  ADD_ROOM_STARTING,
  ADD_ROOM_SUCCESS,
  ADD_ROOM_ERROR,
} from '../../constants/actionTypes';

export default function addRoomReducer(state = initialState.addRoom, action) {
  switch (action.type) {
    case ADD_ROOM_STARTING: {
      const newState = clone(state);
      newState.saving = true;
      newState.saved = false;
      return newState;
    }

    case ADD_ROOM_SUCCESS: {
      const { data } = action;

      const newState = clone(state);
      newState.saving = false;
      newState.saved = true;
      newState.error = null;

      newState.newRoom = data.room;
      return newState;
    }

    case ADD_ROOM_ERROR: {
      const newState = clone(state);
      newState.saving = false;
      newState.saved = false;
      newState.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
