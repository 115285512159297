import { cloneDeep } from 'lodash';
import {
    SET_CART_QUANTITY_STARTING,
    SET_CART_QUANTITY_SUCCESS,
    SET_CART_QUANTITY_ERROR,
} from '../../constants/actionTypes';
import initialState from '../../store/initialState';


export default function setCartQuantityReducer(state = initialState.setCartQuantity, action) {
    switch (action.type) {
        case SET_CART_QUANTITY_STARTING: {
            const newState = cloneDeep(state);
            const { productId } = action;

            if (!newState[productId]) {
                newState[productId] = cloneDeep(initialState.setCartQuantity.productId);
            }

            const cartQuantityData = newState[productId];
            cartQuantityData.saving = true;
            cartQuantityData.saved = false;
            return newState;
        }

        case SET_CART_QUANTITY_SUCCESS: {
            const newState = cloneDeep(state);
            const { productId } = action;

            if (!newState[productId]) {
                newState[productId] = cloneDeep(initialState.setCartQuantity.productId);
            }

            const cartQuantityData = newState[productId];
            cartQuantityData.saving = false;
            cartQuantityData.saved = true;
            cartQuantityData.error = '';
            return newState;
        }

        case SET_CART_QUANTITY_ERROR: {
            const newState = cloneDeep(state);
            const { productId } = action;

            if (!newState[productId]) {
                newState[productId] = cloneDeep(initialState.setCartQuantity.productId);
            }

            const cartQuantityData = newState[productId];
            cartQuantityData.saving = false;
            cartQuantityData.saved = false;
            cartQuantityData.error = action.err;
            return newState;
        }

        default: {
            return state;
        }
    }
}
