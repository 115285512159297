import { cloneDeep } from 'lodash';
import {
    GET_ADDRESSES_STARTING,
    GET_ADDRESSES_SUCCESS,
    GET_ADDRESSES_ERROR,
} from '../../constants/actionTypes';
import initialState from '../../store/initialState';

export default function addressesReducer(state = initialState.addresses, action) {
    switch (action.type) {

        case GET_ADDRESSES_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_ADDRESSES_SUCCESS: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = true;
            return newState;
        }

        case GET_ADDRESSES_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            return newState;
        }

        default: {
            return state;
        }
    }
}