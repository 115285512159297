import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  DEACTIVATE_LOCATION_STARTING,
  DEACTIVATE_LOCATION_SUCCESS,
  DEACTIVATE_LOCATION_ERROR,
} from '../../constants/actionTypes';

export default function deactivateLocationReducer(state = initialState.deactivateLocation, action) {
  switch (action.type) {
    case DEACTIVATE_LOCATION_STARTING: {
      const {locationId} = action;
      const newState = clone(state);

      if (!newState[locationId]) {
        newState[locationId] = clone(initialState.deactivateLocation.locationId);
      }

      const locationData = newState[locationId];
      locationData.saving = true;
      locationData.saved = false;
      locationData.error = null;

      return newState;
    }

    case DEACTIVATE_LOCATION_SUCCESS: {
      const {locationId} = action;
      const newState = clone(state);

      if (!newState[locationId]) {
        newState[locationId] = clone(initialState.deactivateLocation.locationId);
      }

      const locationData = newState[locationId];
      locationData.saving = false;
      locationData.saved = true;
      locationData.error = null;
      return newState;
    }

    case DEACTIVATE_LOCATION_ERROR: {
      const {locationId} = action;
      const newState = clone(state);

      if (!newState[locationId]) {
        newState[locationId] = clone(initialState.deactivateLocation.locationId);
      }

      const locationData = newState[locationId];
      locationData.saving = false;
      locationData.saved = false;
      locationData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
