import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  UPDATE_CATEGORY_STARTING,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_ERROR,
} from '../../constants/actionTypes';

export default function updateCategoryReducer(state = initialState.updateCategory, action) {
  switch (action.type) {
    case UPDATE_CATEGORY_STARTING: {
      const {categoryId} = action;
      const newState = clone(state);

      if (!newState[categoryId]) {
        newState[categoryId] = clone(initialState.updateCategory.categoryId);
      }

      const updateData = newState[categoryId];
      updateData.saving = true;
      updateData.saved = false;
      updateData.error = null;

      return newState;
    }

    case UPDATE_CATEGORY_SUCCESS: {
      const {categoryId} = action;
      const newState = clone(state);

      if (!newState[categoryId]) {
        newState[categoryId] = clone(initialState.updateCategory.categoryId);
      }

      const updateData = newState[categoryId];
      updateData.saving = false;
      updateData.saved = true;
      updateData.error = null;
      return newState;
    }

    case UPDATE_CATEGORY_ERROR: {
      const {categoryId} = action;
      const newState = clone(state);

      if (!newState[categoryId]) {
        newState[categoryId] = clone(initialState.updateCategory.categoryId);
      }

      const updateData = newState[categoryId];
      updateData.saving = false;
      updateData.saved = false;
      updateData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
