import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    ACTIVATE_AREA_STARTING,
    ACTIVATE_AREA_SUCCESS,
    ACTIVATE_AREA_ERROR,
} from '../../constants/actionTypes';

export default function activateAreaReducer(state = initialState.activateArea, action) {
    switch (action.type) {
        case ACTIVATE_AREA_STARTING: {
            const {areaId} = action;
            const newState = clone(state);

            if (!newState[areaId]) {
                newState[areaId] = clone(initialState.activateArea.areaId);
            }

            const locationData = newState[areaId];
            locationData.saving = true;
            locationData.saved = false;
            locationData.error = null;

            return newState;
        }

    case ACTIVATE_AREA_SUCCESS: {
        const {areaId} = action;
        const newState = clone(state);

        if (!newState[areaId]) {
            newState[areaId] = clone(initialState.activateArea.areaId);
        }

        const locationData = newState[areaId];
        locationData.saving = false;
        locationData.saved = true;
        locationData.error = null;
        return newState;
    }

    case ACTIVATE_AREA_ERROR: {
        const {areaId} = action;
        const newState = clone(state);

        if (!newState[areaId]) {
            newState[areaId] = clone(initialState.activateArea.areaId);
        }

        const locationData = newState[areaId];
        locationData.saving = false;
        locationData.saved = false;
        locationData.error = action.err;
        return newState;
    }

    default: {
        return state;
    }
    }
}