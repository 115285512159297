import clone from  'lodash/cloneDeep';
import initialState from "../../store/initialState";
import {
  ADD_LICENSE_STARTING,
  ADD_LICENSE_SUCCESS,
  ADD_LICENSE_ERROR,
} from '../../constants/actionTypes';

export default function addLicenseReducer(state = initialState.addLicense, action) {
    switch (action.type) {
        case ADD_LICENSE_STARTING: {
            const newState = clone(state);
            newState.saving = true;
            newState.saved = false;
            return newState;
        }
    
        case ADD_LICENSE_SUCCESS: {
            const {data} = action;
            const newState = clone(state);
            newState.saving = false;
            newState.saved = true;
            newState.error = null;
            
            newState.newLicense = data.license;
            return newState;
        }
        case ADD_LICENSE_ERROR: {
            const newState = clone(state);
            newState.saving = false;
            newState.saved = false;
            newState.error = action.err;
            return newState;
        }
        default: {
            return state;
    } 
  }
}