import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    UPDATE_AREA_STARTING,
    UPDATE_AREA_SUCCESS,
    UPDATE_AREA_ERROR,
} from '../../constants/actionTypes';

export default function updateAreaReducer(state = initialState.updateArea, action) {
    switch (action.type) {
        case UPDATE_AREA_STARTING: {
            const {areaId} = action;
            const newState = clone(state);

            if (!newState[areaId]) {
                newState[areaId] = clone(initialState.updateArea.areaId);
            }

            const updateData = newState[areaId];
            updateData.saving = true;
            updateData.saved = false;
            updateData.error = null;

            return newState;
        }

    case UPDATE_AREA_SUCCESS: {
        const {areaId} = action;
        const newState = clone(state);

        if (!newState[areaId]) {
            newState[areaId] = clone(initialState.updateArea.areaId);
        }

        const updateData = newState[areaId];
        updateData.saving = false;
        updateData.saved = true;
        updateData.error = null;
        return newState;
    }

    case UPDATE_AREA_ERROR: {
        const {areaId} = action;
        const newState = clone(state);

        if (!newState[areaId]) {
            newState[areaId] = clone(initialState.updateArea.areaId);
        }

        const updateData = newState[areaId];
        updateData.saving = false;
        updateData.saved = false;
        updateData.error = action.err;
        return newState;
    }

    default: {
        return state;
    }
    }
}