import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    ADD_AREA_STARTING,
    ADD_AREA_SUCCESS,
    ADD_AREA_ERROR,
} from '../../constants/actionTypes';

export default function addAreaReducer(state = initialState.addArea, action) {
    switch (action.type) {
        case ADD_AREA_STARTING: {
            const newState = clone(state);
            newState.saving = true;
            newState.saved = false;
            return newState;
        }

    case ADD_AREA_SUCCESS: {
        const { data } = action;

        const newState = clone(state);
        newState.saving = false;
        newState.saved = true;
        newState.error = null;

        newState.newArea = data.area;
        return newState;
    }

    case ADD_AREA_ERROR: {
        const newState = clone(state);
        newState.saving = false;
        newState.saved = false;
        newState.error = action.err;
        return newState;
    }

    default: {
        return state;
    }
    }
}