import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  ACTIVATE_ACK_MESSAGES_STARTING,
  ACTIVATE_ACK_MESSAGES_SUCCESS,
  ACTIVATE_ACK_MESSAGES_ERROR,
} from '../../constants/actionTypes';

export default function activateAckMessageReducer(state = initialState.activateAckMessage, action) {
    switch (action.type){
        case ACTIVATE_ACK_MESSAGES_STARTING:{
            const {ackMessageId} = action;
            const newState = clone(state);
                
                if(!newState[ackMessageId]) {
                    newState[ackMessageId] = clone(initialState.activateAckMessage.ackMessageId);
                }
                const AckMessageData = newState[ackMessageId];
                AckMessageData.saving = true;
                AckMessageData.saved = false;
                AckMessageData.error = null;
                
                return newState;
                }
           
        case ACTIVATE_ACK_MESSAGES_SUCCESS:{
            const {ackMessageId} = action;
            const newState = clone(state);
            
            if(!newState[ackMessageId]) {
                newState[ackMessageId] = clone(initialState.activateAckMessage.ackMessageId);
            }
            const AckMessageData = newState[ackMessageId];
            AckMessageData.saving = false;
            AckMessageData.saved = true;
            AckMessageData.error = null;
            
            return newState;
        }
        case ACTIVATE_ACK_MESSAGES_ERROR:{
           const {ackMessageId} = action;
           const newState = clone(state);
           
           if(!newState[ackMessageId]) {
               newState[ackMessageId] = clone(initialState.activateAckMessage.ackMessageId);
           }
            const  AckMessageData = newState[ackMessageId];
           AckMessageData.saving = false;
           AckMessageData.saved = false;
           AckMessageData.error = action.err;
           
           return newState;
        }
    
        default: {
            return state;            
        }
  }
}
        
        
            


