import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  GET_CATEGORY_POSTS_ERROR,
  GET_CATEGORY_POSTS_STARTING,
  GET_CATEGORY_POSTS_SUCCESS,
} from '../../constants/actionTypes';

export default function categoryPostsReducer(state = initialState.categoryPosts, action) {
  switch (action.type) {
    case GET_CATEGORY_POSTS_STARTING: {
      const { categoryId } = action;
      const newState = cloneDeep(state);

      if (!newState[categoryId]) {
        newState[categoryId] = cloneDeep(initialState.categoryPosts.categoryId);
      }

      const postsData = newState[categoryId];
      postsData.ids = [];
      postsData.map = {};
      postsData.loading = true;
      postsData.loaded = false;
      postsData.error = null;

      return newState;
    }

    case GET_CATEGORY_POSTS_SUCCESS: {
      const { data, categoryId } = action;
      const newState = cloneDeep(state);

      if (!newState[categoryId]) {
        newState[categoryId] = cloneDeep(initialState.categoryPosts.categoryId);
      }

      const postsData = newState[categoryId];
      const ids = [];
      const map = {};

      data.posts.forEach((post) => {
        ids.push(post.id);
        map[post.id] = post;
      });

      postsData.ids = ids;
      postsData.map = map;
      postsData.loading = false;
      postsData.loaded = true;

      //postsData.page = action.data.page;
      //postsData.pages = action.data.pages;
      //postsData.limit = action.data.limit;
      //postsData.total = action.data.total;
      //postsData.isLastPage = postsData.page >= postsData.pages;

      return newState;
    }

    case GET_CATEGORY_POSTS_ERROR: {
      const { categoryId } = action;
      const newState = cloneDeep(state);

      if (!newState[categoryId]) {
        newState[categoryId] = cloneDeep(initialState.categoryPosts.categoryId);
      }

      const postsData = newState[categoryId];
      postsData.loading = false;
      postsData.loaded = false;
      postsData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
