import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  GET_AHU_DUST_COLLECTOR_FILTER_REQUESTS_ERROR,
  GET_AHU_DUST_COLLECTOR_FILTER_REQUESTS_STARTING,
  GET_AHU_DUST_COLLECTOR_FILTER_REQUESTS_SUCCESS,

  ADD_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS,
  ACKNOWLEDGE_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS,
  RETURN_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS,
  CLOSE_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS,
  MARK_AS_DONE_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS,
  SET_FILTER_DETAILS_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS,
  UPDATE_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS,
} from '../../constants/actionTypes';
import {chunk} from "lodash";

export default function ahuDustCollectorFilterRequestsReducer(state = initialState.ahuDustCollectorFilterRequests, action) {
  switch (action.type) {
    case GET_AHU_DUST_COLLECTOR_FILTER_REQUESTS_STARTING: {
      const newState = cloneDeep(state);
      newState.loading = true;
      newState.loaded = false;
      newState.filterKey = action.filterKey;
      return newState;
    }

    case GET_AHU_DUST_COLLECTOR_FILTER_REQUESTS_SUCCESS: {
      if (state.filterKey !== action.filterKey) {
        return state;
      }

      const newState = cloneDeep(state);
      const ids = [];
      const map = {};

      action.data.ahuDustCollectorFilterRequests.forEach((ahuDustCollectorFilterRequest) => {
        const ahuDustCollectorFilterRequestId = ahuDustCollectorFilterRequest.id;
        ids.push(ahuDustCollectorFilterRequestId);
        map[ahuDustCollectorFilterRequestId] = ahuDustCollectorFilterRequest;
      });

      const pageLimit = 6;

      newState.ids = ids;
      newState.pagedIds = chunk(ids, pageLimit);
      newState.map = map;
      //newState.page = action.data.page;
      //newState.pages = action.data.pages;
      //newState.limit = action.data.limit;
      //newState.total = action.data.total;
      //newState.isLastPage = newState.page >= newState.pages;
      newState.loading = false;
      newState.loaded = true;

      return newState;
    }

    case GET_AHU_DUST_COLLECTOR_FILTER_REQUESTS_ERROR: {
      if (state.filterKey !== action.filterKey) {
        return state;
      }

      const newState = cloneDeep(state);
      newState.loading = false;
      newState.loaded = false;
      newState.error = action.err ? action.err : 'Failed to retrieve ahu dust collector filter replacement requests.';
      return newState;
    }

    case ADD_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS: {
      const newState = cloneDeep(state);
      const { ahuDustCollectorFilterRequest } = action.data;
      const ahuDustCollectorFilterRequestId = ahuDustCollectorFilterRequest.id;

      // Push new data
      newState.map[ahuDustCollectorFilterRequestId] = ahuDustCollectorFilterRequest;
      newState.ids = [ahuDustCollectorFilterRequestId].concat(newState.ids);
      // newState.ids.push(ahuDustCollectorFilterRequestId);

      return newState;
    }

    case ACKNOWLEDGE_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS:
    case RETURN_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS:
    case MARK_AS_DONE_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS:
    case SET_FILTER_DETAILS_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS:
    case UPDATE_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS:
    case CLOSE_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS: {
      const newState = cloneDeep(state);
      const { ahuDustCollectorFilterRequest } = action.data;
      const ahuDustCollectorFilterRequestId = ahuDustCollectorFilterRequest.id;

      // Push new data
      newState.map[ahuDustCollectorFilterRequestId] = ahuDustCollectorFilterRequest;
      return newState;
    }

    // case CLOSE_AHU_DUST_COLLECTOR_FILTER_REQUEST_SUCCESS: {
    //   const newState = cloneDeep(state);
    //   const { ahuDustCollectorFilterRequest } = action.data;
    //   const ahuDustCollectorFilterRequestId = ahuDustCollectorFilterRequest.id;
    //
    //   newState.ids = newState.ids.filter((id) => id !== ahuDustCollectorFilterRequestId);
    //   return newState;
    // }

    default: {
      return state;
    }
  }
}
