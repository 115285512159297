import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  ACTIVATE_NETWORK_STARTING,
  ACTIVATE_NETWORK_SUCCESS,
  ACTIVATE_NETWORK_ERROR,
} from '../../constants/actionTypes';

export default function activateNetworkReducer(state = initialState.activateNetwork, action) {
  switch (action.type) {
    case ACTIVATE_NETWORK_STARTING: {
      const {networkId} = action;
      const newState = clone(state);

      if (!newState[networkId]) {
        newState[networkId] = clone(initialState.activateNetwork.networkId);
      }

      const locationData = newState[networkId];
      locationData.saving = true;
      locationData.saved = false;
      locationData.error = null;

      return newState;
    }

    case ACTIVATE_NETWORK_SUCCESS: {
      const {networkId} = action;
      const newState = clone(state);

      if (!newState[networkId]) {
        newState[networkId] = clone(initialState.activateNetwork.networkId);
      }

      const locationData = newState[networkId];
      locationData.saving = false;
      locationData.saved = true;
      locationData.error = null;
      return newState;
    }

    case ACTIVATE_NETWORK_ERROR: {
      const {networkId} = action;
      const newState = clone(state);

      if (!newState[networkId]) {
        newState[networkId] = clone(initialState.activateNetwork.networkId);
      }

      const locationData = newState[networkId];
      locationData.saving = false;
      locationData.saved = false;
      locationData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
