import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  ADD_PARAMETER_STARTING,
  ADD_PARAMETER_SUCCESS,
  ADD_PARAMETER_ERROR,
} from '../../constants/actionTypes';

export default function addParameterReducer(state = initialState.addParameter, action) {
  switch (action.type) {
    case ADD_PARAMETER_STARTING: {
      const newState = clone(state);
      newState.saving = true;
      newState.saved = false;
      return newState;
    }

    case ADD_PARAMETER_SUCCESS: {
      const { data } = action;

      const newState = clone(state);
      newState.saving = false;
      newState.saved = true;
      newState.error = null;

      newState.newParameter = data.parameter;
      return newState;
    }

    case ADD_PARAMETER_ERROR: {
      const newState = clone(state);
      newState.saving = false;
      newState.saved = false;
      newState.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
