import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as socketActions from '../../actions/socketActions';

class ParameterDataFetch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            paramDataTimer: 0,
        };

        this.startGetParamDataFromSocket = this.startGetParamDataFromSocket.bind(this);
    }

    componentDidMount() {
        // this.startGetParamDataFromSocket();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.startGetParamDataFromSocket();
    }

    componentWillUnmount() {
        this.clearGetParamDataTimer();
    }

    startGetParamDataFromSocket() {
        const {actions, parameterData} = this.props;
        const {paramDataTimer} = this.state;

        if (paramDataTimer) {
            // Do nothing
            return;
        }

        if (!parameterData || !parameterData.ids) {
            return;
        }

        // Get notifications now
        if (parameterData.ids.length) {
            // console.log('Get param data happening here');
            // console.log(parameterData.ids);
            actions.getParamData(parameterData.ids);
        }

        const timer = setInterval(() => {
            const {parameterData} = this.props;
            // console.log('----------');
            // console.log(parameterData.ids);
            // console.log('----------');

            if (!parameterData || !parameterData.ids) {
                return;
            }

            if (parameterData.ids.length) {
                // console.log('Get param data happening here');
                // console.log(parameterData.ids);
                actions.getParamData(parameterData.ids);
            }
        }, 1.6 * 1000);

        console.log('Setting param data timer');
        this.setState({
            paramDataTimer: timer,
        });
    }

    clearGetParamDataTimer() {
        const {paramDataTimer} = this.state;
        if (paramDataTimer) {
            console.log('Clearing param data timer');
            clearInterval(paramDataTimer);
        }
    }

    render() {
        return (
            <div className="param-data-fetcher">
            </div>
        );
    }
}

ParameterDataFetch.defaultProps = {
    actions: {},
    currentUser: {},
    parameterData: {},
};

ParameterDataFetch.propTypes = {
    actions: PropTypes.object,
    currentUser: PropTypes.object,
    parameterData: PropTypes.object,
};

const mapStateToProps = state => ({
    currentUser: state.currentUser,
    parameterData: state.parameterData,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}, socketActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ParameterDataFetch);
