import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  ACTIVATE_PARAMETER_STARTING,
  ACTIVATE_PARAMETER_SUCCESS,
  ACTIVATE_PARAMETER_ERROR,
} from '../../constants/actionTypes';

export default function activateParameterReducer(state = initialState.activateParameter, action) {
  switch (action.type) {
    case ACTIVATE_PARAMETER_STARTING: {
      const {paramId} = action;
      const newState = clone(state);

      if (!newState[paramId]) {
        newState[paramId] = clone(initialState.activateParameter.paramId);
      }

      const locationData = newState[paramId];
      locationData.saving = true;
      locationData.saved = false;
      locationData.error = null;

      return newState;
    }

    case ACTIVATE_PARAMETER_SUCCESS: {
      const {paramId} = action;
      const newState = clone(state);

      if (!newState[paramId]) {
        newState[paramId] = clone(initialState.activateParameter.paramId);
      }

      const locationData = newState[paramId];
      locationData.saving = false;
      locationData.saved = true;
      locationData.error = null;
      return newState;
    }

    case ACTIVATE_PARAMETER_ERROR: {
      const {paramId} = action;
      const newState = clone(state);

      if (!newState[paramId]) {
        newState[paramId] = clone(initialState.activateParameter.paramId);
      }

      const locationData = newState[paramId];
      locationData.saving = false;
      locationData.saved = false;
      locationData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
