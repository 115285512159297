import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  GET_AHU_ON_OFF_REQUESTS_ERROR,
  GET_AHU_ON_OFF_REQUESTS_STARTING,
  GET_AHU_ON_OFF_REQUESTS_SUCCESS,

  ADD_AHU_ON_OFF_REQUEST_SUCCESS,
  ACKNOWLEDGE_AHU_ON_OFF_REQUEST_SUCCESS,
  CLOSE_AHU_ON_OFF_REQUEST_SUCCESS,
  MARK_AS_DONE_AHU_ON_OFF_REQUEST_SUCCESS,
} from '../../constants/actionTypes';

export default function ahuOnOffRequestsReducer(state = initialState.ahuOnOffRequests, action) {
  switch (action.type) {
    case GET_AHU_ON_OFF_REQUESTS_STARTING: {
      const newState = cloneDeep(state);
      newState.loading = true;
      newState.loaded = false;
      newState.filterKey = action.filterKey;
      return newState;
    }

    case GET_AHU_ON_OFF_REQUESTS_SUCCESS: {
      if (state.filterKey !== action.filterKey) {
        return state;
      }

      const newState = cloneDeep(state);
      const ids = [];
      const map = {};

      action.data.ahuOnOffRequests.forEach((ahuOnOffRequest) => {
        const ahuOnOffRequestId = ahuOnOffRequest.id;
        ids.push(ahuOnOffRequestId);
        map[ahuOnOffRequestId] = ahuOnOffRequest;
      });

      newState.ids = ids;
      newState.map = map;
      //newState.page = action.data.page;
      //newState.pages = action.data.pages;
      //newState.limit = action.data.limit;
      //newState.total = action.data.total;
      //newState.isLastPage = newState.page >= newState.pages;
      newState.loading = false;
      newState.loaded = true;

      return newState;
    }

    case GET_AHU_ON_OFF_REQUESTS_ERROR: {
      if (state.filterKey !== action.filterKey) {
        return state;
      }

      const newState = cloneDeep(state);
      newState.loading = false;
      newState.loaded = false;
      newState.error = action.err ? action.err : 'Failed to retrieve ahu on off requests.';
      return newState;
    }

    case ADD_AHU_ON_OFF_REQUEST_SUCCESS: {
      const newState = cloneDeep(state);
      const { ahuOnOffRequest } = action.data;
      const ahuOnOffRequestId = ahuOnOffRequest.id;

      // Push new data
      newState.map[ahuOnOffRequestId] = ahuOnOffRequest;
      newState.ids = [ahuOnOffRequestId].concat(newState.ids);
      // newState.ids.push(ahuOnOffRequestId);

      return newState;
    }

    case ACKNOWLEDGE_AHU_ON_OFF_REQUEST_SUCCESS:
    case MARK_AS_DONE_AHU_ON_OFF_REQUEST_SUCCESS: {
      const newState = cloneDeep(state);
      const { ahuOnOffRequest } = action.data;
      const ahuOnOffRequestId = ahuOnOffRequest.id;

      // Push new data
      newState.map[ahuOnOffRequestId] = ahuOnOffRequest;
      return newState;
    }

    case CLOSE_AHU_ON_OFF_REQUEST_SUCCESS: {
      const newState = cloneDeep(state);
      const { ahuOnOffRequest } = action.data;
      const ahuOnOffRequestId = ahuOnOffRequest.id;

      newState.ids = newState.ids.filter((id) => id !== ahuOnOffRequestId);
      return newState;
    }

    default: {
      return state;
    }
  }
}
