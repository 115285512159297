import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    GET_REQUEST_NOTIFICATIONS_ERROR,
    GET_REQUEST_NOTIFICATIONS_STARTING,
    GET_REQUEST_NOTIFICATIONS_SUCCESS,
} from '../../constants/actionTypes';

export default function requestNotificationsReducer(state = initialState.requestNotifications, action) {
    switch (action.type) {
        case GET_REQUEST_NOTIFICATIONS_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_REQUEST_NOTIFICATIONS_SUCCESS: {
            const newState = cloneDeep(state);
            const ids = [];
            const map = {};

            action.data.notifications.forEach((notification) => {
                const notificationId = notification.id;
                ids.push(notificationId);
                map[notificationId] = notification;
            });

            newState.ids = ids;
            newState.map = map;
            //newState.page = action.data.page;
            //newState.pages = action.data.pages;
            //newState.limit = action.data.limit;
            //newState.total = action.data.total;
            //newState.isLastPage = newState.page >= newState.pages;
            newState.loading = false;
            newState.loaded = true;

            return newState;
        }

        case GET_REQUEST_NOTIFICATIONS_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to retrieve request notifications.js.';
            return newState;
        }

        default: {
            return state;
        }
    }
}
