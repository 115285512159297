import { cloneDeep } from 'lodash';
import {
    GET_TENANTS_STARTING,
    GET_TENANTS_SUCCESS,
    GET_TENANTS_ERROR,
    ADD_TENANT_SUCCESS,
    UPDATE_TENANT_SUCCESS,
} from '../../../constants/actionTypes';
import initialState from '../../../store/initialState';

export default function tenantsReducer(state = initialState.tenants, action) {
    switch (action.type) {
        case GET_TENANTS_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_TENANTS_SUCCESS: {
            const newState = cloneDeep(state);
            const ids = [];
            const map = {};

            action.data.tenants.forEach((tenant) => {
                const tenantId = tenant.id;
                ids.push(tenantId);
                map[tenantId] = tenant;
            });

            newState.ids = ids;
            newState.map = map;


            newState.loading = false;
            newState.loaded = true;

            return newState;
        }

        case GET_TENANTS_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to  retrieve tenants.';
            return newState;
        }

        case ADD_TENANT_SUCCESS: {
            const newState = cloneDeep(state);
            const { tenant } = action.data;
            const tenantId = tenant.id;


            newState.map[tenantId] = tenant;
            newState.ids.push(tenantId);

            newState.ids.sort((ten1Id, ten2Id) => {
                const ten1 = newState.map[ten1Id];
                const ten2 = newState.map[ten2Id];
                return ten1.id - ten2.id;
            });

            return newState;
        }

        case UPDATE_TENANT_SUCCESS: {
            const newState = cloneDeep(state);
            const { tenant } = action.data;
            const tenantId = tenant.id;
            newState.map[tenantId] = tenant;
            return newState;
        }

        default: {
            return state;
        }
    }
}