import isNil from 'lodash/isNil';
import moment from 'moment-timezone';
import {dateFormats, twentyOneCfrConfig, utcOffset} from '../constants/appConfig';

moment.tz.setDefault(dateFormats.timeZone);

export const UserAgentTester = {
    Android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i);
    },
    AnyMobile: function () {
        return (UserAgentTester.Android() || UserAgentTester.BlackBerry() || UserAgentTester.iOS() || UserAgentTester.Opera() || UserAgentTester.Windows());
    },
    Chrome: function () {
        return !isNil(window.chrome);
    },
};

export function suppressEvent(event) {
    if (event) {
        event.preventDefault();
        event.stopPropagation();
    }
}

export function strOr(s, orDefaultValue = '') {
    return s ? s : orDefaultValue;
}

export function stripText(st, len = 10) {
    if (isNil(st) || !st.length) {
        return '';
    }

    if (st.length <= len) {
        return st;
    }

    for (let i = len; i > 0; i--) {
        if (st.charAt(i) === ' ' && (st.charAt(i - 1) !== ',' || st.charAt(i - 1) !== '.' || st.charAt(i - 1) !== ';')) {
            return st.substring(0, i) + '…';
        }
    }

    return st.substring(0, len) + '…';
}

export function getTimeAgo(dtStr, useFullSuffix = false, ignoreBig = false) {
    const now = moment();
    const dt = moment.utc(dtStr, dateFormats.in.datetime);
    const diff = moment.duration(now.diff(dt));

    const bigLimit = 1; // days
    const days = Math.floor(diff.asDays());

    if (ignoreBig && (days > bigLimit)) {
        return '';
    }

    const years = Math.floor(diff.asYears());
    if (years > 0) {
        return `${years} y${useFullSuffix ? `ear${years === 1 ? '' : 's'}` : ''}`;
    }

    const months = Math.floor(diff.asMonths());
    if (months > 0) {
        return `${months} mo${useFullSuffix ? `nth${months === 1 ? '' : 's'}` : ''}`;
    }

    const weeks = Math.floor(diff.asWeeks());
    if (weeks > 0) {
        return `${weeks} w${useFullSuffix ? `eek${weeks === 1 ? '' : 's'}` : ''}`;
    }

    if (days > 0) {
        return `${days} d${useFullSuffix ? `ay${days === 1 ? '' : 's'}` : ''}`;
    }

    const hours = Math.floor(diff.asHours());
    if (hours > 0) {
        return `${hours} h${useFullSuffix ? `our${hours === 1 ? '' : 's'}` : ''}`;
    }

    const minutes = Math.floor(diff.asMinutes());
    if (minutes > 0) {
        return `${minutes} m${useFullSuffix ? `inute${minutes === 1 ? '' : 's'}` : ''}`;
    }

    const seconds = Math.floor(diff.asSeconds());
    if (seconds > 0) {
        return `${seconds} s${useFullSuffix ? `econd${seconds === 1 ? '' : 's'}` : ''}`;
    }

    return `Now`;
}

export function getUserDisplayName(user, defaultName='') {
    if (!user) {
        return defaultName;
    }

    if (!user.fullname) {
        return `${user.username} (ID: ${user.id}))`;
    }

    return `${user.fullname} (${user.username})`;
}

export function getFormattedDateStrUtc(dtStr) {
    const d = moment(dtStr);
    // d.utcOffset(utcOffset);
    return d.format(dateFormats.out.datetime) + ' Hrs.';
}

export function getInputDateStr(dtStr, invalidValue) {
    const d = moment(dtStr);

    if (!d.isValid()) {
        return invalidValue ? invalidValue : 'Invalid Date'
    }

    d.utcOffset(utcOffset);
    return d.format(dateFormats.in.datetime);
}

export function getFormattedDateStr(dtStr, invalidValue) {
    const d = moment(dtStr);

    if (!d.isValid()) {
        return invalidValue ? invalidValue : 'Invalid Date'
    }

    d.utcOffset(utcOffset);
    return d.format(dateFormats.out.datetime) + ' Hrs.';
}

export function getFormattedDurationStr(seconds) {
    const d = moment.duration(seconds, 'seconds');

    if (Math.floor(d.asYears()) > 0) {
        return `${d.years()}y ${d.months()}mo`;
    } else if (Math.floor(d.asMonths()) > 0) {
        return `${d.months()}mo ${d.days()}d`;
    } else if (Math.floor(d.asDays()) > 0) {
        return `${d.days()}d ${d.hours()}h`;
    } else if (Math.floor(d.asHours()) > 0) {
        return `${d.hours()}h ${d.minutes()}m`;
    } else if (Math.floor(d.asMinutes()) > 0) {
        return `${d.minutes()}m ${d.seconds()}s`;
    } else if (d.asSeconds() > 0) {
        return `${d.seconds()}s`;
    }

    return '0s';
}

export function isPasswordValid(pwd, minLength = twentyOneCfrConfig.minPasswordLength) {
    if (pwd.length < minLength) {
        return false;
    }

    const hasUpper = /[A-Z]/.test(pwd);
    const hasLower = /[a-z]/.test(pwd);
    const hasNumber = /\d/.test(pwd);
    const hasNonAlpha = /\W/.test(pwd);
    const complexity = hasUpper + hasLower + hasNumber + hasNonAlpha;

    return (complexity >= 4);
}

export function checkIfVideoUrl(url) {
    return url.indexOf('youtube.com') !== -1 || url.indexOf('vimeo.com') !== -1|| url.indexOf('dailymotion.com') !== -1;
}

export function getFormattedAddress(address) {
    const fragments = [];

    if (address.line1) {
        fragments.push(address.line1);
    }

    if (address.line2) {
        fragments.push(address.line2);
    }

    if (address.street) {
        fragments.push(address.street);
    }

    if (address.village) {
        fragments.push(address.village.name);
    }

    if (address.city) {
        fragments.push(address.city.name);
    }

    if (address.zone) {
        fragments.push(address.zone.name);
    }

    if (address.country) {
        fragments.push(address.country.name);
    }

    let fullAddress = fragments.map(f => f.trim()).join(', ');

    if (address.pinCode) {
        fullAddress = `${fullAddress} - ${address.pinCode}.`;
    } else {
        fullAddress = `${fullAddress}.`;
    }

    return fullAddress;
}
