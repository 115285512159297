import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  UPDATE_PARAM_LIMITS_STARTING,
  UPDATE_PARAM_LIMITS_SUCCESS,
  UPDATE_PARAM_LIMITS_ERROR,
} from '../../constants/actionTypes';

export default function updateParamLimitsReducer(state = initialState.updateParamLimits, action) {
  switch (action.type) {
    case UPDATE_PARAM_LIMITS_STARTING: {
      const newState = clone(state);
      newState.saving = true;
      newState.saved = false;
      return newState;
    }

    case UPDATE_PARAM_LIMITS_SUCCESS: {
      const newState = clone(state);
      newState.saving = false;
      newState.saved = true;
      newState.error = null;

      return newState;
    }

    case UPDATE_PARAM_LIMITS_ERROR: {
      const newState = clone(state);
      newState.saving = false;
      newState.saved = false;
      newState.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
