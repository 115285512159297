import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  DEACTIVATE_PARAMETER_STARTING,
  DEACTIVATE_PARAMETER_SUCCESS,
  DEACTIVATE_PARAMETER_ERROR,
} from '../../constants/actionTypes';

export default function deactivateParameterReducer(state = initialState.deactivateParameter, action) {
  switch (action.type) {
    case DEACTIVATE_PARAMETER_STARTING: {
      const {paramId} = action;
      const newState = clone(state);

      if (!newState[paramId]) {
        newState[paramId] = clone(initialState.deactivateParameter.paramId);
      }

      const locationData = newState[paramId];
      locationData.saving = true;
      locationData.saved = false;
      locationData.error = null;

      return newState;
    }

    case DEACTIVATE_PARAMETER_SUCCESS: {
      const {paramId} = action;
      const newState = clone(state);

      if (!newState[paramId]) {
        newState[paramId] = clone(initialState.deactivateParameter.paramId);
      }

      const locationData = newState[paramId];
      locationData.saving = false;
      locationData.saved = true;
      locationData.error = null;
      return newState;
    }

    case DEACTIVATE_PARAMETER_ERROR: {
      const {paramId} = action;
      const newState = clone(state);

      if (!newState[paramId]) {
        newState[paramId] = clone(initialState.deactivateParameter.paramId);
      }

      const locationData = newState[paramId];
      locationData.saving = false;
      locationData.saved = false;
      locationData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
