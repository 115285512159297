import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  UPDATE_ACK_MESSAGE_STARTING,
  UPDATE_ACK_MESSAGE_SUCCESS,
  UPDATE_ACK_MESSAGE_ERROR,
} from '../../constants/actionTypes';

export default function updateAckMessageReducer(state = initialState.updateAckMessage, action) {
    switch (action.type) {
        case UPDATE_ACK_MESSAGE_STARTING: {
            const {ackMessageId} = action;
            const newState = clone(state);
            
            if(!newState[ackMessageId]) {
                newState[ackMessageId] = clone(initialState.updateAckMessage.ackMessageId);
            }
            
            const updateData = newState[ackMessageId];
            updateData.saving = true;
            updateData.saved = false;
            updateData.error = null;
            
            return newState;
        }
        case UPDATE_ACK_MESSAGE_SUCCESS: {
            const {ackMessageId} = action;
            const newState = clone(state);
            
            if(!newState[ackMessageId]) {
                newState[ackMessageId] = clone(initialState.updateAckMessage.ackMessageId);
            }
            
            const updateData = newState[ackMessageId];
            updateData.saving = false;
            updateData.saved = true;
            updateData.error = null;
            
            return  newState;
        }
        case UPDATE_ACK_MESSAGE_ERROR: {
            const {ackMessageId} = action;
            const newState = clone(state);
            
            if(!newState[ackMessageId]) {
                newState[ackMessageId] = clone(initialState.updateAckMessage.ackMessageId);
            }
            
            const updateData = newState[ackMessageId];
            updateData.saving = false;
            updateData.saved = false;
            updateData.error = action.err;
            
            return newState;
        }
    
        default: {
            return state;
    }
  }
}