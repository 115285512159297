import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    GET_AHU_DUST_COLLECTOR_FILTER_REQUESTS_SUCCESS,
    GET_AHU_FILTER_CLEANING_REQUESTS_SUCCESS,
    GET_AHU_ON_OFF_REQUESTS_SUCCESS,
    GET_DUCT_CLEANING_REQUESTS_SUCCESS,
} from '../../constants/actionTypes';

export default function userDataReducer(state = initialState.userData, action) {
    switch (action.type) {
        case GET_AHU_FILTER_CLEANING_REQUESTS_SUCCESS:
        case GET_AHU_ON_OFF_REQUESTS_SUCCESS:
        case GET_AHU_DUST_COLLECTOR_FILTER_REQUESTS_SUCCESS:
        case GET_DUCT_CLEANING_REQUESTS_SUCCESS: {
            const newState = cloneDeep(state);
            const {userData} = action.data;

            for (const userId in userData) {
                if (userData.hasOwnProperty(userId)) {
                    newState.map[userId] = userData[userId];
                }
            }

            return newState;
        }

        default: {
            return state;
        }
    }
}
