export default {
  currentUser: {
    prevRoute: '',

    loading: false,
    loaded: false,

    refetchInfo: false,
    fetchingInfo: true,
    forceLogin: false,
    loggedIn: false,
    loginInProgress: false,

    error: '',
    loggedInElsewhere: false,

    userData: {},
    rolePermissions: {},
    tokenData: {},

    userId: '',
    token: '',
  },

  socket: {
    connected: false,
    connecting: false,
    firstConnectDone: false,

    error: null,

    loading: false,
    socket: null,

    lastUpdateTime: null,
  },

  clientInfo: {
    loading: false,
    loaded: false,
    data: {},
  },

  fileUploadStatus: {
    uploading: false,
    uploaded: false,
    error: null,
    file: null,
  },

  files: {
    fileId: {
      loading: false,
      loaded: false,
      error: null,

      file: {},
    },
  },

  chat: {
    prompt: '',
    messages: [],
    messageStr: '',

    loading: false,
    loaded: false,
    error: null,
  },

  locations: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  locationDevices: {
    locationId: {
      mainDeviceIds: [],
      mainDevicesMap: {},
      relatedDevices: {},
      parameters: {},

      deviceTypeOfIds: {},
      uniqueDeviceTypes: [],
    },
  },

  categories: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  referenceDevices: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  referenceParameters: {
    deviceId: {
      ids: [],
      inactiveIds: [],
      map: {},
      loading: false,
      loaded: false,
      error: null,
      isLastPage: false,
      page: 1,
      limit: 10,
      pages: 1,
      total: 0,
    },
  },

  networks: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  locationAreas: {
    locationId: {
      ids: [],
      map: {},
      loading: false,
      loaded: false,
      error: null,
      isLastPage: false,
      page: 1,
      limit: 10,
      pages: 1,
      total: 0,
    },
  },

  areaRooms: {
    areaId: {
      ids: [],
      map: {},
      loading: false,
      loaded: false,
      error: null,
      isLastPage: false,
      page: 1,
      limit: 10,
      pages: 1,
      total: 0,
    },
  },

  rooms: {
    locationId: {
      ids: [],
      map: {},

      devicesMap: {},

      monitoringDeviceTypes: {},
      roomDevicesByType: {},

      loading: false,
      loaded: false,
      error: null,
      isLastPage: false,
      page: 1,
      limit: 10,
      pages: 1,
      total: 0,
    },
  },

  areas: {
    locationId: {
      ids: [],
      map: {},
      loading: false,
      loaded: false,
      error: null,
      isLastPage: false,
      page: 1,
      limit: 10,
      pages: 1,
      total: 0,
    },
  },

  roomWiseDevices: {
    roomId: {
      ids: [],
      map: {},
      loading: false,
      loaded: false,
      error: null,
      isLastPage: false,
      page: 1,
      limit: 10,
      pages: 1,
      total: 0,
    },
  },

  parameters: {
    paramId: {
      loading: false,
      loaded: false,

      parameter: null,
    },
  },

  paramData: {
    paramId: {
      loading: false,
      loaded: false,

      value: null,
      ts: null,
    },
  },

  fullScreenStatus: {
    enabled: false,
  },

  addCategory: {
    saving: false,
    saved: false,
    error: null,
    newCategory: null,
  },

  updateCategory: {
    categoryId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  updateClientInfo: {
    saving: false,
    saved: false,
    error: null,
  },

  addMobileTower: {
    saving: false,
    saved: false,
    error: null,
    newMobileTower: null,
  },

  updateMobileTower: {
    mobileTowerId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  addLocation: {
    saving: false,
    saved: false,
    error: null,
    newLocation: null,
  },

  addAckMessage: {
    saving: false,
    saved: false,
    error: null,
    newAckMessage: null,
  },

  updateLocation: {
    locationId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  addRoom: {
    saving: false,
    saved: false,
    error: null,
    newRoom: null,
  },

  updateRoom: {
    roomId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  addArea: {
    saving: false,
    saved: false,
    error: null,
    newArea: null,
  },

  updateArea: {
    areaId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  addDevice: {
    saving: false,
    saved: false,
    error: null,
    newDevice: null,
  },

  addAHUDevice: {
    saving: false,
    saved: false,
    error: null,
    newDevice: null,
  },

  updateDevice: {
    deviceId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  addNetwork: {
    saving: false,
    saved: false,
    error: null,
    newNetwork: null,
  },

  updateNetwork: {
    networkId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  addParameter: {
    saving: false,
    saved: false,
    error: null,
    newParameter: null,
  },

  updateParameter: {
    paramId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  roles: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  modules: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addRole: {
    saving: false,
    saved: false,
    error: null,
    newRole: null,
  },

  updateRole: {
    roleId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  users: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addUser: {
    saving: false,
    saved: false,
    error: null,
    newUser: null,
  },

  updateUser: {
    userId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  updateRolePermissions: {
    roleId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateCategory: {
    categoryId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateCategory: {
    categoryId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateMobileTower: {
    mobileTowerId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateMobileTower: {
    mobileTowerId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateLocation: {
    locationId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateLocation: {
    locationId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateRoom: {
    roomId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateRoom: {
    roomId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateArea: {
    areaId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateArea: {
    areaId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateDevice: {
    deviceId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateDevice: {
    deviceId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateNetwork: {
    networkId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateNetwork: {
    networkId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateParameter: {
    paramId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateParameter: {
    paramId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateRole: {
    roleId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateAckMessage: {
    ackMessageId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateAckMessage: {
    ackMessageId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateRole: {
    roleId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  reportTemplates: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addReportTemplate: {
    saving: false,
    saved: false,
    error: null,
    newReportTemplate: null,
  },

  updateReportTemplate: {
    reportTemplateId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateReportTemplate: {
    reportTemplate: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateReportTemplate: {
    reportTemplate: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  userData: {
    map: {},
  },

  ahuFilterCleaningRequests: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: '',
  },

  addAhuFilterCleaningRequest: {
    saving: false,
    saved: false,
    error: null,
    newRequest: null,
  },

  acknowledgeAhuFilterCleaningRequest: {
    requestId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  closeAhuFilterCleaningRequest: {
    requestId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  setAhuFilterCleaningRequestOperationStatus: {
    requestId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  ahuOnOffRequests: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: '',
  },

  addAhuOnOffRequest: {
    saving: false,
    saved: false,
    error: null,
    newRequest: null,
  },

  acknowledgeAhuOnOffRequest: {
    requestId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  closeAhuOnOffRequest: {
    requestId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  setAhuOnOffRequestOperationStatus: {
    requestId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  auditLogs: {
    ids: [],
    map: {},
    pagedIds: [],
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  alarms: {
    ids: [],
    map: {},
    pagedIds: [],
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  attendanceRecords: {
    ids: [],
    pagedIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: '',
  },

  employees: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: '',
  },

  attendanceAreas: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: '',
  },

  terminals: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: '',
  },

  ahuDustCollectorFilterRequests: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    pagedIds: [],
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: '',
  },

  addAhuDustCollectorFilterRequest: {
    saving: false,
    saved: false,
    error: null,
    newRequest: null,
  },

  acknowledgeAhuDustCollectorFilterRequest: {
    requestId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  closeAhuDustCollectorFilterRequest: {
    requestId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  setAhuDustCollectorFilterRequestOperationStatus: {
    requestId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  updateAhuDustCollectorFilterRequest: {
    requestId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  ductCleaningRequests: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: '',
  },

  addDuctCleaningRequest: {
    saving: false,
    saved: false,
    error: null,
    newRequest: null,
  },

  acknowledgeDuctCleaningRequest: {
    requestId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  closeDuctCleaningRequest: {
    requestId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  setDuctCleaningRequestOperationStatus: {
    requestId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  requestNotifications: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: '',
  },

  ackMessages: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: '',
  },

  updateAckMessage: {
    ackMessageId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  toastNotification: {
    id: 0,
    data: null,
  },

  mobileTowers: {
    ids: [],
    inactiveIds: [],
    map: {},
    defectsMap: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  regions: {
    ids: [],
    inactiveIds: [],
    userAddress: null,
    showInternational: false,
    map: {},
    hierarchy: {},
    towersMap: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  parameterData: {
    connected: false,
    connecting: false,
    error: null,

    ids: [],
    loading: false,
    socket: null,
    dataRecords: {},
    timestamps: {},
    values: {},
    historicalDataRecords: {},
    lastUpdateTime: null,
  },

  searchResults: {
    query: {
      loading: false,
      loaded: false,
      error: null,

      result: null,
      webPages: {},
      wikiPage: {},
      prefixData: {},
    },
  },

  saveSearchResult: {
    query: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  dictionaryWordsInPrefix: {
    prefixStr: {
      loading: false,
      loaded: false,
      error: null,

      searchQuery: '',
      matchCount: 0,

      ids: [],
      map: {},
    },
  },

  searchSuggestions: {
    query: {
      loading: false,
      loaded: false,
      error: null,

      suggestions: [],
      links: {},
      resultsData: {},
    },
  },

  testBenchStatus: {
    loading: false,
    loaded: false,
    error: null,

    updating: false,
    updated: false,
    updateError: null,

    isTestRunning: false,
    currentTest: null,
  },

  updateParamLimits: {
    saving: false,
    saved: false,
    error: null,
  },

  alarmNotifications: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: '',
  },

  applications: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: '',
  },

  licenses: {
    applicationId: {
      ids: [],
      map: {},
      loading: false,
      loaded: false,
      error: null,
      isLastPage: false,
      page: 1,
      limit: 10,
      pages: 1,
      total: 0,
      filterKey: '',
    },
  },

  addLicense: {
    saving: false,
    saved: false,
    error: null,
    newLicense: null,
  },

  updateLicense: {
    licenseId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  companies: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: '',
  },

  jobs: {
    ids: [],
    inactiveIds: [],
    map: {},
    applications: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  createJobApplication: {
    saving: false,
    saved: false,
    error: null,
    newJobApplication: null,
  },

  userLocations: {
    userId: {
      loading: false,
      loaded: false,
      error: null,

      locations: [],
    },
  },

  hydraulicTestBench: {
    dtList: [],
    reportData: {},
    pagedDtList: [],
    deviceIds: [],
    devicesMap: {},
    parametersMap: {},
    deviceParamIdsMap: {},

    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  gearBoxTest: {
    dtList: [],
    reportData: {},
    pagedDtList: [],
    deviceIds: [],
    devicesMap: {},
    parametersMap: {},
    deviceParamIdsMap: {},

    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  signup: {
    saving: false,
    saved: false,
    error: null,
    newUser: null,
  },

  forgotPassword: {
    saving: false,
    saved: false,
    error: null,
  },

  resetPassword: {
    saving: false,
    saved: false,
    error: null,
    newPassword: null,
  },

  properties: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  products: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  enquiryRequest: {
    saving: false,
    saved: false,
    error: null,
  },

  cart: {
    loading: false,
    loaded: false,
    cartData: null,
  },

  setCartQuantity: {
    productId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  addAddress: {
    saving: false,
    saved: false,
    error: null,
    newAddress: null,
  },

  addresses: {
    loading: false,
    loaded: false,
    addressData: null,
  },

  posts: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  categoryPosts: {
    categoryId: {
      ids: [],
      map: {},
      loading: false,
      loaded: false,
      error: null,
      isLastPage: false,
      page: 1,
      limit: 10,
      pages: 1,
      total: 0,
    },
  },

  addPost: {
    saving: false,
    saved: false,
    error: null,
    newPost: null,
  },

  updatePost: {
    postId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activatePost: {
    postId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivatePost: {
    postId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  universities: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },

  materials: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },

  tenants: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: '',
  },

  addTenant: {
    saving: false,
    saved: false,
    error: null,
    newTenant: null,
  },

  updateTenant: {
    tenantId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  places: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addPlace: {
    saving: false,
    saved: false,
    error: null,
    newPlace: null,
  },

  updatePlace: {
    placeId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activatePlace: {
    placeId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivatePlace: {
    placeId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  defects: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: '',
  },

  recommendations: {
    mobileTowerId: {
      ids: [],
      inactiveIds: [],
      map: {},
      loading: false,
      loaded: false,
      error: null,
      isLastPage: false,
      page: 1,
      limit: 10,
      pages: 1,
      total: 0,
    },
  },

  addRecommendation: {
    saving: false,
    saved: false,
    error: null,
    newRecommendation: null,
  },

  updateRecommendation: {
    recommendationId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateRecommendation: {
    recommendationId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateRecommendation: {
    recommendationId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  uploadTowerImage: {
    id: {
      loading: false,
      loaded: false,
      error: null,

      image: {},
    },
  },

  addImageLabel: {
    saving: false,
    saved: false,
    error: null,
    newImageLabel: null,
  },

  uploadTowerChecklist: {
    id: {
      loading: false,
      loaded: false,
      error: null,

      checklist: {},
    },
  },
  members: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: '',
  },

  addMember: {
    saving: false,
    saved: false,
    error: null,
    newMember: null,
  },

  updateMember: {
    memberId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

};


