import { apiUrl, searchApiUrl } from './appConfig';

export const CHECK_USER_LOGIN = `${apiUrl}/login/check`;
export const USER_AUTO_LOGIN = `${apiUrl}/login/auto?u={userId}`;
export const GET_USER_INFO = `${apiUrl}/login/verify`;
export const SEND_HEARTBEAT = `${apiUrl}/login/heartbeat`;
export const LOGOUT_USER = `${apiUrl}/login/off`;
export const GET_ACTIVE_LOCATIONS = `${apiUrl}/locations`;

export const GET_INACTIVE_LOCATIONS = `${apiUrl}/locations/inactive`;

export const GET_ALL_LOCATIONS = `${apiUrl}/locations/all?inactive=1`;
export const GET_LOCATION = `${apiUrl}/locations/{locationId}`;
export const GET_LOCATION_DEVICES = `${apiUrl}/locations/{locationId}/devices`;
export const GET_LOCATION_ROOMS = `${apiUrl}/locations/{locationId}/rooms`;
export const GET_LOCATION_AREAS = `${apiUrl}/locations/{locationId}/areas`;
export const GET_AREA_ROOMS = `${apiUrl}/areas/{areaId}/rooms`;
export const ADD_LOCATION = `${apiUrl}/locations`;
export const UPDATE_LOCATION = `${apiUrl}/locations/{locationId}`;

export const GET_ACTIVE_CATEGORIES = `${apiUrl}/categories`;
export const GET_INACTIVE_CATEGORIES = `${apiUrl}/categories/inactive`;
export const ADD_CATEGORY = `${apiUrl}/categories`;
export const UPDATE_CATEGORY = `${apiUrl}/categories/{categoryId}`;
export const DEACTIVATE_CATEGORY = `${apiUrl}/categories/{categoryId}`;
export const ACTIVATE_CATEGORY = `${apiUrl}/categories/{categoryId}`;

export const ACTIVATE_LOCATION = `${apiUrl}/locations/{locationId}`;

export const DEACTIVATE_LOCATION = `${apiUrl}/locations/{locationId}`;

export const GET_ACTIVE_NETWORKS = `${apiUrl}/networks`;

export const GET_INACTIVE_NETWORKS = `${apiUrl}/networks/inactive`;

export const ACTIVATE_NETWORK = `${apiUrl}/networks/{networkId}`;

export const DEACTIVATE_NETWORK = `${apiUrl}/networks/{networkId}`;

export const ADD_NETWORK = `${apiUrl}/networks`;
export const UPDATE_NETWORK = `${apiUrl}/networks/{networkId}`;

export const ADD_ROOM = `${apiUrl}/rooms`;
export const UPDATE_ROOM = `${apiUrl}/rooms/{roomId}`;
export const DEACTIVATE_ROOM = ``;
export const ACTIVATE_ROOM = ``;
export const GET_ROOM_DEVICES = `${apiUrl}/rooms/{roomId}/devices`;

export const ADD_AREA = `${apiUrl}/areas`;
export const UPDATE_AREA = `${apiUrl}/areas/{areaId}`;
export const DEACTIVATE_AREA = ``;
export const ACTIVATE_AREA = ``;
export const GET_AREA_DEVICES = `${apiUrl}/areas/{areaId}/devices`;


export const GET_DEVICE = ``;
export const ADD_DEVICE = `${apiUrl}/devices`;
export const ADD_AHU_DEVICE = `${apiUrl}/devices/create_ahu`;
export const UPDATE_DEVICE = `${apiUrl}/devices/{deviceId}`;
export const GET_REFERENCE_DEVICES = `${apiUrl}/devices/reference_devices`;
export const GET_REFERENCE_PARAMETERS = `${apiUrl}/devices/reference_devices/{deviceId}/parameters`;
export const CREATE_FROM_REFERENCE_DEVICE = `${apiUrl}/devices/create_from_reference`;
export const DEACTIVATE_DEVICE = ``;
export const ACTIVATE_DEVICE = ``;
export const START_DEVICE = ``;
export const STOP_DEVICE = ``;
export const START_ALL_DEVICES = ``;
export const STOP_ALL_DEVICES = ``;

export const GET_DEVICE_PARAMETERS = `${apiUrl}/devices/{deviceId}/parameters`;
export const ADD_PARAMETER = `${apiUrl}/parameters`;
export const UPDATE_PARAMETER = `${apiUrl}/parameters/{paramId}`;
export const DEACTIVATE_PARAMETER = ``;
export const ACTIVATE_PARAMETER = ``;

export const GET_CLIENT_INFO = `${apiUrl}/client_info`;
export const GET_ALARMS = ``;
export const GET_ALERTS = ``;
export const GET_REQUEST_NOTIFICATIONS = `${apiUrl}/alarms/request_notifications`;
export const GET_ALARM_NOTIFICATIONS = `${apiUrl}/alarms/alarm_notifications`;
export const ACKNOWLEDGE_ALARM = `${apiUrl}/alarms/{alarmId}/acknowledge`;
export const GET_FLOOR_PLAN_DATA = ``;
export const GET_USERS = `${apiUrl}/users/search`;
export const ADD_USER = `${apiUrl}/users`;
export const UPDATE_USER = `${apiUrl}/users/{userId}`;
export const UPDATE_PARAM_LIMITS = `${apiUrl}/parameters/update_limits`;
export const GET_CONTROL_SCHEDULES = ``;
export const CREATE_CONTROL_SCHEDULE = ``;
export const DELETE_CONTROL_SCHEDULE = ``;
export const UPDATE_CONTROL_SCHEDULE = ``;
export const GET_COMMON_DEVICES = ``;
export const GET_USER_ROLES = `${apiUrl}/roles`;
export const GET_REPORT_TEMPLATES = `${apiUrl}/reports/report_templates`;
export const ADD_REPORT_TEMPLATE = ``;
export const UPDATE_REPORT_TEMPLATE = ``;
export const ACTIVATE_REPORT_TEMPLATE = ``;
export const DEACTIVATE_REPORT_TEMPLATE = ``;
export const STOP_HOOTERS = ``;
export const GET_BACKUPS = ``;
export const CREATE_BACKUP = ``;
export const GET_ACK_MESSAGES = `${apiUrl}/ack_messages`;
export const ADD_ACK_MESSAGE = `${apiUrl}/ack_messages`;
export const UPDATE_ACK_MESSAGE = `${apiUrl}/ack_messages/:ackMessageId`;
export const DELETE_ACK_MESSAGE = ``;
export const CHECK_HMI_MAPPING = ``;
export const GET_HMI_MAPPING = ``;
export const DELETE_ALL_BACKUPS = ``;
export const DELETE_ALL_ALARMS = ``;
export const SAVE_REPORT_SETTINGS = ``;
export const UPDATE_ROLE = `${apiUrl}/roles/{roleId}`;
export const ADD_ROLE = `${apiUrl}/roles`;
export const UPDATE_ROLE_PERMISSIONS = `${apiUrl}/roles/{roleId}/update_permissions`;
export const START_POLLING_PROCESS = ``;
export const STOP_POLLING_PROCESS = ``;
export const GET_PARAM_DATA = ``;

export const GET_CONTROL_REQUESTS = ``;
export const CREATE_CONTROL_REQUEST = ``;
export const UPDATE_CONTROL_REQUEST = ``;
export const ACKNOWLEDGE_CONTROL_REQUEST = ``;
export const SAVE_MAINTENANCE_SETTINGS = ``;
export const UPDATE_CLIENT_INFO = `${apiUrl}/client_info/update`;
export const GET_AUDIT_LOG_DATA = `${apiUrl}/audit_logs/get_between`;
export const GET_ALARM_DATA = `${apiUrl}/alarms/get_between`;
export const GET_HYDRAULIC_TEST_BENCH_DATA = `${apiUrl}/reports/data/hydraulic_test_bench`;
export const GET_GEAR_BOX_TEST_DATA = `${apiUrl}/reports/data/zf_gearbox`;

export const GET_AHU_FILTER_CLEANING_REQUESTS = `${apiUrl}/ahu_filter_cleaning_requests/search`;
export const ADD_AHU_FILTER_CLEANING_REQUEST = `${apiUrl}/ahu_filter_cleaning_requests`;
export const ACKNOWLEDGE_AHU_FILTER_CLEANING_REQUEST = `${apiUrl}/ahu_filter_cleaning_requests/acknowledge`;
export const CLOSE_AHU_FILTER_CLEANING_REQUEST = `${apiUrl}/ahu_filter_cleaning_requests/close`;
export const MARK_AS_DONE_AHU_FILTER_CLEANING_REQUEST = `${apiUrl}/ahu_filter_cleaning_requests/set_operation_status`;

export const GET_AHU_ON_OFF_REQUESTS = `${apiUrl}/ahu_on_off_requests/search`;
export const ADD_AHU_ON_OFF_REQUEST = `${apiUrl}/ahu_on_off_requests`;
export const ACKNOWLEDGE_AHU_ON_OFF_REQUEST = `${apiUrl}/ahu_on_off_requests/acknowledge`;
export const CLOSE_AHU_ON_OFF_REQUEST = `${apiUrl}/ahu_on_off_requests/close`;
export const MARK_AS_DONE_AHU_ON_OFF_REQUEST = `${apiUrl}/ahu_on_off_requests/set_operation_status`;

export const GET_AHU_DUST_COLLECTOR_FILTER_REQUESTS = `${apiUrl}/ahu_dust_collector_filter_requests/search`;
export const ADD_AHU_DUST_COLLECTOR_FILTER_REQUEST = `${apiUrl}/ahu_dust_collector_filter_requests`;
export const ACKNOWLEDGE_AHU_DUST_COLLECTOR_FILTER_REQUEST = `${apiUrl}/ahu_dust_collector_filter_requests/acknowledge`;
export const RETURN_AHU_DUST_COLLECTOR_FILTER_REQUEST = `${apiUrl}/ahu_dust_collector_filter_requests/return`;
export const CLOSE_AHU_DUST_COLLECTOR_FILTER_REQUEST = `${apiUrl}/ahu_dust_collector_filter_requests/close`;
export const MARK_AS_DONE_AHU_DUST_COLLECTOR_FILTER_REQUEST = `${apiUrl}/ahu_dust_collector_filter_requests/set_operation_status`;
export const SET_FILTER_DETAILS_AHU_DUST_COLLECTOR_FILTER_REQUEST = `${apiUrl}/ahu_dust_collector_filter_requests/set_filter_details`;
export const UPDATE_AHU_DUST_COLLECTOR_FILTER_REQUEST = `${apiUrl}/ahu_dust_collector_filter_requests/{requestId}`;

export const GET_DUCT_CLEANING_REQUESTS = `${apiUrl}/duct_cleaning_requests/search`;
export const ADD_DUCT_CLEANING_REQUEST = `${apiUrl}/duct_cleaning_requests`;
export const ACKNOWLEDGE_DUCT_CLEANING_REQUEST = `${apiUrl}/duct_cleaning_requests/acknowledge`;
export const CLOSE_DUCT_CLEANING_REQUEST = `${apiUrl}/duct_cleaning_requests/close`;
export const MARK_AS_DONE_DUCT_CLEANING_REQUEST = `${apiUrl}/duct_cleaning_requests/set_operation_status`;

export const GET_ATTENDANCE_RECORDS = `${apiUrl}/employees/get_attendance_between`;
export const GET_EMPLOYEES = `${apiUrl}/employees/search`;

export const GET_MOBILE_TOWER_BY_ID = `${apiUrl}/mobile_towers/{towerId}`;
export const GET_ACTIVE_MOBILE_TOWERS = `${apiUrl}/mobile_towers`;
export const GET_INACTIVE_MOBILE_TOWERS = `${apiUrl}/mobile_towers/inactive`;
export const ADD_MOBILE_TOWER = `${apiUrl}/mobile_towers`;
export const UPDATE_MOBILE_TOWER = `${apiUrl}/mobile_towers/{towerId}`;
export const DEACTIVATE_MOBILE_TOWER = `${apiUrl}/mobile_towers/{towerId}`;
export const ACTIVATE_MOBILE_TOWER = `${apiUrl}/mobile_towers/{towerId}`;
export const GET_REGIONS = `${apiUrl}/mobile_towers/regions`;

export const GET_SEARCH_RESULT = `${apiUrl}/search`;
export const GET_SEARCH_SUGGESTIONS = `${apiUrl}/search/suggest`;
export const GET_DICT_WORDS_IN_PREFIX = `${searchApiUrl}/search/data/words_in_prefix`;
export const SAVE_SEARCH_RESULT = `${apiUrl}/search`;
export const GET_JOBS = `${apiUrl}/jobs/search`;
export const GET_JOB_BY_ID = `${apiUrl}/jobs/{jobId}`;
export const CREATE_JOB_APPLICATION = `${apiUrl}/jobs/{jobId}/apply`;
export const GET_JOB_APPLICATION = ``;
export const UPDATE_JOB_APPLICATION = ``;
export const DELETE_JOB_APPLICATION = ``;
export const GET_SAVED_REPORTS = ``;
export const REVIEW_REPORT = ``;
export const APPROVE_REPORT = ``;
export const PRINT_REPORT = ``;
export const SAVE_REPORT_COMMENTS = ``;

export const GET_DEVICE_DATA_REPORT = ``;
export const GET_RUN_HOURS = ``;
export const GET_RUN_HOURS_REPORT = ``;
export const CREATE_JOB = ``;
export const UPDATE_JOB = ``;
export const ACTIVATE_JOB = ``;
export const DEACTIVATE_JOB = ``;
export const CREATE_POLL = ``;
export const UPDATE_POLL = ``;
export const ACTIVATE_POLL = ``;
export const DEACTIVATE_POLL = ``;
export const SAVE_POLL_RESPONSE = ``;
export const GET_USER_LOCATIONS = `${apiUrl}/users/location`;
export const ADD_DEFECT = `${apiUrl}/users/defects`;
export const UPDATE_DEFECT = `${apiUrl}/users/defects`;
export const ACTIVATE_DEFECT = `${apiUrl}/users/defects`;
export const DEACTIVATE_DEFECT = `${apiUrl}/users/defects`;
export const ADD_TENANT = `${apiUrl}/mobile_towers/{towerId}/tenants`;
export const UPDATE_TENANT = `${apiUrl}/mobile_towers/{towerId}/tenants/{tenantId}`;
export const ACTIVATE_TENANT = `${apiUrl}/users/tenants`;
export const DEACTIVATE_TENANT = `${apiUrl}/users/tenants`;
export const GET_MODELS = `${apiUrl}/users/tenants`;
export const GET_WEATHER_DETAILS = `${apiUrl}/users/weather`;
export const GET_TEMPERATURE = `${apiUrl}/weather/forecast`;

export const AI_CHAT_URL = `http://192.168.29.160:11434/api/chat`;
export const GET_MODULES = `${apiUrl}/roles/modules`;

export const GET_APPLICATIONS = `${apiUrl}/licenses/applications`;
export const GET_APPLICATION_LICENSES = `${apiUrl}/licenses/{applicationId}`;
export const ADD_LICENSE = `${apiUrl}/licenses`;
export const UPDATE_LICENSE = `${apiUrl}/licenses/{licenseId}`;

export const SIGNUP_USER = `${apiUrl}/login/signup`;
export const FORGOT_PASSWORD = `${apiUrl}/login/forgot_password`;
export const RESET_PASSWORD = `${apiUrl}/login/reset_password`;

export const GET_PROPERTIES = `${apiUrl}/re_properties/search`;
export const GET_PROPERTY_BY_ID = `${apiUrl}/re_properties/{propertyId}`;
export const SEND_ENQUIRY_REQUEST = `${apiUrl}/re_properties/save_enquiry`;

export const GET_PRODUCTS = `${apiUrl}/products/search`;
export const GET_PRODUCT_BY_ID = `${apiUrl}/products/{productId}`;
export const SET_CART_QUANTITY = `${apiUrl}/cart`;
export const GET_SHOPPING_CART = `${apiUrl}/cart`;

export const GET_ADDRESSES = ``;
export const ADD_NEW_ADDRESS = ``;

export const GET_POSTS = `${apiUrl}/posts`;
export const GET_POST_BY_ID = `${apiUrl}/posts/{postId}`;
export const ADD_POST = `${apiUrl}/posts`;
export const UPDATE_POST = `${apiUrl}/posts/{postId}`;
export const ACTIVATE_POST = ``;
export const DEACTIVATE_POST = ``;
export const GET_INACTIVE_POSTS = `${apiUrl}/posts/inactive`;

export const GET_UNIVERSITIES = `${apiUrl}/education/universities`;
export const GET_MATERIALS = `${apiUrl}/materials/materials`;

export const GET_TENANTS = `${apiUrl}/mobile_towers/{towerId}/tenants`;
export const GET_DEFECTS = `${apiUrl}/mobile_towers/defects`;

//TOWER SITENAMES
export const GET_TOWER_SITENAMES = `${apiUrl}/mobile_towers/
`;
export const ADD_TOWER_SITENAME = `${apiUrl}/mobile_towers/regions/create`;

//places
export const GET_PLACES = `${apiUrl}/places`;
export const GET_PLACE_BY_ID = `${apiUrl}/places/{placeId}`;
export const ADD_PLACE = `${apiUrl}/places`;
export const UPDATE_PLACE = `${apiUrl}/places/{placeId}`;
export const ACTIVATE_PLACE = ``;
export const DEACTIVATE_PLACE = ``;
export const GET_INACTIVE_PLACES = `${apiUrl}/places/inactive`;

export const GET_RECOMMENDATIONS = `${apiUrl}/recommendations`;
export const ADD_RECOMMENDATION = `${apiUrl}/mobile_towers/{towerId}/recommendations`;
export const UPDATE_RECOMMENDATION = `${apiUrl}/mobile_towers/{towerId}/recommendations/{recommendationId}`;

//Upload images
export const ADD_MOBILE_TOWER_IMAGE = `${apiUrl}/mobile_towers/images/add`;
export const DELETE_MOBILE_TOWER_IMAGE = `${apiUrl}/mobile_towers/image/{imageId}`;

export const GET_ACTIVE_RECOMMENDATIONS = ``;
export const GET_INACTIVE_RECOMMENDATIONS = ``;
export const ACTIVATE_RECOMMENDATION = ``;
export const DEACTIVATE_RECOMMENDATION = ``;

export const ADD_IMAGE_LABEL = `${apiUrl}/mobile_towers/images/add`;

export const ADD_TOWER_CHECKLIST = `${apiUrl}/mobile_towers/uploadChecklist`;

//mobile tower members
export const GET_MEMBERS = `${apiUrl}/mobile_towers/{towerId}/members`;
export const ADD_MEMBER = `${apiUrl}/mobile_towers/{towerId}/members`;
export const UPDATE_MEMBER = `${apiUrl}/mobile_towers/{towerId}/members/{memberId}`;
export const ACTIVATE_MEMBER = `${apiUrl}/users/members`;
export const DEACTIVATE_MEMBER = `${apiUrl}/users/members`;

//weather trends
export const GET_WEATHER = `${apiUrl}/weather/forecast`;
