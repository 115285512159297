import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  GET_LOCATIONS_ERROR,
  GET_LOCATIONS_STARTING,
  GET_LOCATIONS_SUCCESS,
  ADD_LOCATION_SUCCESS,
  UPDATE_LOCATION_SUCCESS,
  GET_INACTIVE_LOCATIONS_SUCCESS,
  DEACTIVATE_LOCATION_SUCCESS,
  ACTIVATE_LOCATION_SUCCESS,
} from '../../constants/actionTypes';

const sortByOrder = (newState) => {
  newState.ids.sort((loc1Id, loc2Id) => {
    const loc1 = newState.map[loc1Id];
    const loc2 = newState.map[loc2Id];
    return loc1.dashboardOrder - loc2.dashboardOrder;
  });
  
  newState.inactiveIds.sort((loc1Id, loc2Id) => {
    const loc1 = newState.map[loc1Id];
    const loc2 = newState.map[loc2Id];
    return loc1.dashboardOrder - loc2.dashboardOrder;
  });
}

export default function locationsReducer(state = initialState.locations, action) {
  switch (action.type) {
    case GET_LOCATIONS_STARTING: {
      const newState = cloneDeep(state);
      newState.loading = true;
      newState.loaded = false;
      return newState;
    }

    case GET_LOCATIONS_SUCCESS: {
      const newState = cloneDeep(state);
      const ids = [];
      
      action.data.locations.forEach((location) => {
        const locationId = location.id;
        ids.push(locationId);
        newState.map[locationId] = location;
        });

      newState.ids = ids;
      
      //newState.page = action.data.page;
      //newState.pages = action.data.pages;
      //newState.limit = action.data.limit;
      //newState.total = action.data.total;
      //newState.isLastPage = newState.page >= newState.pages;
      newState.loading = false;
      newState.loaded = true;
      
      sortByOrder(newState);

      return newState;
    }
  
  case GET_INACTIVE_LOCATIONS_SUCCESS: {
    const newState = cloneDeep(state);
    const inactiveIds = [];
    
    action.data.locations.forEach((location) => {
      const locationId = location.id;
      inactiveIds.push(locationId);
      newState.map[locationId] = location;
    });
    newState.inactiveIds = inactiveIds;
    
    sortByOrder(newState);
    return newState;
  }

    case GET_LOCATIONS_ERROR: {
      const newState = cloneDeep(state);
      newState.loading = false;
      newState.loaded = false;
      newState.error = action.err ? action.err : 'Failed to retrieve locations.';
      return newState;
    }

    case ADD_LOCATION_SUCCESS: {
      const newState = cloneDeep(state);
      const { location } = action.data;
      const locationId = location.id;

      // Push new location data
      newState.map[locationId] = location;
      newState.ids.push(locationId);

      // Sort by display order
      sortByOrder(newState);

      return newState;
    }

    case UPDATE_LOCATION_SUCCESS: {
      const newState = cloneDeep(state);
      const { location } = action.data;
      const locationId = location.id;
      newState.map[locationId] = location;
      
      sortByOrder(newState);
      return newState;
    }
  
  case ACTIVATE_LOCATION_SUCCESS: {
    const newState = cloneDeep(state);
    const { locationId } = action;
    newState.ids.push(locationId);
    
    newState.inactiveIds = newState.inactiveIds.filter((lId) => {
      return locationId !== lId;
    });
    if (newState.map[locationId]) {
      newState.map[locationId].isActive =1; 
    }
    sortByOrder(newState);
    return newState;
  }
  
  case DEACTIVATE_LOCATION_SUCCESS: {
    const newState = cloneDeep(state);
    const { locationId } = action;
    newState.inactiveIds.push(locationId);
    
    newState.ids = newState.ids.filter((lId) => {
      return locationId !== lId;
    });
    
    if (newState.map[locationId]) {
      newState.map[locationId].isActive = 0;
    }
    
    sortByOrder(newState);
    return newState;
  }

    default: {
      return state;
    }
  }
}
