import { cloneDeep, property } from 'lodash';
import {
    GET_PROPERTIES_STARTING,
    GET_PROPERTIES_SUCCESS,
    GET_PROPERTIES_ERROR,

    GET_PROPERTY_BY_ID_STARTING,
    GET_PROPERTY_BY_ID_SUCCESS,
    GET_PROPERTY_BY_ID_ERROR,
} from '../constants/actionTypes';
import initialState from '../store/initialState';

const sortByOrder = (newState) => {
    newState.ids.sort((property1Id, property2Id) => {
        const property1 = newState.map[property1Id];
        const property2 = newState.map[property2Id];
        return property1.displayOrder - property2.displayOrder;
    });
}

export default function propertiesReducer(state = initialState.properties, action) {
    switch (action.type) {

        case GET_PROPERTIES_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_PROPERTIES_SUCCESS: {
            const newState = cloneDeep(state);
            const ids = [];
            action.data.properties.forEach((property) => {
                const propertyId = property.id;
                ids.push(propertyId);
                newState.map[propertyId] = property;
            });

            newState.ids = ids;

            //newState.page = action.data.page;
            //newState.pages = action.data.pages;
            //newState.limit = action.data.limit;
            //newState.total = action.data.total;
            //newState.isLastPage = newState.page >= newState.pages;

            newState.loading = false;
            newState.loaded = true;

            sortByOrder(newState);

            return newState;
        }

        case GET_PROPERTIES_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to retrive properties';
            return newState;
        }

        case GET_PROPERTY_BY_ID_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_PROPERTY_BY_ID_SUCCESS: {
            const newState = cloneDeep(state);
            const { propertyId, data } = action;
            newState.map[propertyId] = data.property;
            newState.loading = false;
            newState.loaded = true;
            return newState;
        }

        case GET_PROPERTY_BY_ID_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to retrive property details by Id';
            return newState;
        }

        default: {
            return state;
        }
    }
}