import io from 'socket.io-client';
import {
    SOCKET_CONNECT_STARTING,
    SOCKET_CONNECT_SUCCESS,
    SOCKET_CONNECT_ERROR,
    SOCKET_DISCONNECTED,

    RECEIVE_ALERTS,
    RECEIVE_PARAM_DATA,
    HEARTBEAT_SUCCESS,
    CLEAR_DATA,
    ADD_DATA_PARAMETERS,
    REMOVE_DATA_PARAMETERS,
    SET_DATA_PARAMETERS,

    IGNORE_ACTION, GET_TEST_DETAILS_STARTING, GET_TEST_DETAILS_SUCCESS, GET_TEST_DETAILS_ERROR,
} from '../constants/actionTypes';
import { store } from '../index';
import { socketUrl } from '../constants/appConfig';

export let socket = null;
let heartbeatTimer = null;

export function heartbeatSuccess(data) {
    return { type: HEARTBEAT_SUCCESS, data };
}

export function stopHeartbeatTimer() {
    if (heartbeatTimer !== null) {
        clearInterval(heartbeatTimer);
        heartbeatTimer = null;
    }
}

export function startHeartbeatTimer(sessionHash) {
    if (heartbeatTimer !== null) {
        return;
    }

    const sendHeartbeat = () => {
        if (socket !== null) { // TODO Check if connected
            socket.emit('heartbeat', {
                sessionHash,
                dt: (new Date()).toString(),
            }, (response) => {
                //console.log('Got response for ping', response);
                if (response && response.success) {
                    store.dispatch(heartbeatSuccess(response));
                }
            });
        }
    };

    sendHeartbeat();
    heartbeatTimer = setInterval(sendHeartbeat, 5000);
}

export function socketConnectStarting() {
    return { type: SOCKET_CONNECT_STARTING };
}

export function socketDisconnected() {
    return { type: SOCKET_DISCONNECTED };
}

export function socketConnected(client) {
    return { type: SOCKET_CONNECT_SUCCESS, socket: client };
}

export function socketConnectFailed(err) {
    return { type: SOCKET_CONNECT_ERROR, err: err.message };
}

export function receiveAlerts(data) {
    return { type: RECEIVE_ALERTS, data };
}

export function receiveParamData(data) {
    return { type: RECEIVE_PARAM_DATA, data };
}

export function addDataParameters(paramIds) {
    return { type: ADD_DATA_PARAMETERS, paramIds };
}

export function removeDataParameters(paramIds) {
    return { type: REMOVE_DATA_PARAMETERS, paramIds };
}

export function setDataParameters(paramIds) {
    return { type: SET_DATA_PARAMETERS, paramIds };
}

export function getParamData(paramIds) {
    if (!socket) {
        return;
    }

    socket.emit('getData', {
        ids: paramIds,
    });

    return { type: IGNORE_ACTION };
}

export function clearData() {
    return { type: CLEAR_DATA };
}

export function initSocket() {
    return (dispatch) => {
        if (socket) {
            // socket init already done
            return;
        }

        dispatch(socketConnectStarting());

        socket = io.connect(socketUrl, {
            transports: ['websocket', 'polling'],
            'sync disconnect on unload': true,
            'reconnectionDelay': 200,
            'reconnectionDelayMax': 500,
        });

        socket.on('connect', () => {
            dispatch(socketConnected(socket));

            // For this application, socket requires authorization
            // TODO Get current user data from server
            // TODO Fix authentication
            // const userSession = localStorage.getItem('userSession');
            // let userSessionJson = null;
            //
            // if (userSession) {
            //     try {
            //         userSessionJson = JSON.parse(userSession);
            //     } catch (err) {
            //         //console.error(err);
            //     }
            // }
            //
            // if (userSessionJson) {
            //     dispatch(getUserInfo(userSessionJson.sessionHash));
            // } else {
            //     dispatch(forceUserLogin());
            // }
        });

        // TODO Handle more events coming from socket here

        socket.on('connect_error', (err) => {
            dispatch(socketConnectFailed(err));
        });

        socket.on('connect_timeout', (/* timeout */) => {
            dispatch(socketConnectFailed(new Error('Connection on server socket timed out')));
        });

        socket.on('error', (err) => {
            dispatch(socketConnectFailed(err));
        });

        socket.on('reconnecting', (/* attemptNumber */) => {
            dispatch(socketConnectStarting());
        });

        socket.on('reconnect', () => {
            dispatch(socketConnected(socket));
        });

        socket.on('reconnect_error', (err) => {
            dispatch(socketConnectFailed(err));
        });

        socket.on('reconnect_failed', (/* attemptNumber */) => {
            dispatch(socketConnectFailed(new Error('Failed to re-connect on server socket')));
        });

        socket.on('ping', () => {
            // use for connection statistics
        });

        socket.on('pong', (/* latency */) => {
            // use for connection statistics
        });

        socket.on('disconnect', () => {
            dispatch(socketDisconnected());
        });

        socket.on('receiveData', (data) => {
            try {
                data = JSON.parse(data);
                dispatch(receiveParamData(data));
            } catch (err) {
                console.error('Failed to parse data received', err);
            }
        });

        socket.on('receiveAlerts', (data) => {
            dispatch(receiveAlerts(data));
        });

        window.onbeforeunload = () => {
            socket.emit('client_unload', {

            }, (response) => {
                console.log('disconnect response', response);
            });
        };
    };
}
