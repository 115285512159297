import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  UPDATE_LOCATION_STARTING,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_ERROR,
} from '../../constants/actionTypes';

export default function updateLocationReducer(state = initialState.updateLocation, action) {
  switch (action.type) {
    case UPDATE_LOCATION_STARTING: {
      const {locationId} = action;
      const newState = clone(state);

      if (!newState[locationId]) {
        newState[locationId] = clone(initialState.updateLocation.locationId);
      }

      const updateData = newState[locationId];
      updateData.saving = true;
      updateData.saved = false;
      updateData.error = null;

      return newState;
    }

    case UPDATE_LOCATION_SUCCESS: {
      const {locationId} = action;
      const newState = clone(state);

      if (!newState[locationId]) {
        newState[locationId] = clone(initialState.updateLocation.locationId);
      }

      const updateData = newState[locationId];
      updateData.saving = false;
      updateData.saved = true;
      updateData.error = null;
      return newState;
    }

    case UPDATE_LOCATION_ERROR: {
      const {locationId} = action;
      const newState = clone(state);

      if (!newState[locationId]) {
        newState[locationId] = clone(initialState.updateLocation.locationId);
      }

      const updateData = newState[locationId];
      updateData.saving = false;
      updateData.saved = false;
      updateData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
