import cloneDeep from 'lodash/cloneDeep';
import { GET_MATERIALS_ERROR, GET_MATERIALS_STARTING, GET_MATERIALS_SUCCESS } from "../constants/actionTypes";
import initialState from "../store/initialState";

export default function materialsReducer(state = initialState.materials, action) {
    switch (action.type) {
        case GET_MATERIALS_STARTING: {
            console.log('Get materials starting in reducer');
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_MATERIALS_SUCCESS: {
            console.log('Get materials success in reducer');
            console.log(action);
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = true;
            newState.data = action.data.materials;
            return newState;
        }

        case GET_MATERIALS_ERROR: {
            console.log('Get materials error in reducer');
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            return newState;
        }

        default: {
            return state;
        }
    }
}
