import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  GET_AREA_ROOMS_ERROR,
  GET_AREA_ROOMS_STARTING,
  GET_AREA_ROOMS_SUCCESS,
} from '../../constants/actionTypes';

export default function areaRoomsReducer(state = initialState.areaRooms, action) {
  switch (action.type) {
    case GET_AREA_ROOMS_STARTING: {
      const { areaId } = action;
      const newState = cloneDeep(state);

      if (!newState[areaId]) {
        newState[areaId] = cloneDeep(initialState.areaRooms.areaId);
      }

      const roomsData = newState[areaId];
      roomsData.ids = [];
      roomsData.map = {};
      roomsData.loading = true;
      roomsData.loaded = false;
      roomsData.error = null;

      return newState;
    }

    case GET_AREA_ROOMS_SUCCESS: {
      const { data, areaId } = action;
      const newState = cloneDeep(state);

      if (!newState[areaId]) {
        newState[areaId] = cloneDeep(initialState.areaRooms.areaId);
      }

      const roomsData = newState[areaId];
      const ids = [];
      const map = {};

      data.rooms.forEach((room) => {
        ids.push(room.id);
        map[room.id] = room;
      });

      roomsData.ids = ids;
      roomsData.map = map;
      roomsData.loading = false;
      roomsData.loaded = true;

      //roomsData.page = action.data.page;
      //roomsData.pages = action.data.pages;
      //roomsData.limit = action.data.limit;
      //roomsData.total = action.data.total;
      //roomsData.isLastPage = roomsData.page >= roomsData.pages;

      return newState;
    }

    case GET_AREA_ROOMS_ERROR: {
      const { areaId } = action;
      const newState = cloneDeep(state);

      if (!newState[areaId]) {
        newState[areaId] = cloneDeep(initialState.areaRooms.areaId);
      }

      const roomsData = newState[areaId];
      roomsData.loading = false;
      roomsData.loaded = false;
      roomsData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
