import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  GET_ATTENDANCE_RECORDS_SUCCESS, GET_EMPLOYEES_STARTING, GET_EMPLOYEES_SUCCESS, GET_EMPLOYEES_ERROR,
} from '../../constants/actionTypes';

export default function employeesReducer(state = initialState.employees, action) {
  switch (action.type) {
    case GET_EMPLOYEES_STARTING: {
      const newState = cloneDeep(state);
      newState.loading = true;
      newState.loaded = false;
      return newState;
    }

    case GET_EMPLOYEES_SUCCESS: {
      const newState = cloneDeep(state);
      const ids = [];
      const map = {};

      action.data.employees.forEach((employee) => {
        const employeeId = employee.id;
        ids.push(employeeId);
        map[employeeId] = employee;
      });

      newState.ids = ids;
      newState.map = map;
      //newState.page = action.data.page;
      //newState.pages = action.data.pages;
      //newState.limit = action.data.limit;
      //newState.total = action.data.total;
      //newState.isLastPage = newState.page >= newState.pages;
      newState.loading = false;
      newState.loaded = true;

      return newState;
    }

    case GET_EMPLOYEES_ERROR: {
      const newState = cloneDeep(state);
      newState.loading = false;
      newState.loaded = false;
      newState.error = action.err ? action.err : 'Failed to retrieve employees.';
      return newState;
    }
    
    case GET_ATTENDANCE_RECORDS_SUCCESS: {
      const newState = cloneDeep(state);
      const map = {};

      action.data.employees.forEach((employee) => {
        const employeeId = employee.id;
        map[employeeId] = employee;
      });

      newState.map = map;
      return newState;
    }

    default: {
      return state;
    }
  }
}
